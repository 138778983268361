import { useEffect, useState } from "react";
import { getLoggedinUser } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';

var moment = require('moment-timezone');

const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  var token = userProfileSession && userProfileSession["token"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token = userProfileSession && userProfileSession["token"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, []);


  return { userProfile, loading, token };
};


const TimeDifference = (row) => {
  const startDateTime = moment(`${row?.start_date} ${row?.start_time}`).tz(moment.tz.guess());
  const endDateTime = moment(row?.end_date && row?.end_time
    ? `${row?.end_date} ${row?.end_time}`
    : moment().format('YYYY-MM-DD HH:mm:ss')).tz(moment.tz.guess());

  const duration = moment.duration(endDateTime.diff(startDateTime));
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.minutes());
  const seconds = Math.floor(duration.seconds());
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  return formattedTime;
};


const SumTimeDifference = (row) => {
  const totalMilliseconds = row?.length > 0
    ? (row || []).reduce((sum, item) =>
      sum + moment.duration(TimeDifference(item)).asMilliseconds(), 0)
    : 0;
  const totalDuration = moment.duration(totalMilliseconds);
  const totalHours = Math.floor(totalDuration.asHours());
  const totalMinutes = totalDuration.minutes();
  const totalSeconds = totalDuration.seconds();
  return `${totalHours < 10 ? '0' : ' '}${totalHours}:${totalMinutes < 10 ? '0' : ''}${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;

};

const ToastMessage = (type, message) => {

  return toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};


const FormatDate = (date, selectedCountry) => {

  const formattedDate = (date?.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/) || date?.match(/^\d{4}-\d{2}-\d{2}$/)) ?
    moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD') :
    moment(date, selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').tz(moment.tz.guess()).format('YYYY-MM-DD');

  if (formattedDate !== "undefined") {
    return formattedDate;
  } else {
    return "";
  }

};


const FormatDateTime = (date, selectedCountry) => {

  const formattedDate = (date?.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/) || date?.match(/^\d{4}-\d{2}-\d{2}$/)) ?
    moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss') :
    moment(date, selectedCountry === 'US' ? 'MM-DD-YYYY HH:mm:ss' : 'DD-MM-YYYY HH:mm:ss').tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

  if (formattedDate !== "undefined") {
    return formattedDate;
  } else {
    return "";
  }

};

const FormatMinDate = (date, selectedCountry) => {

  const formattedDate = (date?.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/) || date?.match(/^\d{4}-\d{2}-\d{2}$/)) ?
    moment(date).format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY') : date;

  if (formattedDate !== "undefined") {
    return formattedDate;
  } else {
    return "";
  }

};

// const FormatMaxDate = (date, selectedCountry) => {
//   const parsedDate = moment(date);
//   const formattedDate = (date?.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/) || date?.match(/^\d{4}-\d{2}-\d{2}$/)) ?
//     moment(date).tz(moment.tz.guess()).toDate() :
//     moment(date, selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').tz(moment.tz.guess()).toDate();

//     if (parsedDate.isValid()) {
//       return formattedDate;
//     } else {
//       return "";
//     }

// };


const FormatMaxBalanceDate = (date, balanceCount, selectedCountry) => {
  const formattedDate = (date?.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/) || date?.match(/^\d{4}-\d{2}-\d{2}$/)) ?
    moment(date).add((balanceCount - 1), 'days').tz(moment.tz.guess()).toDate() :
    moment(date, selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').add((balanceCount - 1), 'days').tz(moment.tz.guess()).toDate();

  if (formattedDate !== "undefined") {
    return formattedDate;
  } else {
    return "";
  }
};

const DefaultDate = (date, selectedCountry) => {
  let formattedDate; 
  if (date === "") {
    formattedDate = moment().tz(moment.tz.guess()).format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY');
  } else {    
    formattedDate = moment(date).tz(moment.tz.guess()).format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY');
  } 

  if (formattedDate !== "Invalid date") {
    return formattedDate;
  } else {
    return "";
  }
};

const DefaultDateWithTime = (date, selectedCountry) => {
  let formattedDate; 
  if (date === "") {
    formattedDate = moment().tz(moment.tz.guess()).format(selectedCountry === 'US' ? 'MM-DD-YYYY HH:mm:ss' : 'DD-MM-YYYY HH:mm:ss');
  } else {    
    formattedDate = moment(date).tz(moment.tz.guess()).format(selectedCountry === 'US' ? 'MM-DD-YYYY HH:mm:ss' : 'DD-MM-YYYY HH:mm:ss');
  } 

  if (formattedDate !== "Invalid date") {
    return formattedDate;
  } else {
    return "";
  }
};

const DefaultDateWithDay = (date, selectedCountry) => {
  let formattedDate;

  if (date === "") {
    formattedDate = moment().tz(moment.tz.guess()).format(selectedCountry === 'US' ? "dddd, MM-DD-YYYY" : "dddd, DD-MM-YYYY");
  } else {
    formattedDate = moment(date).tz(moment.tz.guess()).format(selectedCountry === 'US' ? "dddd, MM-DD-YYYY" : "dddd, DD-MM-YYYY");
  }

  if (formattedDate !== "Invalid date") {
    return formattedDate;
  } else {
    return "";
  }
};


const FormatNumber = (number, selectedCountry) => {

 
  if (number) {
    const formattedNumber = parseFloat(number);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    const formattedString = formattedNumber?.toLocaleString("en-" + selectedCountry, options);
    if (formattedString.endsWith('.00')) {
      return selectedCountry === "US" ? "$" + formattedString.slice(0, -3) : "₹" + formattedString.slice(0, -3);
    } else {
      return selectedCountry === "US" ? "$" + formattedString : "₹" + formattedString;
    }
  } else {
    return 0;
  }
};


const FormatCount = (number, selectedCountry) => {
  if (number) {
    const formattedNumber = parseFloat(number);
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };
    const formattedString = formattedNumber.toLocaleString("en-" + selectedCountry, options);
    if (formattedString.endsWith('.00')) {
        return 0;
    } else {
        return 2;
    }
}
};

const SmallDateFormat = (selectedCountry) => {
  return selectedCountry === 'US' ? 'm-d-Y' : 'd-m-Y'
};

const SmallDateTimeFormat = (selectedCountry) => {
  return selectedCountry === 'US' ? 'm-d-Y H:i' : 'd-m-Y H:i'
};


export {
  useProfile, FormatDate, FormatDateTime, FormatMinDate, FormatMaxBalanceDate, DefaultDate, DefaultDateWithTime, DefaultDateWithDay,  FormatNumber, FormatCount,  SmallDateFormat, SmallDateTimeFormat,
  TimeDifference, SumTimeDifference, ToastMessage
};