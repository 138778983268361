import React from "react";
import { Card, CardBody } from "reactstrap";
import { TimeDifference } from "../../Components/Hooks/Function";

function UpcomingWorkingHours(props) {
  var startDate = props.event.start_date;
  var moment = require('moment-timezone'); 
  const workingAllList = props?.workingAllList;

  return (
    <Card className="mb-3">
      <CardBody className="p-2">
        <div className="d-flex   gap-2">
          <span className="fw-medium">
            {props.index}{') '}
          </span>

          <div className="flex-grow-1">
            <span className="fw-medium">
              {props.DefaultDate(startDate, props.selectedCountry)} <br />
              {props?.admin ? props.event?.user?.name : ""}
            </span>
          </div>
          <div className="flex-shrink-0">
            <small className="badge bg-primary-subtle text-primary ms-auto">
              {moment.utc(workingAllList?.length > 0 ? (workingAllList || []).filter(item => (item.user_id === props.event?.user?.id && item.start_date === startDate))
                  ?.reduce((sum, item) => sum + moment.duration(TimeDifference(item)).asMilliseconds(), 0) : 0).format('HH:mm:ss') + " hrs" }
            </small>
          </div>
        </div>

      </CardBody>
    </Card>
  );
}

export default UpcomingWorkingHours;