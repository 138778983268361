import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    Button, Offcanvas, Card, CardBody, OffcanvasHeader, Spinner, Badge,
    OffcanvasBody, Col, Container, Dropdown, ModalHeader, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const LeaveType = () => {
    document.title = "Leave Types";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [searchList, setSearchList] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState(false);
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [leaveTypeView, setLeaveTypeView] = useState(null)
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus

            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        };
    };


    const handleAddClick = (arg) => {
        setSubmit(false);
        validation.resetForm();
        validation.setValues({
            "status": 'Active'
        });
        setIsEdit(false);
        toggleDrawer();
    };


    const handleEditClick = useCallback((arg) => {
        setSubmit(false);
        viewLeaveType(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

    // const handleViewClick = (arg) => {
    //     viewLeaveType(arg?.id);
    //     validation.resetForm();
    //     setIsEdit(false);
    // };

    const onClickData = () => {
        setDeleteModal(true);
    };



    const [statusList, setStatusList] = useState([
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ]);

    const [typeList, setTypeList] = useState([
        { value: "Paid", label: "Paid" },
        { value: "Unpaid", label: "Unpaid" },
    ]);


    const keywordSearch = async (e) => {
        let inputVal = e;

        if (inputVal !== "") {


            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.type?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.code.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.status.toLowerCase().includes(inputVal.toLowerCase()) ||
                String(item.monthly_allowed).includes(inputVal.toLowerCase()) ||
                String(item.number_of_days).includes(inputVal.toLowerCase())

            );


            setLeaveTypeList({
                ...leaveTypeList,
                data: filteredResults
            });


        } else {

            setLeaveTypeList({
                ...leaveTypeList,
                data: searchList?.data
            });



        }

    };


    const getLeaveType = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {

            const response = await api.get(`leaveType`,
                {
                    params: {
                        page: currentPage,
                        perPage: rowPerPage
                    }
                }
            );

            if (response.data.status === true) {


                setSearchList(response.data?.data);
                setLeaveTypeList(response.data?.data);

            } else {
                setSearchList([]);
                setLeaveTypeList([]);

                ToastMessage("error", response.data?.message);


            }

            setLoading(false);

            setCurrentPage(1);

        } catch (err) {
            console.error(err.message);
        }

    };

    const getLeaveTypePage = async (currentPage, rowPerPage) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`leaveType`,
                {
                    params: {
                        page: currentPage,
                        perPage: rowPerPage
                    }
                }
            );

            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setLeaveTypeList(response.data?.data);
            } else {
                setSearchList([]);
                setLeaveTypeList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const viewLeaveType = async (id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('leaveType/' + id, {});

            if (response.data.status === true) {

                validation.setValues(response.data?.data);


                setLeaveTypeView(response.data?.data);




            } else {
                setLeaveTypeView([]);


            }

            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };


    const addLeaveType = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('leaveType', value);
            if (response.data.status === true) {
                getLeaveType();
                validation.resetForm();
                toggleDrawer();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    const editLeaveType = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('leaveType/' + value.id, value);
            if (response.data.status === true) {
                getLeaveType();
                validation.resetForm();
                setIsEdit(false);
                toggleDrawer();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);


        } catch (err) {
            console.error(err.message);
        }

    };

    const deleteLeaveType = async (value) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('leaveType/' + value?.id, {});

            if (response.data.status === true) {
                getLeaveType();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }

    };


    useEffect(() => {
        getLeaveType();

    }, []);


    const validation = useFormik({

        enableReinitialize: true,
        initialValues: {
            name: '',
            type: '',
            code: '',
            status: '',
            number_of_days: '',
            monthly_allowed: '',

        },

        validationSchema: Yup.object({
            name: Yup.string().required("Enter"),
            type: Yup.string().required("Enter"),
            code: Yup.string().required("Enter"),
            status: Yup.string().required("Enter"),
            number_of_days: Yup.string().notRequired(),
            monthly_allowed: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editLeaveType(values, validation.values?.id);

            } else {
                addLeaveType(values);

            }
        },
    });

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(leaveTypeList?.total / rowPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClick = (e) => {
        setCurrentPage(e);
        getLeaveTypePage(e, rowPerPage);
    };

    // const handleprevPage = () => {
    //     let prevPage = currentPage - 1;
    //     setCurrentPage(prevPage);
    //     getLeaveTypePage(prevPage, rowPerPage);
    // };

    // const handlenextPage = () => {
    //     let nextPage = currentPage + 1;
    //     setCurrentPage(nextPage);

    //     getLeaveTypePage(nextPage, rowPerPage);

    // };

    // const renderPageNumbers = () => {
    //     const start = (currentPage - 1) * 1;
    //     const end = start + 10;
    //     const currentSet = pageNumbers.slice(start, end);

    //     return currentSet.map((item, key) => (
    //         <React.Fragment key={key}>
    //             <li className="page-item px-1">
    //                 <Link to="#!" className={currentPage === item ? "page-link active px-1 py-0" : "page-link px-1 py-0"} onClick={() => handleClick(item)}>{item}</Link>
    //             </li>
    //         </React.Fragment>
    //     ));
    // };


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => deleteLeaveType(leaveTypeView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Leave Types</h4>

                            </div>
                        </Col>
                    </Row>


                    <Row className='d-flex align-items-center'  >


                        <Col sm={4}>

                        </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">

                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>

                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                                    <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Leave Type</span></button>

                                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />

                            </div>
                        </Col>




                    </Row>






                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >


                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                        <div className="col-7 col-md-2   px-2">
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>


                                        <div className="col-5 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-md-1">

                                            <h5 className="fs-15 mb-0">Type</h5>
                                        </div>


                                        <div className="col-6 col-md-1  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-md-2">

                                            <h5 className="fs-15 mb-0">Code</h5>
                                        </div>



                                        <div className="col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                                            <h5 className="fs-15 mb-0">Number of Days </h5>
                                        </div>


                                        <div className="col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                                            <h5 className="fs-15 mb-0">Monthly Allowed</h5>
                                        </div>


                                        <div className="col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                                            <h5 className="fs-15 mb-0">Status</h5>
                                        </div>




                                        <div className="col-12 col-md-1   d-flex gap-2 align-items-center justify-content-end px-2">

                                            <h5 className="fs-15 mb-0">Action</h5>
                                        </div>

                                    </div>


                                </CardBody>
                            </Card>

                        </Col>




                    </Row>


                    <Row className="row gy-1 mb-1" id="leavetype-list">
                        {loading ?
                            <div className='text-center'>

                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>

                            :
                            leaveTypeList?.data?.length !== 0 ?

                                (leaveTypeList?.data)?.map((item, index) => (

                                    <Col lg={12} key={index} className='mt-0 '  >

                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                            <CardBody className="px-2 py-0">

                                                <div className="d-lg-flex align-items-center row">

                                                    <div className="col-7 col-md-2">

                                                        {item.name}

                                                    </div>

                                                    <div className="col-5 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                                                        {item.type}
                                                    </div>
                                                    <div className="col-4 col-md-1  mx-auto d-flex gap-2 align-items-center">

                                                        {item.code}

                                                    </div>


                                                    <div className="col-4 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                                                        {item.number_of_days}
                                                    </div>

                                                    <div className="col-4 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                                                        {item.monthly_allowed}
                                                    </div>


                                                    <div className="col-6 col-md-2  mx-auto d-flex gap-2 align-items-center">

                                                        <Badge color={`${item.status === "Active" ? "success-subtle text-success" : "danger-subtle text-danger"}`}  >{item?.status}</Badge>
                                                    </div>



                                                    <div className="col-6 col-md-1  mx-auto d-flex gap-0 align-items-center justify-content-end px-2">
                                                        <button type="button" className="btn  btn-sm btn-icon btn25"
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-icon btn25"
                                                            onClick={(e) => { onClickData(item); setLeaveTypeView(item); }}>
                                                            < i className="ri-delete-bin-fill fs-18 secondary" ></i>

                                                        </button>



                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>


                                ))

                                :
                                <div className="noresult" >
                                    <div className="text-center">

                                        <h6 className="mt-2">Sorry! No Result Found</h6>

                                    </div>
                                </div>
                        }
                    </Row>



                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">


                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getLeaveTypePage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >

                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={leaveTypeList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={leaveTypeList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />


                        </div>


                    </div>



                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{!isEdit ? "Add Leave Type" : "Update Leave Type"}</span>
                                <span>
                                    <span className="fs-18 px-1  text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">


                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">

                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name" className="form-label">Name <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                                                        name='name'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            submit && validation.errors.name ? true : false
                                                        }
                                                    />

                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="type" className="form-label">Type <span className='secondary'>*</span></Label>

                                                    <Select
                                                        value={validation.values.type ? typeList?.filter(
                                                            item => item?.value === validation.values.type)?.map((item, index) => {

                                                                return (
                                                                    { value: item.value, label: item.label }
                                                                );
                                                            }) : ""}


                                                        onChange={(e) => {

                                                            validation.setValues({
                                                                ...validation.values,
                                                                type: e?.value
                                                            });


                                                        }}

                                                        options={typeList?.map((item) => {

                                                            return (
                                                                { value: item.value, label: item.label }
                                                            );
                                                        })}


                                                        styles={selectStyle('type')}
                                                    />

                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="code" className="form-label">Code <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="code" placeholder="Enter code"
                                                        name='code'
                                                        validate={{
                                                            required: { value: true },
                                                        }}

                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.code || ""}
                                                        invalid={
                                                            submit && validation.errors.code ? true : false
                                                        }
                                                    />

                                                </div>
                                            </Col>



                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="status" className="form-label">Status <span className='secondary'>*</span></Label>

                                                    <Select value={validation.values.status ? statusList?.filter(
                                                        item => item?.value === validation.values.status)?.map((item, index) => {
                                                            return (
                                                                { value: item.value, label: item.label }
                                                            );
                                                        }) : ""} 

                                                        onChange={(e) => { 
                                                            validation.setValues({
                                                                ...validation.values,
                                                                status: e?.value
                                                            });
 
                                                        }}

                                                        options={statusList?.map((item) => {
                                                            return ( { value: item.value, label: item.label } );
                                                        })}
 
                                                        styles={selectStyle('status')}
                                                    />

                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="number_of_days" className="form-label">Number of Days  </Label>
                                                    <Input type="number" className="form-control px-2 py-1" id="number_of_days" placeholder="Enter number of days"
                                                        name='number_of_days'

                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.number_of_days}

                                                    />

                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="monthly_allowed" className="form-label">Monthly Allowed  </Label>
                                                    <Input type="number" className="form-control px-2 py-1" id="monthly_allowed" placeholder="Enter monthly allowed"
                                                        name='monthly_allowed'
                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.monthly_allowed}

                                                    />

                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">

                                                    <button type="submit" className="btn btn-primary shadow-lg" onClick={() => {
                                                        setSubmit(true);
                                                    }}>{"Save"}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>


                                </div>
                            </SimpleBar>

                        </OffcanvasBody>

                    </Offcanvas>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default LeaveType;