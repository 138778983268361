import React, { useState, useEffect, useCallback } from 'react';
import { Offcanvas, Card, CardBody, OffcanvasHeader, OffcanvasBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const Permission = () => {
    document.title = "Permission";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [deleteModal, setDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [permissionView, setPermissionView] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    // const toggle = useCallback(() => {
    //     if (modal) {
    //         setModal(false);
    //         setTeamMem(null);
    //     } else {
    //         setModal(true);
    //     }
    // }, [modal]);
    const handleEditClick = useCallback((arg) => {
        setSubmit(false);
        validation.resetForm();
        viewPermission(arg?.id);
        setIsEdit(true);
        toggleDrawer();
    }, []);

    const handleAddClick = (arg) => {
        setSubmit(false);
        validation.resetForm();
        validation.setValues({});
        setIsEdit(false);
        toggleDrawer();
    };

    const onClickData = (team) => {
        setDeleteModal(true);
    };

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase()) );

            setPermissionList({
                ...permissionList,
                data: filteredResults
            });

        } else {
            setPermissionList({
                ...permissionList,
                data: searchList?.data
            });
        }
    };

    const getPermission = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`permission?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setPermissionList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setPermissionList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const getPermissionPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`permission?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setPermissionList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setPermissionList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const viewPermission = async (id, name) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('permission/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
                setPermissionView(response.data?.data);
                setSelectedItems(Object.keys(response.data?.data));
            } else {
                setPermissionView([]);
                setSelectedItems([]);
            }
            setOpen(true);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getPermission();
    }, []);


    const addPermissions = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('permission', value);

            if (response.data.status === true) {
                getPermission();
                validation.resetForm();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            toggleDrawer();

        } catch (err) {
            console.error(err.message);
        }

    };

    const editPermissions = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('permission/' + value.id, value);

            if (response.data.status === true) {
                getPermission();
                validation.resetForm();
                setIsEdit(false);

                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            toggleDrawer();

        } catch (err) {
            console.error(err.message);
        }

    };

    const deletePermissions = async (value) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('permission/' + value?.id, {});

            if (response.data.status === true) {
                getPermission();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }

    };

    const [selectedItems, setSelectedItems] = useState([]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            permissions: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Permission Name"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editPermissions({
                    id: validation.values?.id,
                    name: values?.name,
                    permission: selectedItems
                });

            } else {
                addPermissions({
                    name: values?.name,
                    permission: selectedItems
                });
            }
        },
    });


    const handleCheckboxChange = (item) => {
        if (selectedItems.includes(item?.id)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item?.id));
        } else {
            setSelectedItems([...selectedItems, item?.id]);
        }
    };

    const handleEditCheckboxChange = (item) => {
        if (selectedItems.includes(item?.id.toString())) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item?.id.toString()));
        } else {
            setSelectedItems([...selectedItems, item?.id.toString()]);
        }
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getPermissionPage(e, rowPerPage);
    };


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => deletePermissions(permissionView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Permision</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center'  >
                        <Col sm={4}>  </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                                    <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Permission</span></button>
                                <FaPlus className="text-primary d-flex d-md-none" size={18} onClick={() => { handleAddClick(); }} />
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1 mt-1 px-2" >
                        <Col lg={12} className='table px-1  mb-0'>
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center px-0 py-2">
                                        <div className="col-7 col-md-11 px-2">
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>

                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                            <h5 className="fs-15 mb-0">Action</h5>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1 " id="permission-list">
                        {
                            permissionList?.data?.length !== 0 ?
                                (permissionList?.data)?.map((item, index) => (
                                    <Col lg={12} key={index} className='mt-0' >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-flex align-items-center row">
                                                    <div className="col-9 col-md-11"> {item.name}   </div>
                                                    <div className="col-3 col-md-1 d-flex gap-0 align-items-center justify-content-end">
                                                        <button type="button" className="btn  btn-sm btn-icon btn25"
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                        </button>
                                                        <button type="button" className="btn btn-sm btn-icon btn25"
                                                            onClick={(e) => { onClickData(item); setPermissionView(item); }}>
                                                            < i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>

                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getPermissionPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={permissionList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={permissionList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{!isEdit ? "Add Permision" : "Update Permision"}</span>
                                <span>
                                    <span className="fs-18  px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="permissionName" className="form-label">Name <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="permissionName" placeholder="Enter name"
                                                        name='name'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        // readOnly={isEdit ? false  : true}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            submit && validation.errors.name ? true : false
                                                        }
                                                    />
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary shadow-lg" id="addNewPermission" onClick={() => {
                                                        setSubmit(true);
                                                    }}>{"Save"}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Permission;