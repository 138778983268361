import React, { useEffect, useState, useCallback } from 'react';
import {
    Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner,
    Offcanvas,
    OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, socialLogin, resetLoginFlag } from "../../slices/thunks";
import { createSelector } from 'reselect';
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import SimpleBar from "simplebar-react";
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import { ToastMessage, DefaultDate, DefaultDateWithTime, SmallDateFormat, SmallDateTimeFormat, FormatDate, FormatDateTime, FormatMinDate } from "../../Components/Hooks/Function";
import DeleteModal from "../../Components/Common/DeleteModal";


const Blogs = () => {
    document.title = "Blogs";
    //  const history = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [loading, setLoading] = useState(false);
    const [blogList, setBlogList] = useState([]);
    const [blogView, setBlogView] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [isExpanded, setIsExpanded] = useState([]);

    const [deleteModal, setDeleteModal] = useState(false);

    const [searchList, setSearchList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);

    const [statusList, setStatusList] = useState([
        { value: 1, label: 'Public' },
        { value: 2, label: 'Private' },
    ]);
    // const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [attachmentList, setAttachmentList] = useState([]);

    const [file, setFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedMulti, setSelectedMulti] = useState(null);

    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                //  borderColor: state.isFocused ? 'grey'   :'#ced4da'  ,
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'

            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus
            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }

    const tagStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                padding: '0px',
                //  borderColor: state.isFocused ? 'grey'   :'#ced4da'  ,
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'

            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),

            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus
            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }

    const handleAddClick = () => {
        setSelectedMulti();
        setView(false);
        setIsEdit(false);
        setAttachmentList([]);
        setFile([]);
        setFiles([]);
        setView(false);
        setSubmit(false);
        validation.setValues({ status: 1 });
        toggleDrawer();
    };

    const handleEditClick = useCallback((arg) => {
        setSelectedMulti();
        setView(false);
        setSubmit(false);
        setFile([]);
        setFiles([]);
        setAttachmentList([]);
        viewBlog(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

    const handleViewClick = (arg) => {
        setView(true);
        viewBlog(arg?.id);
        validation.resetForm();
        setIsEdit(false);
    };


    const handleThumbnailChange = (e) => {
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
        const fileDate = Array.from(e.target.files);
        const totalSize = fileDate.reduce((acc, file) => acc + file.size, 0);
        if (totalSize <= maxSizeInBytes) {
            setFile(fileDate);
            setFiles(fileDate.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));

            validation.setValues({
                ...validation.values,
                thumbnail: fileDate[0].name
            });

        } else {
            setFile([]);
            setFiles([]);

            ToastMessage("error", "Uplaoad File is Greater than 10 MB");
        }
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getBlogPage(e, rowPerPage);
    };


    const getBlog = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';

        try {
            const response = await api.get(`blog?page=${currentPage}&perPage=${rowPerPage}`, {});

            if (response.data.status === true) {
                setSearchList(response.data.data);
                setBlogList(response.data.data);

            } else {
                setSearchList([]);
                setBlogList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }

    };

    const getBlogCategory = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';

        try {
            const response = await api.get(`blogCategoryList`, {});

            if (response.data.status === true) {
                const formattedOptions = formatOptions(response.data?.data);
                setCategoryList(formattedOptions);

            } else {

                setCategoryList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }

    };

    const getTag = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';

        try {
            const response = await api.get(`blogTagList`, {});
            if (response.data.status === true) {
                setTagList(response.data.data);

            } else {
                setTagList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }

    };

    const formatOptions = (apiData) => {
        const groupedOptions = {};
        apiData.forEach(option => {
            const group = option.parent?.name;
            const value = option.id;
            const label = option.name;
            if (!groupedOptions[group]) {
                groupedOptions[group] = [];
            }

            groupedOptions[group].push({ value, label });
        });

        const formattedOptions = Object.keys(groupedOptions).map(group => ({
            label: group,
            options: groupedOptions[group],
        }));
        return formattedOptions;
    };

    const getBlogPage = async (currentPage, rowPerPage) => {

        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {

            const response = await api.get(`blog?page=${currentPage}&perPage=${rowPerPage}`);

            if (response.data.status === true) {
                setSearchList([]);
                setBlogList([]);

            } else {
                setSearchList([]);
                setBlogList([]);

                ToastMessage("error", response.data?.message);
            }

            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const onClickData = (team) => {
        setDeleteModal(true);
    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            title: '',
            thumbnail: '',
            description: '',
            blog_category_id: '',
            status: '',
            publish: '',
            tags: '',

        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter"),
            thumbnail: isEdit ? Yup.string() : Yup.string().required("Please enter"),
            description: Yup.string().required("Please enter"),
            blog_category_id: Yup.string().required("Please enter"),
            status: Yup.string().required("Please enter"),
            publish: Yup.string().required("Please enter"),
            tags: Yup.array().notRequired(),
        }),

        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEdit) {
                    await editBlog(values, validation.values?.id);
                } else {
                    await addBlog(values);
                }

                setSubmitting(false);
            } catch (error) {

                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },

    });

    const addBlog = async (value) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {

            const formData = new FormData();
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    if (key === "publish") {
                        formData.append('publish', value.publish ? FormatDateTime(value.publish, selectedCountry) : "");
                    } else if (key === "tags") {
                        formData.append('tags', selectedMulti?.map(item => (item.value)));
                    } else {
                        formData.append(key, value[key] || '');
                    }
                }
            }

            if (file) {
                formData.append(`attachment`, file[0]);
            }



            const response = await api.post('blog', formData);

            if (response.data.status === true) {
                getBlog();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }

    };

    const editBlog = async (value) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            formData.append('_method', "PUT");
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    if (key === "publish") {
                        formData.append('publish', value.publish ? FormatDateTime(value.publish, selectedCountry) : "");
                    } else if (key === "tags") {
                        formData.append('tags', selectedMulti?.map(item => (item.value)));
                    } else {
                        formData.append(key, value[key] || '');
                    }
                }
            }

            if (file) {
                formData.append(`thumbnail`, file[0]);
            }

            if (selectedMulti) {
                formData.append(`tagsList`, selectedMulti);
            }

            const response = await api.post('blog/' + value.id, formData);

            if (response.data.status === true) {
                getBlog();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }

    };


    const viewBlog = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('blog/' + id, {});
            if (response.data.status === true) {

                validation.setValues({
                    ...response.data?.data,
                    publish: DefaultDateWithTime(response.data?.data?.publish, selectedCountry),
                    tags: response.data?.data?.tags_in_blogs?.map(item => { return { value: item.blogtags?.id, label: item.blogtags?.name }; })
                });

                setBlogView({
                    ...response.data?.data,
                    tags: response.data?.data?.tags_in_blogs?.map(item => { return item.blogtags?.name + ", "; })
                });

                setSelectedMulti(response.data?.data?.tags_in_blogs?.map(item => { return { value: item.blogtags?.id, label: item.blogtags?.name }; }));


                setAttachmentList(response.data?.attachment);
                setOpen(true);
            } else {
                setBlogView([]);
                setAttachmentList([]);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const deleteBlog = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('blog/' + value?.id, {});
            if (response.data.status === true) {
                getBlog();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)
        } catch (err) {
            console.error(err.message);
        }

    };

    const tagRemove = async (id) => {

        if (validation.values.id && id) {
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            api.defaults.headers.common['Content-Type'] = 'application/json'
            try {
                const response = await api.delete('tagRemove/' + id + "/" + validation.values.id, {});
                if (response.data.status === true) {

                } else {
                    ToastMessage("error", response.data?.message);
                }

            } catch (err) {
                console.error(err.message);
            }
        }
    };

    const tagRemoveAll = async () => {
        if (validation.values.id) {
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            api.defaults.headers.common['Content-Type'] = 'application/json'
            try {
                const response = await api.delete('tagRemoveAll/' + validation.values.id, {});
                if (response.data.status === true) {

                } else {
                    ToastMessage("error", response.data?.message);
                }
            } catch (err) {
                console.error(err.message);
            }
        }


    };


    useEffect(() => {
        getBlog();
        getTag();
        getBlogCategory();
    }, []);


    const handleToggle = (index) => {
        const newExpandedStates = [...isExpanded];
        newExpandedStates[index] = !newExpandedStates[index];
        setIsExpanded(newExpandedStates);
    };

    useEffect(() => {
        setIsExpanded(blogList?.data?.map(() => false))
    }, [blogList]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={blogView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteBlog(blogView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Blogs</h4>
                            </div>
                        </Col>
                    </Row>


                    <Row className='d-flex align-items-center'  >
                        <Col sm={4}> </Col>
                        <Col className="col-sm-auto ms-auto d-flex align-items-center gap-2 px-2 py-1">
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                                    <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Blog</span></button>
                                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />
                            </div>


                        </Col>
                    </Row>


                    <Row className="row gy-1 mb-1  mt-1 px-2" >
                        <Col lg={12} className='table px-1 mb-0' >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0 rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-7 col-md-2 px-2">
                                            <h5 className="fs-15 mb-0"> Title</h5>
                                        </div>
                                        <div className="d-flex   col-6 col-md-2  mt-0 align-items-center  justify-content-end  justify-content-md-start px-2">
                                            <h5 className="fs-15 mb-0">Categories</h5>
                                        </div>
                                        <div className="d-flex col-6 ml-0 col-md-4 mt-0 align-items-center px-2">
                                            <h5 className="fs-15 mb-0">Description</h5>
                                        </div>

                                        <div className="d-flex  col-6 col-md-2  mt-0 align-items-center  justify-content-end  justify-content-md-start px-2">
                                            <h5 className="fs-15 mb-0">Publish Date</h5>
                                        </div>
                                        <div className="  col-12 col-md-1 d-flex align-items-center ">
                                            <h5 className="fs-15 mb-0" >Status</h5>
                                        </div>
                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                            <h5 className="fs-15 mb-0">  Actions</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1" id="blog-list">
                        {loading ?
                            <div className='text-center'>

                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>

                            :
                            blogList?.data?.length !== 0 ?

                                (blogList?.data)?.map((item, index) => {
                                    return (
                                        <Col lg={12} key={index} className='mt-0' >
                                            <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                                <CardBody className="px-2 py-0">
                                                    <div className="d-lg-flex align-items-center row">

                                                        <div className="col-9 col-md-2 order-0 order-md-0">
                                                            <a href="#!" onClick={() => { handleViewClick(item); }}>  {item.title}  </a>
                                                        </div>

                                                        <div className="col-12 col-md-2 order-2 order-md-1 align-items-center">
                                                            <p className="mb-0">  {item.blog_category?.name}</p>
                                                        </div>

                                                        <div className={`${isExpanded?.[index] ? "d-block" : "d-flex"} col-12 col-md-4 order-3 order-md-2 gap-2 align-items-center`}>

                                                            <div dangerouslySetInnerHTML={{ __html: isExpanded?.[index] ? item.description : item?.description?.length > 35 ? item.description.substring(0, 35) + "...." : item?.description }} className='text_html' />
                                                            {item?.description?.length > 35 && (
                                                                <a href="#!" key={item.id} onClick={() => handleToggle(index)} className='text-nowrap'>
                                                                    {isExpanded?.[index] ? 'Read Less' : 'Read More'}
                                                                </a>
                                                            )}
                                                        </div>
                                                        <div className="col-8 col-md-2  order-4 order-md-3 align-items-center">
                                                            <p className="mb-0 ">  {DefaultDateWithTime(item.publish, selectedCountry)}</p>
                                                        </div>

                                                        <div className="col-4 col-md-1 order-5 order-md-4 d-flex align-items-center justify-content-end justify-content-md-start ">
                                                            <p className="mb-0">  {statusList?.filter(key => key?.value === parseInt(item.status))?.[0]?.label}</p>
                                                        </div>

                                                        <div className="col-3 col-md-1  order-6 order-md-5 mx-auto d-flex  gap-0 align-items-center justify-content-end px-1">
                                                            {item.blog_attachments?.length > 0 ?
                                                                <button type="button" className="btn  btn-sm btn-icon btn25" onClick={(e) => { viewAttachmet(item.id) }}>
                                                                    <i className="ri-attachment-2 fs-18 primary"></i>
                                                                </button> : " "}

                                                            <button type="button" className="btn  btn-sm btn-icon btn25" onClick={(e) => { handleEditClick(item); }}>
                                                                <i className="ri-pencil-fill fs-18 primary"></i>
                                                            </button>

                                                            <button type="button" className="btn btn-sm btn-icon btn25" onClick={(e) => { onClickData(item); setBlogView(item); }}>
                                                                < i className="ri-delete-bin-fill fs-18 secondary"></i>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>


                                    )
                                })

                                :
                                <div className="noresult" >
                                    <div className="text-center">

                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>


                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getBlogPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={blogList?.total}>All</option>
                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={blogList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>

                    </div>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <h5 className="m-0 me-2 text-white">   {!view ? !isEdit ? "Add Blog" : "Update Blog" : "View Blog"} </h5>

                                <span>
                                    {!view ? <span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>

                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                    {view ? <>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Title </Label>  <br />
                                                    {blogView.title}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Thumbnail Image </Label> <br />
                                                    {blogView?.thumbnail ?
                                                        <img src={url + blogView?.thumbnail} alt="" className="rounded-circle avatar-sm" />
                                                        : ""
                                                        // <div className="avatar-sm">
                                                        //     <div className="avatar-title rounded-circle bg-light text-primary">
                                                        //         {blogView?.name?.slice(0, 1)}
                                                        //     </div>
                                                        // </div>
                                                    }
                                                </div>
                                            </Col>


                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Description </Label> <br />
                                                    <div dangerouslySetInnerHTML={{ __html: blogView?.description }} />
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Categories  </Label> <br />
                                                    {blogView.blog_category?.name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Status   </Label> <br />
                                                    {statusList?.filter(key => key?.value === parseInt(blogView.status))?.[0]?.label}
                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Publish    </Label> <br />
                                                    {DefaultDateWithTime(blogView.publish, selectedCountry)}
                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Tags    </Label> <br />
                                                    {blogView.tags}
                                                </div>
                                            </Col>

                                        </Row>
                                    </> : <>

                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="roleName" className="form-label"> Title <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="title" placeholder="Enter title"
                                                            name='title'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.title || ""}
                                                            invalid={submit && validation.errors.title ? true : false}
                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="blog_category_id" className="form-label">Category <span className='secondary'>*</span></Label>

                                                        <Select
                                                            defaultValue={
                                                                validation.values.blog_category?.name ? {
                                                                    "value": validation.values.blog_category_id,
                                                                    "label": validation.values.blog_category?.name
                                                                } : ""}

                                                            options={categoryList}

                                                            placeholder="Select..."
                                                            name='blog_category_id'
                                                            onChange={(e) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    blog_category_id: e?.value
                                                                });
                                                            }}

                                                            styles={selectStyle('blog_category_id')}
                                                        />
                                                    </div>

                                                </Col>


                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="roleName" className="form-label"> Thumbnail Image <span className='secondary'>*</span></Label>
                                                        <Input type="file" accept="image/*" className="form-control px-2 py-1" id="thumbnail" placeholder="Enter thumbnail"
                                                            name='thumbnail' onChange={handleThumbnailChange}
                                                            invalid={submit && validation.errors.thumbnail ? true : false}
                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="description" className="form-label">Description <span className='secondary'>*</span></Label>
                                                        <div style={{ border: `1px solid ${submit && validation.errors.description ? 'red' : 'transparent'}`, borderRadius: '5px', marginBottom: '10px' }}>
                                                            <CKEditor
                                                                name="description"
                                                                editor={ClassicEditor}
                                                                data={validation.values.description || ""}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    validation.setValues({
                                                                        ...validation.values,
                                                                        description: data ? data : ""
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="status" className="form-label">Status   <span className='secondary'>*</span></Label>
                                                        <Select
                                                            name='status'
                                                            defaultValue={statusList?.filter(item => item?.value === parseInt(validation.values.status))}
                                                            onChange={(e) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    status: e?.value
                                                                });
                                                            }}

                                                            options={statusList}
                                                            styles={selectStyle('status')}
                                                        />
                                                    </div>

                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="roleName" className="form-label"> Publish <span className='secondary'>*</span></Label>
                                                        <Flatpickr
                                                            name='publish'
                                                            className={`form-control py-1 px-2 ${submit && validation.errors.publish ? 'is-invalid' : ''}`}
                                                            options={{
                                                                allowInput: true,
                                                                enableTime: true,
                                                                dateFormat: SmallDateTimeFormat(selectedCountry),
                                                            }}
                                                            onChange={(selectedDates, dateStr, instance) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    publish: dateStr
                                                                });
                                                            }}
                                                            value={validation.values?.publish || ""}
                                                        />
                                                    </div>
                                                </Col>


                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="roleName" className="form-label"> Tags    </Label>
                                                        <Select
                                                            value={selectedMulti}
                                                            isMulti
                                                            onChange={(selectedOptions, actionMeta) => {
                                                                if (actionMeta.action === 'remove-value') {

                                                                    tagRemove(actionMeta.removedValue?.value);

                                                                } else if (actionMeta.action === 'clear') {

                                                                    tagRemoveAll();

                                                                } else if (actionMeta.action === 'pop-value') {

                                                                    tagRemove(actionMeta.removedValue?.value);

                                                                }

                                                                setSelectedMulti(selectedOptions);
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    tags: selectedOptions
                                                                });

                                                            }}
                                                            options={tagList?.map((item) => {
                                                                return ({ value: item.id, label: item.name });
                                                            })}

                                                            styles={tagStyle('tags')}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={12}  >
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="submit" className="btn btn-primary shadow-lg" id="addNew" onClick={() => { setSubmit(true); }}>
                                                            {!isEdit ? "Add Blogs" : "Update Blogs"}</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </>}

                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Blogs;