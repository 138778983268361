import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Offcanvas, Card, CardBody, Spinner, OffcanvasHeader, Button,
  OffcanvasBody, Col, Container, ModalHeader, Form, Input, Label, Modal, ModalBody, ModalFooter, Row
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import Slider from "react-rangeslider";
import { api, url } from "../../helpers/api";
import Select from "react-select";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimesCircle, FaTimes, FaPlus, FaFilePdf, FaFileAlt, FaFileExcel, FaCloudDownloadAlt, FaPen } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FeatherIcon from 'feather-icons-react';
import Pagination from "react-js-pagination";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import "react-rangeslider/lib/index.css";
import { FormatDate } from "../../Components/Hooks/Function";
import { ToastMessage, DefaultDate, SmallDateFormat } from "../../Components/Hooks/Function";

const EODReport = () => {
  document.title = "End of Day Report";
  var moment = require('moment-timezone');
  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
  const pdfExtensions = new Set(["pdf"]);
  const excelExtensions = new Set(["xls", "xlsx"]);
  const [modalFile, setModalFile] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [modalEOD, setModalEOD] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [userList, setUserList] = useState([]);
  const getDefaultDate = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return [firstDayOfMonth, lastDayOfMonth];
  };

  const editorConfig = {
    height: 200,
    toolbar: {
      items: ['bold', 'italic', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
    }
  };

  const formatPercentage = (value) => value + "%";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [searchList, setSearchList] = useState([]);
  const userDetails = getLoggedinUser()?.user;
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [view, setView] = useState(false);
  const [EODList, setEODList] = useState([]);
  const [EODReportList, setEODReporList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [EODView, setEODView] = useState(null);
  const [submit, setSubmit] = useState(false);

  const selectStyles = (fieldName) => {
    return {
      container: (baseStyles, state) => ({
        ...baseStyles,
        boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        margin: "0px 0px 5px 0px",
        minHeight: "30px",
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      dropdownButton: (baseStyles, state) => ({
        ...baseStyles,
        width: "100%",
        height: "30px",
        padding: "5px 8px",
        boxWidth: "none",
        boxShadow: "none",
        color: "rgb(118, 118, 118)",
        border: "1px solid #ced4da",
        "& svg": {
          color: "hsl(0, 0%, 80%)",
          height: "35",
          width: "35"
        }
      }),
      groupHeading: (provided) => ({
        fontWeight: 'bold',
        // color: '#019aff',
        padding: "5px 12px",
        paddingLeft: '6px',
        cursor: "pointer",
        alignItems: 'center',
        display: 'flex',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        cursor: "pointer",
        paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
        fontWeight: state.data.label === 'Select All' && 'bold',
        // color: state.data.label === 'Select All' && '#019aff',
        // padding:"5px 12px",
        height: "30px",
        boxShadow: "none",
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active": {
          backgroundColor: "unset",
        },
      }),
    };
  };

  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? ' ' : ' ',
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px', // Change padding on focus

      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    };
  };

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleViewClick = (arg) => {
    setAttachmentList([]);
    setFile([]);
    setFiles([]);
    setView(true);
    setSubmit(false);
    viewEOD(arg?.id);
    validation.resetForm();
    setIsEdit(false);
  };

  const handleEditClick = useCallback((arg) => {
    setAttachmentList([]);
    setFile([]);
    setFiles([]);
    setView(false);
    setSubmit(false);
    viewEOD(arg?.id);
    setIsEdit(true);
    validation.resetForm();
  }, []);


  const handleAddClick = (arg) => {
    setAttachmentList([]);
    setFile([]);
    setFiles([]);
    setView(false);
    setSubmit(false);
    validation.resetForm();
    validation.setValues({
      date: new Date().toISOString(),
      task_completed_today: 1,
    })
    setIsEdit(false);
    toggleDrawer();
  };

  const handleReportDownload = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`getAllEndOfDayReport`, {
        params: {
          user_id: user_idParam,
          project_id: project_idParam,
          startDate: startDateParam,
          endDate: endDateParam,
          companyName: getSettingsDetails("settings").filter(type => type?.name === "name")[0]?.value,
          selectedCountry: selectedCountry,
          logo: getSettingsDetails("settings").filter(type => type?.name === "logo-light")[0]?.value,
          download: true
        },
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'EOD Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleReportClick = async () => {
    setShowEdit(false);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`getAllEndOfDayReport`, {
        params: {
          user_id: user_idParam,
          project_id: project_idParam,
          startDate: startDateParam,
          endDate: endDateParam,
          companyName: getSettingsDetails("settings").filter(type => type?.name === "name")[0]?.value,
          selectedCountry: selectedCountry,
          logo: getSettingsDetails("settings").filter(type => type?.name === "logo-light")[0]?.value,
          download: false
        },
      });

      if (response.data.status === true) {
        setEODReporList(response.data?.data);
      } else {
        setEODReporList([]);
      }
      setModalEOD(!modalEOD)

    } catch (err) {
      console.error(err.message);
    }
  };

  const onClickData = (team) => {
    setDeleteModal(true);
  };

  function getExtension(filename) {
    return filename?.split('.').pop()
  }

  const handleFileChange = (e) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize <= maxSizeInBytes) {
      const allowedFiles = files.filter((file) =>
        file.type === 'application/pdf' || file.type.startsWith('image/')
      );
      setFile(allowedFiles);
      setFiles(
        allowedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    } else {
      setFile([]);
      setFiles([]);
      toast("Uplaoad File is Greater than 10 MB",
        {
          position: "top-right", hideProgressBar: true,
          className: 'secondary',
          progress: undefined, toastId: ""
        })
    }
  }

  const handleClick = (e) => {
    setCurrentPage(e);
    getEODReportPage(e, rowPerPage);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState("50");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fileUrl, setFileUrl] = useState([]);
  const [modalAttachment, setModalAttachment] = useState({
    show: false,
    file: [],
  });

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
    );
  };

  const [fromValue, setFromValue] = useState(moment().clone().tz(moment.tz.guess()).startOf('month'));
  const [toValue, setToValue] = useState(moment().clone().tz(moment.tz.guess()).endOf('month'));
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setFromValue(selectedDates[0]);
      setToValue(selectedDates[1]);
      filterValidation.handleSubmit();
    }
  };

  const filterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: '',
      endDate: '',
      project_id: projectList?.map((item, index) => {
        return (
          { value: item.id, label: item.name }
        );
      }),
      user_id: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
        return (
          { value: item.id, label: item.name }
        );
      }),
    },
    validationSchema: Yup.object({
      user_id: Yup.array(),
      project_id: Yup.array(),
      startDate: Yup.date(),
      endDate: Yup.date(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
      api.defaults.headers.common['Content-Type'] = 'application/json';
      try {
        const response = await api.get(`endOfDayReport`, {
          params: {
            user_id: user_idParam,
            project_id: project_idParam,
            startDate: startDateParam,
            endDate: endDateParam,
            page: currentPage,
            perPage: rowPerPage
          }
        });

        if (response.data.status === true) {
          setSearchList(response.data?.data);
          setEODList(response.data?.data);
        } else {
          setSearchList([]);
          setEODList([]);
        }
        setLoading(false);
      } catch (err) {
        console.error(err.message);
      }
    },
  });

  const handleUserSelectionChange = (selected) => {
    filterValidation.setValues({
      ...filterValidation.values,
      user_id: selected.length > 0 ? selected : [{
        name: "Select...",
        value: 0
      }]
    });

    filterValidation.handleSubmit();
  };

  const handleProjectSelectionChange = (selected) => {
    filterValidation.setValues({
      ...filterValidation.values,
      project_id: selected.length > 0 ? selected : [{
        name: "Select...",
        value: ""
      }]
    });

    filterValidation.handleSubmit();
  };

  const startDateParam = fromValue ? `${moment(fromValue).format('YYYY-MM-DD')}` : '';
  const endDateParam = toValue ? `${moment(toValue).format('YYYY-MM-DD')}` : '';
  const user_idParam = userDetails?.roles?.[0]?.name === "Admin" ? filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '' : '';
  const project_idParam = filterValidation?.values?.project_id ? filterValidation.values.project_id?.map((value) => (value.value)).filter(value => value !== "") : '';

  const getAllUser = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getAllUser', {});
      if (response.data.status === true) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }
    } catch (err) {
      console.error(err.message);
    }

  };

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  };

  const getUserAllAssignedProject = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('userAllAssignedProject/' + id, {});
      if (response.data.status === true) {
        setProjectList(response.data?.data);
      } else {
        setProjectList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const getProject = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`getAllProject`);
      if (response.data.status === true) {
        setProjectList(response.data?.data);
      } else {
        setProjectList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getSettings();
    getAllUser();
    getEODReport();
    if (userDetails?.roles?.[0]?.name === "Admin") {
      getProject();
    } else {
      getUserAllAssignedProject(userDetails?.id);
    }
  }, []);



  const [loading, setLoading] = useState(false);
  const getEODReport = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`endOfDayReport`, {
        params: {
          user_id: user_idParam,
          startDate: startDateParam,
          endDate: endDateParam,
          page: currentPage,
          perPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setEODList(response.data?.data);
        setSearchList(response.data?.data);
      } else {
        setEODList([]);
        setSearchList([]);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getEODReportPage = async (year) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`endOfDayReport`, {
        params: {
          user_id: user_idParam,
          startDate: startDateParam,
          endDate: endDateParam,
          page: currentPage,
          perPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setEODList(response.data?.data);
        setSearchList(response.data?.data);
      } else {
        setEODList([]);
        setSearchList([]);
        ToastMessage("error", response.data?.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const viewEOD = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('endOfDayReport/' + id, {});
      if (response.data.status === true) {
        validation.setValues({
          ...response.data?.data,
          date: DefaultDate(response.data?.data?.date, selectedCountry)
        });
        setEODView(response.data?.data);
        setAttachmentList(response.data?.attachment);
        setOpen(true);
      } else {
        setEODView([]);
        setAttachmentList([]);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const viewAttachmet = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('endOfDayReportAttachment/' + id, {});
      if (response.data.status === true) {
        setModalAttachment({ ...modalAttachment, show: true, file: response.data.data });
      } else {
        setModalAttachment({ ...modalAttachment, show: true, file: [], });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  const addEOD = async (values) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          if (key === "date") {
            formData.append('date', values.date ? FormatDate(values.date, selectedCountry) : "");
          } else {
            formData.append(key, values[key] || '');
          }
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });

      formData.append(`status`, 1);
      const response = await api.post('endOfDayReport', formData);
      if (response.data.status === true) {
        getEODReport();
        validation.resetForm();
        setOpen(false);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
      validation.setSubmitting(false);
    }

  };

  const editEOD = async (values, id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('_method', "PUT");
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          if (key === "date") {
            formData.append('date', values.date ? FormatDate(values.date, selectedCountry) : "");
          } else {
            formData.append(key, values[key] || '');
          }
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });

      const response = await api.post('endOfDayReport/' + id, formData);
      if (response.data.status === true) {
        getEODReport();
        setIsEdit(false);
        validation.resetForm();
        setOpen(false);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
      validation.setSubmitting(false);
    }

  };

  const deleteEODReport = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('endOfDayReport/' + value?.id, {});
      if (response.data.status === true) {
        getEODReport();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message); s
      }
      setDeleteModal(false)
    } catch (err) {
      console.error(err.message);
      validation.setSubmitting(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      today_activity: '',
      date: '',
      project_id: '',
      reporting_to: '',
      task_completed_today: '',
      plans_for_tomorrow: '',
      additional_information: '',
    },
    validationSchema: Yup.object({
      today_activity: Yup.string().required("Enter"),
      date: Yup.string().required("Enter"),
      project_id: Yup.string().required("Enter"),
      reporting_to: Yup.string().required("Enter"),
      task_completed_today: Yup.string().required("Enter"),
      plans_for_tomorrow: Yup.string().notRequired(),
      additional_information: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        editEOD(values, validation.values?.id);

      } else {
        addEOD(values);
      }
    },
  });

  const handleDelete = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('endOfDayReportAttachment/' + id, {});
      if (response.data.status === true) {
        setAttachmentList(attachmentList?.filter(item => item.id !== id));
        setFile(file?.filter(item => item.id !== id));
        setFiles(files?.filter(item => item.id !== id));
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const keywordSearch = async (e) => {
    let inputVal = e;
    if (inputVal !== "") {
      const filteredResults = searchList?.data?.filter(item =>
        item.user?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        DefaultDate(item.date, selectedCountry).includes(inputVal.toLowerCase()) ||
        item?.project?.name.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.today_activity.toLowerCase().includes(inputVal.toLowerCase()) ||
        item?.task_completed_today?.toLowerCase().includes(inputVal.toLowerCase())
      );
      setEODList({
        ...EODList,
        data: filteredResults
      });
    } else {
      setEODList({
        ...EODList,
        data: searchList?.data
      });
    }
  };

  const [isExpanded, setIsExpanded] = useState([]);
  useEffect(() => {
    if (EODList?.data) {
      setIsExpanded(new Array(EODList.data.length).fill(false));
    }
  }, [EODList]);

  const handleToggle = (index) => {
    if (EODList?.data && index >= 0 && index < EODList.data.length) {
      const newExpandedStates = [...isExpanded];
      newExpandedStates[index] = !newExpandedStates[index];
      setIsExpanded(newExpandedStates);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => deleteEODReport(EODView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >End of Day Report</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} lg={6} xl={6} >
              <Form onSubmit={(e) => {
                e.preventDefault();
                filterValidation.handleSubmit();
                return false;
              }}>
                <Row>
                  <Col xs={12} md={5} >
                    <Flatpickr
                      className="form-control py-1 px-2"
                      placeholder="Select Date Range"
                      options={{
                        allowInput: true,
                        mode: 'range',
                        dateFormat: SmallDateFormat(selectedCountry),
                        defaultDate: getDefaultDate(),
                      }}
                      onChange={handleDateChange}
                    />
                  </Col>

                  {userDetails?.roles?.[0]?.name === "Admin" && <Col xs={6} md={3} className='py-1 pe-0 py-md-0 px-md-0' >
                    <ReactMultiSelectCheckboxes
                      value={filterValidation.values.user_id?.filter(item => item?.value !== 0)?.map((item, index) => {
                        return (
                          { value: item.value, label: item.label }
                        );
                      })
                      }

                      options={[{
                        label: "Select All",
                        options: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
                          return ({ value: item.id, label: item.name });
                        }),
                      }]}

                      onChange={handleUserSelectionChange}
                      styles={selectStyles('user_id')}
                    />
                  </Col>}


                  <Col xs={`${userDetails?.roles?.[0]?.name === "Admin" ? 6 : 12}`} md={3} className={`${userDetails?.roles?.[0]?.name === "Admin" ? "" : "px-md-0"} py-1 py-md-0`} >
                    <ReactMultiSelectCheckboxes
                      value={filterValidation.values.project_id?.filter(item => item?.value !== 0)?.map((item, index) => {
                        return ({ value: item.value, label: item.label });
                      })}

                      options={[{
                        label: "Select All",
                        options: projectList?.map((item, index) => {
                          return ({ value: item.id, label: item.name });
                        })
                      }]}

                      onChange={handleProjectSelectionChange}
                      styles={selectStyles('project_id')}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col xs={12} md={6} lg={6} xl={6} className='align-items-center'   >

              <Row className='align-items-center' >
                <Col className="col-xs-12 col-sm-auto ms-auto d-flex py-0 align-items-center gap-2">

                  <div className="search-box">
                    <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                    <i className="ri-search-line search-icon"></i>
                  </div>

                  {userDetails?.roles?.[0]?.name === "Admin" ?
                    <>
                      <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex align-items-center gap-2 px-3 py-1" onClick={() => { handleReportClick(); }} >
                        <i className="ri-download-cloud-fill fs-16 search-icon"></i> <span className='d-none d-md-block'> Get All EOD Report </span></button>

                      <div className='d-flex d-md-none align-items-center'>
                        <FaCloudDownloadAlt className="text-primary" size={25} onClick={() => { handleReportClick(); }} />
                      </div>
                    </>
                    :
                    <>  <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                      <i className="ri-add-line search-icon"></i>   End of Day Report </button>
                      <FaPlus className="text-primary d-flex d-md-none" size={18} onClick={() => handleAddClick()} />
                    </>
                  }
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row  gy-1 mb-1  mt-1  px-2"  >
            <Col lg={12} className='table px-1  mb-0'  >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center px-0 py-2 d-none d-md-none d-lg-block">

                    <div className="col-2 px-2">
                      <h5 className="fs-15 mb-0"> Employee Name</h5>
                    </div>

                    <div className="col-1 d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-md-0">
                      <h5 className="fs-15 mb-0">Date</h5>
                    </div>

                    <div className=" col-2 d-flex gap-2 align-items-center px-2">
                      <h5 className="fs-15 mb-0">Project</h5>
                    </div>

                    <div className=" col-4 d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-2">
                      <h5 className="fs-15 mb-0">Today Activity   </h5>
                    </div>

                    <div className=" col-2 d-flex gap-2 align-items-center">
                      <h5 className="fs-15 mb-0">Tasks Completed Today</h5>
                    </div>

                    <div className="col-1    d-flex gap-2 align-items-center justify-content-end">
                      <h5 className="fs-15 mb-0">Action</h5>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1" id="EOD-list">
            {
              loading ?
                <div className='text-center'>
                  <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                    Loading...
                  </Spinner>
                </div>
                :
                (EODList?.data)?.length !== 0 ?
                  (EODList?.data)?.map((item, index) => (
                    <Col lg={12} key={index} className='mt-0' >
                      <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                        <CardBody className="px-2 py-0">
                          <div className="d-lg-flex align-items-center row">
                            <div className="col-8 col-md-2">

                              <a href="#!" onClick={() => { handleViewClick(item); }}>  {item.user?.name} </a>

                            </div>
                            <div className="col-4 col-md-1 d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-md-1">

                              {DefaultDate(item.date, selectedCountry)}

                            </div>

                            <div className={`col-6 col-md-2`}>
                              {item?.project?.name}
                            </div>

                            <div className={`col-12 col-md-4 gap-2 align-items-center col-md-2 ${isDesktop ? isExpanded?.[index] ? "d-block" : "d-flex" : "d-block"}`}>

                              <div dangerouslySetInnerHTML={{ __html: isExpanded?.[index] ? item.today_activity : item?.today_activity?.length > 35 ? item.today_activity.substring(0, 35) + "...." : item?.today_activity }} className='text_html' />
                              {item.today_activity.length > 35 && (
                                <a href="#!" key={item.id} onClick={() => handleToggle(index)} className='text-nowrap'>
                                  {isExpanded?.[index] ? 'Read Less' : 'Read More'}
                                </a>
                              )}

                            </div>
                            <div className={`col-6 col-md-2 d-flex gap-2 align-items-center justify-content-end justify-content-md-start   `}>

                              <div className='task_slide w-100 pe-none'>
                                <Slider
                                  min={1}
                                  max={100}
                                  format={formatPercentage}
                                  value={parseInt(item?.task_completed_today || 1)}
                                  tooltip={false}
                                  disabled={true}
                                />
                              </div> {item?.task_completed_today || 1}%


                            </div>

                            <div className="col-6 col-md-1 d-flex gap-0 align-items-center justify-content-end px-2">
                              {item.end_of_day_report_attachments?.length > 0 ?
                                <button type="button" className="btn  btn-sm btn-icon btn25"
                                  onClick={(e) => { viewAttachmet(item.id) }}>
                                  <i className="ri-attachment-2 fs-18 primary"  ></i>
                                </button> : " "}

                              {userDetails?.roles?.[0]?.name !== "Admin" && item.date === moment().tz(moment.tz.guess()).format('YYYY-MM-DD') && <>
                                <button type="button" className="btn  btn-sm btn-icon btn25"
                                  onClick={(e) => { handleEditClick(item); }}>
                                  <i className="ri-pencil-fill fs-18 primary"  ></i>
                                </button>

                                <button type="button" className="btn btn-sm btn-icon btn25"
                                  onClick={(e) => { onClickData(item); setEODView(item); }}>
                                  <i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                </button>
                              </>
                              }
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))

                  :
                  <div className="noresult" >
                    <div className="text-center">
                      <h6 className="mt-2">Sorry! No Result Found</h6>
                    </div>
                  </div>
            }
          </Row>


          <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getEODReportPage(1, e.target.value);
                }}
                value={rowPerPage}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={EODList?.total}>All</option>
              </select>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={EODList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>
          </div>



          <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                setModalAttachment({
                  ...modalAttachment,
                  show: false,
                  file: [],
                });
              }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => {
                  setModalAttachment({
                    ...modalAttachment,
                    show: false,
                    file: [],
                  });
                }}  >
                  {"View Attachment"}

                </ModalHeader>
                <ModalBody >
                  {modalAttachment?.show ? <>
                    <div className='text-center pb-2' >
                      {imageExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                        <img
                          src={url + modalAttachment?.file?.[currentSlide]?.path}
                          alt={modalAttachment?.file?.[currentSlide]?.name}
                          width={isDesktop ? 500 : "100%"}
                          height={"100%"}

                        />
                        : pdfExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                          <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />
                          : excelExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                            <>  {modalAttachment?.file?.[currentSlide]?.name}</>
                            : <>  {modalAttachment?.file?.[currentSlide]?.name}</>

                      }
                    </div>
                  </>
                    : ""}

                  {modalAttachment?.file?.[0]?.path !== undefined ?
                    <div className='pb-2 text-center' >
                      <button className="btn btn-primary shadow-lg">
                        <a style={{ color: "white" }} href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                      </button>
                    </div>
                    : ""}


                  {modalAttachment?.file?.length > 1 ?
                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                          <FeatherIcon icon="arrow-left" size="20" />
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" onClick={goToNextSlide}>
                          <FeatherIcon icon="arrow-right" size="20" />
                        </Link>
                      </div>
                    </div>
                    : ""}

                </ModalBody>
              </Modal>
            </div>
          </div>


          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalDownload} toggle={() => {
                setModalDownload(!modalDownload)
              }} centered>

                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                  setModalDownload(!modalDownload)
                }}>  {"Confirm"} </ModalHeader>

                <ModalBody >
                  <p> Are you sure you want to download End of Day Report  </p>
                </ModalBody >

                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => { setModalDownload(false); }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => {
                      handleReportDownload();
                      setModalDownload(!modalDownload);
                    }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>



          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`} >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{
                  view ? "View End of Day Report" : !isEdit ? "Add End of Day Report" : "Update End of Day Report"
                }</span>
                <span>
                  {!view && <span className="fs-18  px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>
              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">
                  {view ? <>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Date </Label> <br />
                          {DefaultDate(EODView?.date, selectedCountry)}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Project </Label><br />

                          {EODView?.project?.name}

                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Reporting To</Label><br />

                          {EODView?.reporting_to_user?.name}

                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Task Completed Today </Label> <br />
                          <div className='d-flex align-items-center gap-2'>
                            <div className='task_slide w-100 pe-none'>
                              <Slider
                                min={1}
                                max={100}
                                format={formatPercentage}
                                value={parseInt(EODView?.task_completed_today || 1)}
                                tooltip={false}
                              />
                            </div>
                            {(EODView?.task_completed_today || 1) + "%"}
                          </div>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Today Activity </Label> <br />
                          <div dangerouslySetInnerHTML={{ __html: EODView?.today_activity }} />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Plans for Tomorrow </Label><br />

                          <div dangerouslySetInnerHTML={{ __html: EODView?.plans_for_tomorrow }} />
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Additional Information (If Applicable) </Label> <br />

                          <div dangerouslySetInnerHTML={{ __html: EODView?.additional_information }} />
                        </div>
                      </Col>

                    </Row>
                  </> :

                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="date" className="form-label">Date <span className='secondary'>*</span></Label>
                            <Flatpickr
                              name='date'
                              className={`form-control py-1 px-2 ${submit && validation.errors.date ? 'is-invalid' : ''}`}

                              options={{
                                allowInput: true,
                                dateFormat: SmallDateFormat(selectedCountry),
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                validation.setValues({
                                  ...validation.values,
                                  date: dateStr
                                });
                              }}

                              value={validation.values.date || ""}

                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="project_id" className="form-label">  Project <span className='secondary'>*</span></Label>
                            <Select
                              name="project_id"

                              defaultValue={validation.values.project_id ? projectList?.filter(
                                item => item?.id === validation.values.project_id)?.map((item, index) => {
                                  return (
                                    { value: item.id, label: item.name }
                                  );
                                }) : ""}

                              onChange={(e) => {

                                validation.setValues({
                                  ...validation.values,
                                  project_id: e?.value
                                })
                              }}

                              options={projectList?.map((item) => {
                                return ({ value: item.id, label: item.name });
                              })}
                              styles={selectStyle('project_id')}
                            />

                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="reporting_to" className="form-label">Reporting To  <span className='secondary'>*</span></Label>
                            <Select
                              name="reporting_to"
                              defaultValue={validation.values.reporting_to_user
                                ? projectList
                                  ?.filter(item => item?.id === validation.values.project_id)
                                  ?.map(item => [
                                    { value: item.leader?.id, label: item.leader?.name },
                                    { value: item.project_manager?.id ?? "", label: item.project_manager?.name ?? "" }
                                  ])
                                  ?.flat()
                                  ?.filter(item => item?.value === validation.values.reporting_to_user?.id)
                                  ?.map((item, index) => {
                                    return (
                                      { value: item.value, label: item.label }
                                    );
                                  })
                                : ""}

                              onChange={(e) => {
                                validation.setValues({
                                  ...validation.values,
                                  reporting_to: e?.value
                                })
                              }}

                              options={validation.values.project_id
                                ? projectList
                                  ?.filter(item => item?.id === validation.values.project_id)
                                  ?.map(item => [
                                    // { value: "", label: "Select" }, 
                                    { value: item.leader?.id, label: item.leader?.name },
                                    { value: item.project_manager?.id ?? "", label: item.project_manager?.name ?? "" }
                                  ])
                                  ?.flat()
                                  ?.map(item => ({ value: item.value, label: item.label }))
                                : []}

                              styles={selectStyle('reporting_to')}
                            />
                          </div>
                        </Col>


                        <Col lg={6}>
                          <div className="mb-3 task_slide">
                            <Label htmlFor="task_completed_today" className="form-label">Task Completed Today % <span className='secondary'>*</span></Label>
                            <div className='d-flex align-items-center gap-2'>
                              <div className='task_slide w-100' >
                                <Slider
                                  min={1}
                                  max={100}
                                  format={formatPercentage}
                                  value={parseInt(validation.values.task_completed_today || 1)}
                                  onChange={(value) => {
                                    validation.setValues({
                                      ...validation.values,
                                      task_completed_today: value
                                    });
                                  }}
                                  required={true}
                                />
                              </div>

                              {(validation.values.task_completed_today !== undefined) ? validation.values.task_completed_today + "%" : "1%"}
                            </div>
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="today_activity" className="form-label">Today Activity <span className='secondary'>*</span></Label>

                            <div style={{ border: `1px solid ${submit && validation.errors.today_activity ? 'red' : 'transparent'}`, borderRadius: '5px', marginBottom: '10px' }}>
                              <CKEditor
                                name="today_activity"
                                editor={ClassicEditor}
                                config={editorConfig}
                                data={validation.values.today_activity || ""}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  validation.setValues({
                                    ...validation.values,
                                    today_activity: data ? data : ""
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </Col>


                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="plans_for_tomorrow" className="form-label">Plans for Tomorrow</Label>
                            <CKEditor
                              name="plans_for_tomorrow"
                              editor={ClassicEditor}
                              config={editorConfig}
                              data={validation.values.plans_for_tomorrow || ""}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                validation.setValues({
                                  ...validation.values,
                                  plans_for_tomorrow: data ? data : ""
                                });
                              }}
                            />

                          </div>
                        </Col>


                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="additional_information" className="form-label">Additional Information (If Applicable)   </Label>
                            <CKEditor
                              name="additional_information"
                              editor={ClassicEditor}
                              config={editorConfig}
                              data={validation.values.additional_information || ""}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                validation.setValues({
                                  ...validation.values,
                                  additional_information: data ? data : ""
                                });
                              }}
                            />
                          </div>
                        </Col>

                        <Col lg={12} className='py-2'>
                          <div className="mb-2">
                            <Label htmlFor="attachment" className="form-label">Upload Attachment</Label>
                            <Input type="file" accept=".pdf, image/*" className="form-control px-2 py-1" id="attachment"
                              name='attachment'
                              onChange={handleFileChange}
                              multiple />
                          </div>

                          <Row className='py-2  '>
                            {(files || [])?.map((item, index) => {
                              return [

                                <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                  <FaTimesCircle className="close-button" onClick={(e) => {
                                    setFiles(
                                      files?.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                    );
                                    setFile(
                                      file?.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                    );
                                  }} />
                                  <div className="selected-image cursor" onClick={(e) => {
                                    setModalFile(true);

                                    setFileUrl({ name: item.name, path: item.preview });
                                  }}>
                                    {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                      <img
                                        src={item.preview}
                                        alt={item.name}
                                        width={100}
                                        height={80}
                                      />
                                      : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                        <FaFilePdf size={30} />
                                        : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                          <FaFileExcel size={30} />
                                          : <FaFileAlt size={30} />
                                    }

                                  </div>
                                </Col>

                              ]
                            })}

                            {attachmentList?.map((item, index) => {
                              return [
                                <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                  <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                    handleDelete(item.id);
                                  }} />
                                  <div className="selected-image cursor" onClick={(e) => {
                                    setModalFile(true); setFileUrl({ name: item.path, path: url + item.path });
                                  }}>
                                    {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                      <img
                                        src={url + item.path}
                                        alt={item.name}
                                        width={100}
                                        height={80}
                                      />
                                      : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                        <FaFilePdf size={30} />
                                        : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                          <FaFileExcel size={30} />
                                          : <FaFileAlt size={30} />
                                    }

                                  </div>
                                </Col>
                              ]
                            })}</Row>
                        </Col>

                        <div className="modal fade" tabIndex="-1" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <Modal size="lg" isOpen={modalFile} toggle={() => {
                              setModalFile(false);
                            }} centered>

                              <ModalHeader className='bpBottom pb-2' toggle={() => {
                                setModalFile(false);
                              }}  >
                                {"View Attachment"}

                              </ModalHeader>
                              <ModalBody >
                                {modalFile && <>

                                  <div className='text-center py-4' >

                                    {imageExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                      <img
                                        src={fileUrl.path}
                                        alt={fileUrl.name}
                                        width={isDesktop ? 500 : "100%"}
                                        height={"100%"}
                                      />
                                      : pdfExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                        <iframe src={fileUrl.path} className='iframe' />
                                        : excelExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                          <>  {fileUrl.name}</>
                                          : <>  {fileUrl.name}</>}
                                  </div>
                                </>}

                                <div className='pt-2 text-center' >
                                  <button className="btn btn-primary shadow-lg">
                                    <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                  </button>
                                </div>

                              </ModalBody>
                            </Modal>
                          </div>
                        </div>

                        <Col lg={12}>
                          <div className="hstack gap-2 justify-content-end">
                            <button type="submit" disabled={validation.isSubmitting ? true : false}
                              className="btn btn-primary shadow-lg" onClick={() => {
                                setSubmit(true);
                              }}>{validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  }

                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>



          <Offcanvas isOpen={modalEOD} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between">
                <span className="m-0 me-2 text-white">EOD Report</span>
                <span className='d-flex align-items-center gap-2'>
                  <span className="fs-18 text-color-white cursor" onClick={() => { setModalDownload(!modalDownload); }}>  <FaCloudDownloadAlt style={{ color: "white" }} /> </span>
                  {(EODReportList)?.length !== 0 && <span className="fs-15 text-color-white cursor" onClick={() => { setShowEdit(!showEdit); }}> <FaPen style={{ color: "white" }} /> </span>}
                  <span className="fs-18 text-color-white cursor" onClick={() => { setModalEOD(!modalEOD) }}> <FaTimes style={{ color: "white" }} /> </span>
                </span>
              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">
                  {!showEdit ?
                    (EODReportList)?.length !== 0 ?
                      (EODReportList)?.map((item, index) => (
                        <Col lg={12} key={index} className='mt-0' >
                          <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                            <CardBody className="px-2 py-0">
                              <div className="d-lg-flex align-items-center row">
                                <div className="col-12 col-md-2 mb-2"> <span className='fw-bold'> {item.user?.name}</span>  </div>

                                <div className="col-12 col-md-10">

                                  <p className='d-flex gap-2 align-items-center'> <span className='fw-bold'> Date:</span> {DefaultDate(item.date, selectedCountry)}</p>

                                  <p className='d-flex gap-2 align-items-center'> <span className='fw-bold'>Project: </span> {item?.project?.name}</p>

                                  <p className='d-flex gap-2 align-items-center'> <span className='fw-bold'>Reporting To: </span> {item?.reporting_to_user?.name}</p>

                                  <div className='mb-2'>  <div dangerouslySetInnerHTML={{ __html: "<b style='padding-right:10px'>Today Activity: </b>" + (item?.today_activity || "") }} className='text_html' /></div>

                                  <div className='d-block d-md-flex gap-2 align-items-center mb-2'> <span className='fw-bold'>Task Completed Today:</span>

                                    <span className={`d-flex gap-2 align-items-center ${isDesktop ? "w-50" : "w-100"}`}>  <div className='task_slide w-100 pe-none'>
                                      <Slider min={1} max={100} format={formatPercentage} value={parseInt(item?.task_completed_today || 1)} tooltip={false} disabled={true} />
                                    </div> {item?.task_completed_today || 1}% </span>
                                  </div>

                                  <div className='mb-2'> <div dangerouslySetInnerHTML={{ __html: "<b style='padding-right:10px'> Plans for Tomorrow: </b>" + (item?.plans_for_tomorrow || "") }} className='text_html' /></div>

                                  <div className='mb-2'> <div dangerouslySetInnerHTML={{ __html: "<b style='padding-right:10px'> Additional Information (If Applicable):</b>" + (item?.additional_information || "") }} className='text_html' />
                                  </div>

                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )) : "No Report"

                    : <CKEditor editor={ClassicEditor}
                      config={editorConfig}
                      // disabled={true}
                      data={(EODReportList)?.map((item, index) => (
                        `<div key=${index} class="d-lg-flex align-items-center row"> 
                               <p class='d-flex gap-2 align-items-center'> <b>Name: </b> ${item?.user?.name}</p>
                                  <p class='d-flex gap-2 align-items-center'> <b> Date:</b> ${DefaultDate(item.date, selectedCountry)}</p>
                                  <p class='d-flex gap-2 align-items-center'> <b>Project: </b> ${item?.project?.name}</p>
                                  <p class='d-flex gap-2 align-items-center'> <b>Reporting To: </b> ${item?.reporting_to_user?.name}</p>
                                  <div class='mb-2'> <b style='padding-right:10px'>Today Activity: </b> ${item?.today_activity || ""} </div>
                                  <div class='d-block d-md-flex gap-2 align-items-center mb-2'> <b>Task Completed Today:</b> ${item?.task_completed_today || 1}%  </div>
                                  <div class='mb-2'> <b style='padding-right:10px'> Plans for Tomorrow: </b> ${item?.plans_for_tomorrow || ""} </div>
                                  <div class='mb-2'> <b style='padding-right:10px'> Additional Information (If Applicable):</b>  ${item?.additional_information || ""}  </div>
                                 </div> <br />`
                      )).join('')}
                    />}

                  <div className="hstack gap-2 justify-content-end mt-2">
                    <Button color="light" onClick={() => { setModalEOD(false); }} > Close </Button>
                    <button color="primary" className="btn btn-primary shadow-lg d-flex align-items-center  gap-2 px-3 py-1" onClick={() => { setModalDownload(!modalDownload); }} >
                      <i className="ri-download-cloud-fill fs-16 search-icon"></i> <span className='d-none d-md-flex'>  Download </span> Report </button>
                    {(EODReportList)?.length !== 0 && <button color="primary" className="btn btn-primary  d-flex align-items-center shadow-lg  gap-2 px-3 py-1"
                      onClick={() => { setShowEdit(!showEdit); }} >
                      <i className="ri-pencil-fill fs-16 search-icon"></i> <span className='d-none d-md-flex'> Edit</span>  Report </button>}

                  </div>
                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default EODReport;