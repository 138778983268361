import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner,
  Offcanvas,
  OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, socialLogin, resetLoginFlag } from "../../slices/thunks";
import { createSelector } from 'reselect';
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import SimpleBar from "simplebar-react";
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import { ToastMessage } from "../../Components/Hooks/Function";

const Login = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  const selectLayoutState = (state) => state;
  const loginpageData = createSelector(
    selectLayoutState,
    (state) => ({
      user: state.Account.user,
      error: state.Login.error,
      loading: state.Login.loading,
      errorMsg: state.Login.errorMsg,

    })
  );
  // Inside your component
  const {
    user, error, loading, errorMsg
  } = useSelector(loginpageData);

  const [passwordShow, setPasswordShow] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      remember: false
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      remember: Yup.boolean().notRequired(),
    }),
    onSubmit: (values) => {
      localStorage.removeItem("token");
      localStorage.removeItem("authUser");
      dispatch(loginUser(values, props.router.navigate));
    }
  });


  const forgetValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),

    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await forgetPassword(values);
        setSubmitting(false);
      } catch (error) {

        console.error("An error occurred:", error);
        setSubmitting(false);
      }
    },
  });


  const [settingsList, setSettingsList] = useState([]);


  const forgetPassword = async (values) => {


    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';

    try {

      const response = await api.post('forgetPassword', values);
      if (response.data.status === true) {


        forgetValidation.resetForm();

        setOpen(false);

        ToastMessage("success", response.data?.message);

      } else {
      ToastMessage("error", response.data?.message);
      }


    } catch (err) {
      console.error(err.message);
    }

  };


  const getSettings = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get('settingsDetails', {});

      if (response.data.status === true) {


        setSettingsList(response.data?.data);


        localStorage.setItem("settings", JSON.stringify(response.data?.data));

      } else {

        setSettingsList([]);


        ToastMessage("error", response.data?.message);
      }


    } catch (err) {
      console.error(err.message);
    }

  };

  useEffect(() => {

    getSettings();

    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);


  document.title = "Login";

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <Container className='d-flex align-items-center justify-content-center vh-100'>
        <Row className="d-flex p-0 m-0 login"   >
          <Col xs={6} className="p-0 m-0 d-none d-md-flex" style={{
            backgroundImage: `url(${url + settingsList.filter(type => type?.name === "login_left_image")[0]?.value})`,
            backgroundPosition: "50%",
            backgroundSize: "cover",
          }}>

            {/* <img src={} className='w-100' /> */}

          </Col>

          <Col xs={12} md={6} className="d-flex  login-border"  >

            <div className="d-flex justify-content-center align-items-center px-0 px-md-3 w-100" >
              <div className="row p-2 p-md-5"  >
                <div className="d-flex justify-content-center">
                  <img src={url + settingsList.filter(type => type?.name === "login_logo")[0]?.value} alt="logo" style={{ width: "60%" }} />
                </div>

                <div className="pt-5 pb-2 d-flex justify-content-center">

                  <h3 style={{ color: "#177EF4" }} >Login Account</h3>
                </div>

                <Form onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                  action="#"  >

                  <div className="justify-content-center pt-2">
                    {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                    <div className="col-12">
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Email ID"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={validation.touched.email && validation.errors.email ? true : false}
                        style={{
                          borderLeftWidth: 5,
                          borderRadius: 0,
                          borderLeftColor: validation.touched.email && validation.errors.email ? "#f06548" : "#177EF4",
                          backgroundColor: "#F6F7F7",
                          lineHeight: 2.8
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2 position-relative">
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={validation.touched.password && validation.errors.password ? true : false}
                        style={{
                          borderLeftWidth: 5,
                          borderRadius: 0,
                          borderLeftColor: validation.touched.email && validation.errors.email ? "#f06548" : "#177EF4",
                          backgroundColor: "#F6F7F7",
                          lineHeight: 2.8
                        }}
                      />

                      <button
                        className="btn btn-link position-absolute top-50 end-0 translate-middle-y text-decoration-none text-muted"
                        onClick={() => setPasswordShow(!passwordShow)}
                        type="button"
                        id="password-addon"
                      >
                        <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                      </button>
                    </div>


                  </div>

                  <div className="d-flex justify-content-between pt-2">


                    <div className="  form-check">
                      <Input className="form-check-input" name="remember" type="checkbox" value="" id="auth-remember-check" onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      />
                      <Label className="form-check-label" htmlFor="auth-remember-check">Keep me signed in</Label>
                    </div>

                    <div className="form-check">


                      {/* <Link to="/register"   style={{  color: "#177EF4" }} > Already Member? </Link>   */}
                      <Link style={{ color: "#177EF4" }}
                        onClick={(e) => { toggleDrawer(); }}

                      > Forget Password </Link>

                    </div>

                  </div>

                  <div className="d-flex justify-content-end pt-2">



                  </div>

                  <div className="mt-5 d-flex justify-content-center" >
                    <Button disabled={error ? null : loading ? true : false} className="btn btn-primary shadow-lg fs-18" type="submit" style={{
                      borderRadius: "24px", backgroundColor: "#177EF4",
                      height: "45px", width: "50%"
                    }}>
                      {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                      Login
                    </Button>
                  </div>


                </Form>
              </div>
            </div>

          </Col>
        </Row>
      </Container>

      <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
          <span className="d-flex justify-content-between" >
            <span className="m-0 me-2 text-white">{"Forgot Password"}</span>

            <span className="fs-18  text-color-white px-1"  >
              <FaCheck className='cursor' style={{ color: "white" }} onClick={() => { forgetValidation.handleSubmit(); }} />

              <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
            </span>
          </span>

        </OffcanvasHeader>
        <OffcanvasBody className="p-0">  
          <SimpleBar className="h-100">
            <div className="p-4"> 
              <Form onSubmit={(e) => {
                e.preventDefault();
                forgetValidation.handleSubmit();
                return false;
              }}>
                <Row> 

                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="email" className="form-label">Email</Label>
                      <Input type="text" className="form-control" id="email" name='email'
                        onChange={forgetValidation.handleChange}
                        onBlur={forgetValidation.handleBlur}
                        value={forgetValidation.values.email || ""}
                        placeholder="Enter Email ID"
                        invalid={
                          forgetValidation.touched.email && forgetValidation.errors.email ? true : false
                        }
                      />

                    </div>
                  </Col>




                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      <button type="submit" disabled={forgetValidation.isSubmitting ? true : false}
                        className="btn btn-primary"  >
                        {forgetValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                        {"Save"}</button>
                    </div>
                  </Col>
                </Row>
              </Form>


            </div>
          </SimpleBar>

        </OffcanvasBody>

      </Offcanvas>


    </React.Fragment>
  );
};

export default withRouter(Login);