import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, ModalHeader, Offcanvas, OffcanvasHeader, Spinner, OffcanvasBody, Button,
  Form, Input, Label, Modal, ModalBody, Row
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import Flatpickr from "react-flatpickr";
import Select from 'react-select';
import SimpleBar from "simplebar-react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimesCircle, FaTimes, FaPlus, FaFilePdf, FaFileAlt, FaFileExcel, FaArrowAltCircleLeft } from "react-icons/fa";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import Pagination from "react-js-pagination";
import { FormatDate, FormatMinDate, ToastMessage, DefaultDate, SmallDateFormat, FormatNumber } from "../../Components/Hooks/Function";

const Asset = () => {
  document.title = "Asset User";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [submit, setSubmit] = useState(false);
  const history = useNavigate();
  var moment = require('moment-timezone');
  const [view, setView] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [modalFile, setModalFile] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [assetsView, setAssetsView] = useState(null)
  const [allAssetsList, setAllAssetsList] = useState([]);
  const [assignOpen, setAssignOpen] = useState(false);
  const toggleAssignOpen = () => {
    setAssignOpen(!assignOpen);
  };
  const [isEdit, setIsEdit] = useState(false);
  const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
  const pdfExtensions = new Set(["pdf"]);
  const excelExtensions = new Set(["xls", "xlsx"]);
  const [modalAttachment, setModalAttachment] = useState({
    show: false,
    file: [],
  });
  const options = [
    { value: '', label: 'Select' },
    { value: 'Working', label: 'Working' },
    { value: 'Repair/Service', label: 'Repair/Service' },
    { value: 'Dead', label: 'Dead' }
  ]
  const userDetails = getLoggedinUser()?.user;
  // const [userDetails, setUserDetails] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState("50");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fromValue, setFromValue] = useState(moment().clone().tz(moment.tz.guess()).startOf('month'));
  const [toValue, setToValue] = useState(moment().clone().tz(moment.tz.guess()).endOf('month'));

  const selectAssignStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',

        borderColor: state.isFocused ? 'grey' : submit && assignAssetValidation.errors[fieldName] ? "#f06548" : '#ced4da'

      }),
      option: (provided, state) => ({
        ...provided,

        background: state.isFocused ? '#ced4da' : ' ',
        color: state.isFocused ? '#000' : "",
        backgroundColor: "transparent",
        "&:hover": {
          color: "#000",
          backgroundColor: "#ced4da",
        },
        "&:active": {
          color: "#000",
          // backgroundColor: "#019aff", 
        },
      }),
      groupHeading: (provided) => ({
        fontWeight: 'bold',
        // color: '#019aff',
        padding: '0 6px',
        paddingLeft: '10px',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px', // Change padding on focus

      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    }
  }

  const selectStyles = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",

    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      margin: "0px 0px 5px 0px",
      minHeight: "30px",

    }),
    dropdownButton: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
      height: "30px",
      padding: "5px 8px",
      boxWidth: "none",
      boxShadow: "none",
      color: "rgb(118, 118, 118)",
      border: "1px solid #ced4da",
      "& svg": {
        color: "hsl(0, 0%, 80%)",
        height: "35",
        width: "35"
      }

    }),

    groupHeading: (provided) => ({
      fontWeight: 'bold',
      // color: '#019aff',
      padding: "5px 12px",
      paddingLeft: '6px',
      cursor: "pointer",
      alignItems: 'center',
      display: 'flex',
    }),

    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: "pointer",
      paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
      fontWeight: state.data.label === 'Select All' && 'bold',
      // color: state.data.label === 'Select All' && '#019aff',
      // padding:"5px 12px",
      height: "30px",
      boxShadow: "none",
      display: 'flex',
      alignItems: 'center',
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "unset",
      },
    }),
  };

 

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setFromValue(selectedDates[0]);
      setToValue(selectedDates[1]);
      filterValidation.handleSubmit();
    }
  };

  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const handleFileChange = (e) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize <= maxSizeInBytes) {
      const allowedFiles = files.filter((file) =>
        file.type === 'application/pdf' || file.type.startsWith('image/')
      );

      setFile(allowedFiles);
      setFiles(
        allowedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    } else {
      setFile([]);
      setFiles([]);
      ToastMessage("error", "Uplaoad file is greater than 10 MB");
    }

  }


  const handleAssignAddClick = (arg) => {
    setView(false);
    setSubmit(false);
    getAllAsset();
    setAttachmentList([]);
    setFile([]);
    setFiles([]);
    assignAssetValidation.resetForm();
    assignAssetValidation.setValues({
      asset_date: new Date().toISOString(),
      status: true
    });
    setIsEdit(false);
    toggleAssignOpen();

  };

  const handleEditClick = useCallback((arg) => {
    setView(false);
    getAllAsset();
    setFile([]);
    setFiles([]);
    setAttachmentList([]);
    setSubmit(false);
    viewAssets(arg?.id);
    assignAssetValidation.resetForm();
    setIsEdit(true);
  }, []);

  const handleViewClick = (arg) => {
    setView(true);
    viewAssets(arg?.id);
    assignAssetValidation.resetForm();
    setIsEdit(false);
  };

  const onClickData = () => {
    setDeleteModal(true);
    // viewAssets(arg?.id);
  };

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  };


  const keywordSearch = async (e) => {
    let inputVal = e;
    if (inputVal !== "") {
      const filteredResults = searchList?.data?.data?.filter(item =>
        item.user?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.asset_name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.asset_category?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        FormatNumber(item?.value)?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.asset_id?.toLowerCase()?.includes(inputVal.toLowerCase()) ||
        DefaultDate(item.purchase_date, selectedCountry)?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.warranty?.toLowerCase().includes(inputVal.toLowerCase()) ||
        (item.status === 1 ? "Active" : "Inactive").toLowerCase().includes(inputVal.toLowerCase())
      );

      setAssetsList({
        ...assetsList,
        data: filteredResults
      });

    } else {

      setAssetsList({
        ...assetsList,
        data: searchList?.data?.data
      });

    }
  };

  const viewAttachmet = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('assignAssetAttachment/' + id, {});

      if (response.data.status === true) {
        setModalAttachment({ ...modalAttachment, show: true, file: response.data.data });
      } else {
        setModalAttachment({ ...modalAttachment, show: true, file: [], });
      }

    } catch (err) {
      console.error(err.message);
    }

  }

  const handleDelete = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('assignAssetAttachment/' + id, {});

      if (response.data.status === true) {
        setAttachmentList(attachmentList?.filter(item => item.id !== id));
        setFile(file?.filter(item => item.id !== id));
        setFiles(files?.filter(item => item.id !== id));
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllUser = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getAllUser', {});
      if (response.data.status === true) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  // const formatOptions = (apiData) => {
  //   const groupedOptions = {};
  //   apiData.forEach(option => {
  //     const group = option.parent?.name;
  //     const value = option.id;
  //     const label = option.name;

  //     if (!groupedOptions[group]) {
  //       groupedOptions[group] = [];

  //     }

  //     groupedOptions[group].push({ value, label });
  //   });

  //   const formattedOptions = Object.keys(groupedOptions).map(group => ({
  //     label: group,
  //     options: groupedOptions[group],
  //   }));
  //   return formattedOptions;
  // };

  const [loading, setLoading] = useState(false);
  const getAssets = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`assignAsset`, {
        params: {
          condition: conditionParam,
          user_id: user_idParam,
          startDate: startDateParam,
          endDate: endDateParam,
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setSearchList(response.data);
        setAssetsList(response.data?.data);
      } else {
        setSearchList([]);
        setAssetsList([]);
        ToastMessage("error", response.data?.message);
      }
      setCurrentPage(1);
      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const getAssetsPage = async (currentPage, rowPerPage) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`assignAsset`, {
        params: {
          condition: conditionParam,
          user_id: user_idParam,
          startDate: startDateParam,
          endDate: endDateParam,
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setSearchList(response.data);
        setAssetsList(response.data?.data);
        setAssetsCount(response.data?.count);
      } else {
        setAssetsList([]);
        setSearchList([]);

        ToastMessage("error", response.data?.message);

      }

    } catch (err) {
      console.error(err.message);
    }
  };


  const viewAssets = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('assignAsset/' + id, {});

      if (response.data.status === true) {

        assignAssetValidation.setValues({
          ...response.data?.data,
          asset_date: DefaultDate(response.data?.data?.asset_date, selectedCountry), 
          return_date: DefaultDate(response.data?.data?.return_date, selectedCountry),
          status: response.data?.data?.status === 1 ? true : false
        });

        setAssetsView(response.data?.data);
        setAttachmentList(response.data?.attachment);
      } else {
        setAssetsView([]);
        setAttachmentList([]);

      }

      setAssignOpen(true);

    } catch (err) {
      console.error(err.message);
    }

  };


  const getAllAsset = async () => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`getAllAsset`, {});
      if (response.data.status === true) {
        setAllAssetsList(response.data.data);
      } else {
        setAllAssetsList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };


  useEffect(() => {
    getAssets();
    getAllUser();
    getSettings();
  }, []);


  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
    );
  };

  const addAssignAsset = async (value) => {
    
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (key === "asset_date") {
            formData.append('asset_date', value.asset_date ? FormatDate(value.asset_date, selectedCountry) : "");
          } else if (key === "return_date") {
            formData.append('return_date', value.return_date ? FormatDate(value.return_date, selectedCountry) : "");
          }
          else if (key === "status") {
            formData.append('status', value?.status ? 1 : 0 || 0);
          } else {
            formData.append(key, value[key] || '');
          }
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });
      const response = await api.post('assignAsset', formData);
      if (response.data.status === true) {
        assignAssetValidation.resetForm();
        setIsEdit(false);
        setAssignOpen(false);
        getAssets();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const editAssignAsset = async (value, id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('_method', "PUT");
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (key === "asset_date") {
            formData.append('asset_date', value.asset_date ? FormatDate(value.asset_date, selectedCountry) : "");
          } else if (key === "return_date") {
            formData.append('return_date', value.return_date ? FormatDate(value.return_date, selectedCountry) : "");
          }
          else if (key === "status") {
            formData.append('status', value?.status ? 1 : 0 || 0);
          } else {
            formData.append(key, value[key] || '');
          }
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });
      const response = await api.post('assignAsset/' + id, formData);
      if (response.data.status === true) {
        assignAssetValidation.resetForm();
        setIsEdit(false);
        setAssignOpen(false);
        getAssets();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
    } catch (err) {
      console.error(err.message);
    }

    assignAssetValidation.setSubmitting(false);


  };

  const deleteAssignAsset = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('assignAsset/' + value?.id, {});
      if (response.data.status === true) {
        getAssets();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }
  };

  function getExtension(filename) {
    return filename?.split('.').pop()
  }

  const assignAssetValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      asset_id: '',
      asset_date: '',
      condition: '',
      user_id: '',
      return_date: '',
      note: '',
      status: true,
    },
    validationSchema: Yup.object({
      asset_id: Yup.string().required("Please enter"),
      asset_date: Yup.string().required("Please enter"),
      condition: Yup.string().required("Please enter"),
      user_id: Yup.string().required("Please enter"),
      return_date: Yup.string().notRequired(),
      note: Yup.string().notRequired(),
      status: Yup.string().notRequired(),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isEdit) {
          await editAssignAsset(values, assignAssetValidation.values?.id);
        } else {
          await addAssignAsset(values);
        }
        setSubmitting(false);
      } catch (error) {

        console.error("An error occurred:", error);
        setSubmitting(false);
      }
    },
  });

  const handleUserSelectionChange = (selected) => {
    filterValidation.setValues({
      ...filterValidation.values,
      user_id: selected.length > 0 ? selected : [{
        name: "Select...",
        value: 0
      }]
    });

    filterValidation.handleSubmit();
  };

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      status: '',
      startDate: '',
      endDate: '',
      user_id: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
        return (
          { value: item.id, label: item.name, }
        );
      }),
    },

    validationSchema: Yup.object({
      name: Yup.string().notRequired(),
      status: Yup.string().notRequired(),
      startDate: Yup.date().notRequired(),
      endDate: Yup.date().notRequired(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
      api.defaults.headers.common['Content-Type'] = 'application/json';

      try {
        const response = await api.get(`assignAsset`,
          {
            params: {
              condition: conditionParam,
              user_id: user_idParam,
              startDate: startDateParam,
              endDate: endDateParam,
              page: currentPage,
              rowPerPage: rowPerPage
            }
          });

        if (response.data.status === true) {
          setSearchList(response.data);
          setAssetsList(response.data?.data);
        } else {
          setSearchList([]);
          setAssetsList([])
        }

        setLoading(false);

      } catch (err) {
        console.error(err.message);
      }
    },
  });
 

  const getDefaultDate = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return [firstDayOfMonth, lastDayOfMonth];
  };

  const user_idParam = userDetails?.roles?.[0]?.name === "Admin" ? filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '' : '';
  const conditionParam = filterValidation.values.condition ? `${filterValidation.values.condition}` : '';
  const startDateParam = fromValue ? `${moment(fromValue).format('YYYY-MM-DD')}` : '';
  const endDateParam = toValue ? `${moment(toValue).format('YYYY-MM-DD')}` : '';

  const handleClick = (e) => {
    setCurrentPage(e);
    getAssetsPage(e, rowPerPage);
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        name={assetsView?.name}
        show={deleteModal}
        onDeleteClick={() => deleteAssignAsset(assetsView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Asset User</h4>
              </div>
            </Col>
          </Row>

          <Row  >
            <Col xs={12} md={6} lg={6} >
              <Form onSubmit={(e) => {
                e.preventDefault();
                filterValidation.handleSubmit();
                return false;
              }}>
                <Row  >

                  <Col xs={6} sm={4} md={4} className='py-0' >

                    {/* <Input type="text" className="form-control px-2 py-1" id="name"
                      defaultValue={filterValidation.values.name}
                      placeholder='Employee name'
                      name='name'
                      onChange={(e) => {

                        filterValidation.setValues({
                          ...filterValidation.values,
                          name: e.target.value
                        });

                        filterValidation.handleSubmit()
                      }}
                    /> */}

                    <ReactMultiSelectCheckboxes
                      value={filterValidation.values.user_id?.filter(item => item?.value !== 0)?.map((item, index) => {
                        return (
                          { value: item.value, label: item.label }
                        );
                      })
                      }

                      options={[{
                        label: "Select All",
                        options: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
                          return ({ value: item.id, label: item.name });
                        }),
                      }]}

                      onChange={handleUserSelectionChange}
                      styles={selectStyles}
                    />

                  </Col>

                  <Col xs={6} sm={4} md={4} className='py-0 px-2 px-md-1 ' >

                    <Flatpickr
                      className="form-control py-1 px-2"
                      placeholder="Select Date Range"
                      options={{
                        allowInput: true,
                        mode: 'range',
                        dateFormat: SmallDateFormat(selectedCountry),
                        defaultDate: getDefaultDate(),
                      }}

                      onChange={handleDateChange}
                    />
                  </Col>

                  <Col xs={12} sm={4} md={4} className='py-1 py-md-0  ' >
                    <Select
                      defaultValue={filterValidation.values.condition ? options?.filter(
                        item => item?.value === filterValidation.values.condition)?.map((item, index) => {
                          return (
                            { value: item.value, label: item.label }
                          );
                        }) : ""}


                      onChange={(e) => {
                        filterValidation.setValues({
                          ...filterValidation.values,
                          condition: e?.value
                        });

                        filterValidation.handleSubmit()
                      }}
                      options={options}

                      styles={selectAssignStyle('filter_condition')}
                    />

                  </Col>

                </Row>
              </Form>
            </Col>


            <Col xs={12} md={6} lg={6}   >
              <Row >
                <Col className=" d-flex align-items-center gap-2 justify-content-end">
                  <div className="search-box">
                    <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                  <div className="list-grid-nav hstack gap-1">
                    <Button color="primary" className="d-none d-md-flex btn px-2 py-1" onClick={() => { history('/assets'); }}>   Back      </Button>

                    <span className="fs-18 px-1 d-flex d-md-none" onClick={() => { history('/assets'); }}  >
                      <FaArrowAltCircleLeft className='cursor primary' />
                    </span>

                    <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { handleAssignAddClick(); }} >
                      <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Assign Assets</span></button>
                    <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAssignAddClick(); }} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
            <Col lg={12} className='table px-1  mb-0 '  >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                    <div className="col-7 col-md-3 px-2" >
                      <h5 className="fs-15 mb-0"> User</h5>
                    </div>

                    <div className="col-6  col-md-2 px-1 align-items-center  d-flex justify-content-end  justify-content-md-start px-md-2"  >
                      <h5 className="fs-15 mb-0">Asset</h5>
                    </div>

                    <div className=" col-12 col-md-2 align-items-center d-none d-md-flex">
                      <h5 className="fs-15 mb-0">Date</h5>
                    </div>


                    <div className="  col-12 col-md-2 align-items-center px-1 ">
                      <h5 className="fs-15 mb-0">Condition </h5>
                    </div>

                    <div className=" col-6 col-md-2 align-items-center d-flex   px-0">
                      <h5 className="fs-15 mb-0" >Return Date</h5>
                    </div>

                    {/* <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
 
                      <h5 className="fs-15 mb-0">    Status </h5> 

                    </div>  */}

                    <div className=" col-12 col-md-1 align-items-center d-none d-md-flex justify-content-end px-3">
                      <h5 className="fs-15 mb-0">  Actions</h5>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1" id="assets-list">
            {
              loading ?
                <div className='text-center'>

                  <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                    Loading...
                  </Spinner>
                </div>

                :
                assetsList?.data?.length !== 0 ?

                  (assetsList?.data)?.map((item, index) => (

                    <Col lg={12} key={index} className='mt-0 '  >

                      <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                        <CardBody className="px-2 py-0">

                          <div className="d-lg-flex align-items-center row px-2">

                            <div className="col-4 col-md-3 px-1 px-md-1">

                              <a href="#!" onClick={() => { handleViewClick(item); }}> {item.user?.name}   </a>

                            </div>


                            <div className="col-8 col-md-2 d-flex justify-content-end justify-content-md-start px-1 px-md-2 ">

                              <p className=" mb-0 ">    {item.asset?.asset_name + " (" + item.asset?.asset_id + ")"}  </p>

                            </div>


                            <div className="col-6  col-md-2  d-flex align-items-center   px-1 px-md-2" >

                              <p className={`mb-0 `}> {DefaultDate(item.asset_date, selectedCountry)}</p>

                            </div>

                            <div className="  col-6 col-md-2 align-items-center d-flex justify-content-end justify-content-md-start px-1 px-md-2 ">

                              <p className=" mb-0 ">{item.condition}</p>

                            </div>

                            <div className=" d-flex  col-6 col-md-2 mt-0 align-items-center px-1 px-md-0">

                              <p className="mb-0 ">

                                {item.return_date ? DefaultDate(item.return_date, selectedCountry) : ""}
                              </p>

                            </div>

                            {/* <div className=" col-6 col-md-1  align-items-center d-flex justify-content-end justify-content-md-start px-1">

                              {item.status === 1 ?

                                <Badge color="success-subtle text-success" >Active</Badge>
                                :
                                <Badge color="danger-subtle text-danger"  > Inactive  </Badge>

                              }
                            </div> */}

                            <div className="col-6   col-md-1  align-items-center d-flex justify-content-end px-0 px-md-1"  >

                              {item.assign_asset_attachments?.length > 0 ?
                                <button type="button" className="btn  btn-sm btn-icon btn25"
                                  onClick={(e) => { viewAttachmet(item.id) }}>
                                  <i className="ri-attachment-2 fs-18 primary"  ></i>
                                </button> : " "}

                              <button type="button" className="btn  btn-sm btn-icon btn25"
                                onClick={(e) => { handleEditClick(item); }}>
                                <i className="ri-pencil-fill fs-18 primary"  ></i>
                              </button>

                              <button type="button" className="btn btn-sm btn-icon btn25"
                                onClick={(e) => { onClickData(item); setAssetsView(item); }}>
                                < i className="ri-delete-bin-fill fs-18 secondary" ></i>

                              </button>
                            </div>

                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))

                  :
                  <div className="noresult" >
                    <div className="text-center">

                      <h6 className="mt-2">Sorry! No Result Found</h6>
                      {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                    </div>
                  </div>
            }
          </Row>


          <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getAssetsPage(1, e.target.value);
                }}
                value={rowPerPage}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={assetsList?.total}>All</option>

              </select>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={assetsList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>

          </div>

          <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                setModalAttachment({
                  ...modalAttachment,
                  show: false,
                  file: [],
                });
              }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => {
                  setModalAttachment({
                    ...modalAttachment,
                    show: false,
                    file: [],
                  });
                }}  >
                  {"View Attachment"}

                </ModalHeader>

                <ModalBody >
                  {modalAttachment?.show ? <>

                    <div className='text-center pb-2' >

                      {/* <h5 className='text-secondary'>{modalAttachment?.file?.[currentSlide].name}</h5> <br/> */}

                      {modalAttachment?.file?.[0]?.path !== undefined ?

                        getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase() !== ("pdf" || "doc") ?
                          <img src={url + modalAttachment?.file?.[currentSlide]?.path} className='img' />

                          :
                          <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />


                        : "No Attachment Found"}


                    </div>
                  </>
                    : ""}


                  {modalAttachment?.file?.[0]?.path !== undefined ?

                    <div className='pb-2 text-center' >
                      <button className="btn btn-primary shadow-lg">
                        <a className='text-white' href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                      </button>
                    </div>

                    : ""}


                  {modalAttachment?.file?.length > 1 ?

                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                          <FeatherIcon icon="arrow-left" size="20" />
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" onClick={goToNextSlide}>
                          <FeatherIcon icon="arrow-right" size="20" />
                        </Link>
                      </div>
                    </div>

                    : ""}

                </ModalBody>
              </Modal>
            </div>
          </div>

          <Offcanvas isOpen={assignOpen} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">
                  {!view ? !isEdit ? "Assign Assets" : "Update Assigned Asset" : "View Assigned Asset"} </span>

                <span>
                  {!view ? <span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); assignAssetValidation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleAssignOpen() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>

              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">

                  {view ? <>

                    <Row>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Asset  </Label> <br />
                          {assetsView?.asset?.asset_name + " (" + assetsView?.asset?.asset_id + ")"}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> User </Label> <br />
                          {assetsView?.user?.name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Date  </Label>
                          <br />
                          {DefaultDate(assetsView?.asset_date, selectedCountry)}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Condition </Label>
                          <br />
                          {assetsView?.condition}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Return Date </Label>
                          <br />
                          {assetsView?.return_date ? DefaultDate(assetsView?.return_date, selectedCountry) : ""}
                        </div>

                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Note </Label>
                          <br />
                          {assetsView?.note}
                        </div>

                      </Col>
                    </Row>
                  </>
                    :
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      assignAssetValidation.handleSubmit();
                      return false;
                    }}>
                      <Row>

                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="asset_id" className="form-label "> Asset <span className='secondary'>*</span></Label>

                            <Select
                              placeholder="Select Asset..."
                              name='asset_id'
                              defaultValue={assignAssetValidation.values.asset_id ? allAssetsList?.filter(item => item?.id === assignAssetValidation.values.asset_id)?.map((item, index) => {
                                return ({ value: item.id, label: item.asset_name + " (" + item.asset_id + ")" });
                              }) : ""}

                              onChange={(e) => {
                                assignAssetValidation.setValues({
                                  ...assignAssetValidation.values,
                                  asset_id: e?.value
                                });
                              }}

                              options={
                                [{ value: "", label: "Select" },
                                ...allAssetsList?.filter(item => item?.return_date !== null)?.map((item, index) => {
 
                                  return (
                                    { value: item.id, label: item.asset_name + " (" + item.asset_id + ")" }
                                  );
                                })
                                ]
                              }

                              styles={selectAssignStyle('asset_id')}
                            />

                          </div>

                        </Col>

                        <Col>
                          <div className="mb-2">
                            <Label htmlFor="asset_name" className="form-label"> User <span className='secondary'>*</span> </Label>
                            <Select
                              placeholder="Select Employee..."
                              name='user_id'
                              defaultValue={assignAssetValidation.values.user_id ? userList?.filter(
                                item => item?.id === assignAssetValidation.values.user_id)?.map((item, index) => {
                                  return ({ value: item.id, label: item.name });
                                }) : ""}

                              onChange={(e) => {
                                assignAssetValidation.setValues({
                                  ...assignAssetValidation.values,
                                  user_id: e?.value
                                });
                              }}

                              options={[
                                { value: "", label: "Select" },
                                ...userList?.filter(item => item?.id !== 1)?.map((item, index) => {
                                  return (
                                    { value: item.id, label: item.name }
                                  );
                                })
                              ]
                              }

                              styles={selectAssignStyle('user_id')}
                            />

                          </div>
                        </Col>


                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="roleName" className="form-label"> Date   <span className='secondary'>*</span></Label>
                            <Flatpickr
                              name='asset_date'
                              className={`form-control py-1 px-2 ${submit && assignAssetValidation.errors.asset_date ? 'is-invalid' : ''}`}
                              options={{
                                allowInput: true,
                                dateFormat: SmallDateFormat(selectedCountry),
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                assignAssetValidation.setValues({
                                  ...assignAssetValidation.values,
                                  asset_date: dateStr
                                });
                              }}

                              value={assignAssetValidation.values.asset_date || ""}

                            />

                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="asset_name" className="form-label">Condition <span className='secondary'>*</span> </Label>
                            <Select
                              placeholder="Select..."
                              name='condition'

                              defaultValue={assignAssetValidation.values.condition ? options?.filter(
                                item => item?.value === assignAssetValidation.values.condition)?.map((item, index) => {

                                  return (
                                    { value: item.value, label: item.label }
                                  );
                                }) : ""}


                              onChange={(e) => {

                                assignAssetValidation.setValues({
                                  ...assignAssetValidation.values,
                                  condition: e?.value
                                });
                              }}


                              options={options}

                              styles={selectAssignStyle('condition')}
                            />

                          </div>
                        </Col>


                        {isEdit &&
                          <Col lg={6}>
                            <div className="mb-2">
                              <Label htmlFor="roleName" className="form-label"> Return Date    </Label>
                              <Flatpickr
                                name='return_date'
                                className={`form-control py-1 px-2 ${submit && assignAssetValidation.errors.return_date ? 'is-invalid' : ''}`}
                                options={{
                                  allowInput: true,
                                  minDate: FormatMinDate(assignAssetValidation.values.asset_date, selectedCountry),
                                   dateFormat: SmallDateFormat(selectedCountry),
                                  onReady: function (selectedDates, dateStr, instance) {
                                    instance.set('minDate', FormatMinDate(assignAssetValidation.values.asset_date, selectedCountry));
                                  }
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                  assignAssetValidation.setValues({
                                    ...assignAssetValidation.values,
                                    return_date: dateStr
                                  });
                                }}
                              
                               value={assignAssetValidation.values.return_date || ""}

                              />

                            </div>
                          </Col>
                        }

                        <Col lg={12}>
                          <div className="mb-2">
                            <Label htmlFor="note" className="form-label">Note</Label>
                            <Input type="textarea" className="form-control px-2 py-1" id="note" placeholder="Enter note"
                              name='note'
                              onChange={assignAssetValidation.handleChange}
                              onBlur={assignAssetValidation.handleBlur}
                              value={assignAssetValidation.values.note || ""}
                              invalid={submit && assignAssetValidation.errors.note ? true : false
                              }
                              maxLength={250}
                              style={{ height: "100px", minHeight: '50px', maxHeight: "100px", }}
                              multiline="true"

                            />

                          </div>

                        </Col>

                        <Col lg={12}>
                          <div className="mb-2">
                            <Label htmlFor="attachment" className="form-label">Upload Attachment</Label>
                            <Input type="file" accept=".pdf, image/*" className="form-control px-2 py-1" id="attachment"
                              name='attachment'
                              onChange={handleFileChange}
                              multiple />

                          </div>

                          <Row className='py-2  '>

                            {(files || [])?.map((item, index) => {
                              return [
                                <>
                                  <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                    <FaTimesCircle className="close-button" onClick={(e) => {

                                      setFiles(
                                        files.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                      );
                                      setFile(
                                        file.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                      );
                                    }} />

                                    <div className="selected-image cursor" onClick={(e) => {
                                      setModalFile(true); setFileUrl({ name: item.name, path: item.preview });
                                    }}>
                                      {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                        <img
                                          src={item.preview}
                                          alt={item.name}
                                          width={100}
                                          height={80}
                                        />
                                        : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                          <FaFilePdf size={30} />
                                          : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                            <FaFileExcel size={30} />
                                            : <FaFileAlt size={30} />

                                      }

                                    </div>
                                  </Col>
                                </>
                              ]
                            })}

                            {attachmentList?.map((item, index) => {

                              return [
                                <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                  <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                    handleDelete(item.id);
                                  }} />

                                  <div className="selected-image cursor" onClick={(e) => {
                                    setModalFile(true); setFileUrl({ name: item.path, path: url + item.path });
                                  }}>

                                    {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                      <img

                                        src={url + item.path}
                                        alt={item.name}
                                        width={100}
                                        height={80}
                                      />
                                      : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                        <FaFilePdf size={30} />
                                        : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                          <FaFileExcel size={30} />
                                          : <FaFileAlt size={30} />

                                    }

                                  </div>
                                </Col>
                              ]
                            })}</Row>
                        </Col>

                        {/* <Col xs={6}>

<div className="mb-2">
  <Label htmlFor="roleName" className="form-label">Status</Label>

  <div className="form-check form-switch form-switch-left form-switch-md">



    <Input
      type="switch"
      id="status_id"
      name="status"
      onChange={assignAssetValidation.handleChange}


      onBlur={assignAssetValidation.handleBlur}
      defaultValue={assignAssetValidation.values.status}
      checked={assignAssetValidation.values.status}
    />

    {assignAssetValidation.values.status ? "Active" : "Inactive"}

  </div>

</div>

</Col> */}


                        <div className="modal fade" tabIndex="-1" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <Modal size="lg" isOpen={modalFile} toggle={() => {
                              setModalFile(false);
                            }} centered>

                              <ModalHeader className='bpBottom pb-2' toggle={() => {
                                setModalFile(false);
                              }}  >
                                {"View Attachment"}

                              </ModalHeader>

                              <ModalBody >

                                {modalFile && <>

                                  <div className='text-center py-4' >

                                    {imageExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                      <img

                                        src={fileUrl.path}
                                        alt={fileUrl.name}
                                        width={isDesktop ? 500 : "100%"}
                                        height={"100%"}

                                      />
                                      : pdfExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                        <iframe src={fileUrl.path} className='iframe' />
                                        : excelExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                          <>  {fileUrl.name}</>
                                          : <>  {fileUrl.name}</>

                                    }

                                  </div>
                                </>
                                }


                                <div className='pt-2 text-center' >
                                  <button className="btn btn-primary shadow-lg">
                                    <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                  </button>
                                </div>
                              </ModalBody>
                            </Modal>
                          </div>
                        </div>


                        <Col lg={12}>
                          <div className="hstack gap-2 justify-content-end">
                            <button type="submit" className="btn btn-primary shadow-lg"
                              disabled={assignAssetValidation.isSubmitting ? true : false} onClick={() => {
                                setSubmit(true);

                              }}>
                              {assignAssetValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                              {!view ? !isEdit ? "Assign Assets" : "Update Assigned Assets" : "View Assigned Assets"}</button>
                          </div>
                        </Col>
                      </Row>
                    </Form>

                  }

                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Asset;