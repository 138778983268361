import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, CardBody, Offcanvas, OffcanvasHeader,
    OffcanvasBody, Col, Container, Form, Input, Label, Row,
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, } from 'react-redux';
import SimpleBar from "simplebar-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSelector } from 'reselect';
import { api, url } from "../../helpers/api";
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const Role = () => {
    document.title = "Role";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [submit, setSubmit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [roleView, setRoleView] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleEditClick = useCallback((arg) => {
        setSubmit(false);
        viewRole(arg?.id);
        setIsEdit(true);
    }, []);

    const handleAddClick = () => {
        setSubmit(false);
        validation.resetForm();
        validation.setValues({});
        setIsEdit(false);
        toggleDrawer();
    };

    const onClickData = () => {
        setDeleteModal(true);
    };

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase()));

            setRoleList({
                ...roleList,
                data: filteredResults
            });

        } else {
            setRoleList({
                ...roleList,
                data: searchList?.data
            });
        }
    };

    const getRole = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`role?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setRoleList(response.data?.data);
                setSearchList(response.data?.data);
                setPermissionList(response.data?.permission);
            } else {
                setRoleList([]);
                setSearchList([]);
                setPermissionList(response.data?.permission);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const getRolePage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`role?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setRoleList(response.data?.data);
                setSearchList(response.data?.data);
                setPermissionList(response.data?.permission);
            } else {
                setRoleList([]);
                setSearchList([]);
                setPermissionList(response.data?.permission);
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const viewRole = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('role/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
                setPermissionList(response.data?.permission);
                setSelectedItems(Object.keys(response.data?.rolePermissions));
            } else {
                setPermissionList(response.data?.permission);
                setSelectedItems([]);
            }
            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getRole();
    }, []);

    const addRoles = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('role', value);
            if (response.data.status === true) {
                getRole();
                toggleDrawer();
                validation.resetForm();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const editRoles = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('role/' + value.id, value);
            if (response.data.status === true) {
                getRole();
                validation.resetForm();
                setIsEdit(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            toggleDrawer();
        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteRoles = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('role/' + value?.id, {});
            if (response.data.status === true) {
                getRole();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }
    };


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            permissions: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Role Name"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editRoles({
                    id: validation.values?.id,
                    name: values?.name,
                    permission: selectedItems
                });

            } else {
                addRoles({
                    name: values?.name,
                    permission: selectedItems
                });
            }
        },
    });

    const handleCheckboxChange = (item) => {
        if (selectedItems.includes(item?.id.toString())) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item?.id.toString()));
        } else {
            setSelectedItems([...selectedItems, item?.id.toString()]);
        }
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getRolePage(e, rowPerPage);
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={roleView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteRoles(roleView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Role</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center'  >
                        <Col sm={4}>  </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">

                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                                    <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Role</span></button>
                                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />

                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                        <div className="col-7 col-md-11  px-2">
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>

                                        <div className="mb-1 col-5 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                            <h5 className="fs-15 mb-0">Action</h5>
                                        </div>

                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row className="row gy-1 mb-1" id="role-list">
                        {roleList?.data?.length !== 0 ?
                            (roleList?.data)?.map((item, index) => (
                                <Col lg={12} key={index} className='mt-0 '  >
                                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                        <CardBody className="px-1 py-0">
                                            <div className="d-lg-flex align-items-center row">

                                                <div className="col-7 col-md-11 px-3">

                                                    {item.name}

                                                </div>

                                                <div className="  col-5 col-md-1   d-flex gap-0 align-items-center justify-content-end">

                                                    <button type="button" className="btn  btn-sm btn-icon btn25"
                                                        onClick={(e) => { handleEditClick(item); }}>
                                                        <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                    </button>

                                                    {item.name !== "Admin" && <>  <button type="button" className="btn btn-sm btn-icon btn25"
                                                        onClick={(e) => { onClickData(item); setRoleView(item); }}>
                                                        <i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                                    </button>
                                                    </>
                                                    }

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))
                            :
                            <div className="noresult" >
                                <div className="text-center">
                                    <h6 className="mt-2">Sorry! No Result Found</h6>
                                    {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                </div>
                            </div>
                        }
                    </Row>

                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getRolePage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >

                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={roleList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={roleList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{!isEdit ? "Add Role" : "Update Role"}</span>
                                <span>
                                    <span className="fs-18  px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>

                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="roleName" className="form-label">Name <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="roleName" placeholder="Enter name"
                                                        name='name'
                                                        validate={{ required: { value: true } }}
                                                        disabled={isEdit && validation.values.name === "Admin"}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={submit && validation.errors.name ? true : false}
                                                    />

                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="d-flex align-items-end justify-content-end">
                                                    <Label className="form-label">    <Input className="form-check-input"
                                                        type="checkbox" id="check"
                                                        checked={permissionList?.length === selectedItems?.length}
                                                        onChange={() => {
                                                            if (permissionList?.length === selectedItems?.length) {
                                                                validation.values.name !== "Admin" ? setSelectedItems([]) : "";
                                                            } else {
                                                                setSelectedItems(permissionList?.map((item) => item.id.toString()));
                                                            }
                                                        }}

                                                    /> Select All  </Label>
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <Row className='p-2'>
                                                    {permissionList?.map((item, index) => {
                                                        return [
                                                            <Col xs={6} key={index} className='d-flex gap-2 px-1 py-2'   >
                                                                <Input className="form-check-input"
                                                                    name={item?.name} type="checkbox" id="check"
                                                                    checked={selectedItems.includes(item?.id.toString())}
                                                                    onChange={() => handleCheckboxChange(item)} />
                                                                {item.name}
                                                            </Col>
                                                        ]
                                                    })}
                                                </Row>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary shadow-lg" id="addNewRole" onClick={() => {
                                                        setSubmit(true);
                                                    }}>{"Save"}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Role;