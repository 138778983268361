import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSelector } from 'reselect';
import { api, url } from "../../helpers/api";
import { ToastMessage } from "../../Components/Hooks/Function";

const Login = (props) => {
  const history = useNavigate();
  const selectLayoutState = (state) => state;
  const loginpageData = createSelector(
    selectLayoutState,
    (state) => ({
      user: state.Account.user,
      error: state.Login.error,
      loading: state.Login.loading,
      errorMsg: state.Login.errorMsg,

    })
  );
  // Inside your component
  const {
    user, error, loading, errorMsg
  } = useSelector(loginpageData);

  const [passwordShow, setPasswordShow] = useState(false);
  const [searchParams] = useSearchParams();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirm: '',
    },
    validationSchema: Yup.object({

      password: Yup.string().required("Please Enter Your Password"),
      confirm: Yup.string()
        .required("Please Enter Your Confirm Password")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),

    }),
    onSubmit: (values) => {
      confirmPassword(values);
    }
  });

  const confirmPassword = async (values) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'application/json';

    try {
      const response = await api.post('resetPassword', {
        email: searchParams.get('email'),
        code: searchParams.get('code'),
        password: values.password,
      });

      if (response.data.status === true) {

        validation.resetForm();
        ToastMessage("success", response.data?.message);

      } else {

        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const [settingsList, setSettingsList] = useState([]);

  const getSettings = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get('settingsDetails', {});

      if (response.data.status === true) {

        setSettingsList(response.data?.data);

      } else {

        setSettingsList([]);

        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  useEffect(() => {
    getSettings();
  }, []);

  document.title = "Change Password";

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <Row className="d-flex   p-0 m-0" style={{ height: "100vh" }}>
        <Col xs={6} className="p-0 m-0 d-none d-md-flex">
          <img src={url + settingsList.filter(type => type?.name === "login_left_image")[0]?.value} style={{ width: "100%", height: "100vh" }} />
        </Col>

        <Col xs={12} md={6} className="d-flex  login-border"  >
          <div className="d-flex justify-content-center align-items-center px-0 px-md-5 w-100" >
            <div className="row p25"  >
              <div className="d-flex justify-content-center">
                <img src={url + settingsList.filter(type => type?.name === "login_logo")[0]?.value} alt="logo" style={{ width: "60%" }} />
              </div>

              <div className="pt-5 pb-2 d-flex justify-content-center">
                <h3 style={{ color: "#177EF4" }} >Reset Password</h3>
              </div>

              <Form onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
                action="#"  >
                <div className="  justify-content-center pt-2">
                  {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                  <div className="col-12">
                    <div className="position-relative  mb-3">
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password && validation.errors.password ? true : false
                        }
                        style={{
                          borderLeftWidth: 5,
                          borderRadius: 0,
                          borderLeftColor: validation.touched.email && validation.errors.email ? "#f06548" : "#177EF4",
                          backgroundColor: "#F6F7F7",
                          lineHeight: 2.8
                        }}
                      />

                      <button className="btn btn-link position-absolute top-50 end-0 translate-middle-y text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i></button>
                    </div>
                  </div>

                  <div className="col-12 mt-2 position-relative">
                    <div className="position-relative mb-3">
                      <Input
                        name="confirm"
                        value={validation.values.confirm || ""}
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Enter Confirm Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.confirm && validation.errors.confirm ? true : false
                        }
                        style={{
                          borderLeftWidth: 5,
                          borderRadius: 0,
                          borderLeftColor: validation.touched.email && validation.errors.email ? "#f06548" : "#177EF4",
                          backgroundColor: "#F6F7F7",
                          lineHeight: 2.8
                        }}
                      />

                      <button className="btn btn-link position-absolute top-50 end-0 translate-middle-y text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i></button>
                    </div>
                  </div>
                </div>


                <div className="d-flex justify-content-end pt-2">
                  <div className="form-check">
                    <Link to="/login" style={{ color: "#177EF4" }} > Back to login </Link>
                  </div>
                </div>

                <div className="mt-2 d-flex justify-content-center" >
                  <Button disabled={error ? null : loading ? true : false} className="btn btn-primary shadow-lg fs-18" type="submit" style={{
                    borderRadius: "24px", backgroundColor: "#177EF4",
                    height: "45px", width: "35%"
                  }}>
                    {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                    Reset
                  </Button>
                </div>
              </Form>
            </div>
          </div>

        </Col>
      </Row> 
    </React.Fragment>
  );
};

export default withRouter(Login);