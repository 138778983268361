import React, { useState, useEffect, useCallback } from 'react';
import {
    Card, CardBody, Col, Container, Offcanvas, OffcanvasHeader, OffcanvasBody, Badge,
    Form, Input, Label, FormGroup, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import Select from "react-select";
import classnames from "classnames";
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const BlogTags = () => {
    document.title = "Tags";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [activeTab, setactiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    const [submit, setSubmit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [tagList, setTagList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [message, setMessage] = useState(false);
    const [tagView, setTagView] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [view, setView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAddClick = (arg) => {
        setView(false);
        setSubmit(false);
        validation.setValues({});
        setIsEdit(false);
        toggleDrawer();
    };

    const handleEditClick = useCallback((arg) => {
        setView(false);
        setSubmit(false);
        viewTag(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

    const onClickData = () => {
        setDeleteModal(true);
    };

    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px',

            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }

    const getTag = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`blogTag?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setTagList(response.data?.data);
                setGroupList(response.data?.parent);

            } else {

                setTagList([]);
                setGroupList(response.data?.parent);

                ToastMessage("error", response.data?.message);

            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const getTagPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`blogTag?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setTagList(response.data?.data);
            } else {
                setTagList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };


    const viewTag = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('blogTag/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
            }

            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getTag();
    }, []);

    const addTag = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('blogTag', value);
            if (response.data.status === true) {
                getTag();
                validation.resetForm();
                toggleDrawer();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);


        } catch (err) {
            console.error(err.message);
        }

    };

    const editTag = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('blogTag/' + value.id, value);

            if (response.data.status === true) {
                getTag();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    const deleteTag = async (value) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('blogTag/' + value?.id, {});
            if (response.data.status === true) {
                getTag();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }

    };


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editTag({
                    id: validation.values?.id,
                    name: values?.name,
                    status: values?.status,
                });

            } else {
                addTag({
                    name: values?.name,
                    status: 1
                });
            }
        },
    });


    const handleClick = (e) => {
        setCurrentPage(e);
        getTagPage(e, rowPerPage);
    };




    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />

            <Modal isOpen={deleteModal} toggle={() => { setDeleteModal(false) }} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">

                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#FFBC0A,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>

                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>

                            <p className="text-muted mx-4 mb-0">   Are you sure you want to remove <b>{tagView?.name}</b> record? It will delete all Asset record related to this tag</p>

                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => setDeleteModal(false)}
                        >
                            Close
                        </button>
                        {!message ? <button

                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={() => deleteTag(tagView)}
                        >
                            Yes, Delete It!
                        </button> : ""}
                    </div>
                </ModalBody>
            </Modal>

            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col xs={12}>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Tag Management</h4>

                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center'>
                        <Col sm={4}> </Col>
                        <Col className="col-sm-auto ms-auto d-flex justify-content-end gap-2 py-1">
                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                                    <i className="ri-add-line search-icon"></i> <span className=''> Tag</span></button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2"  >
                        <Col lg={12} className='table px-1  mb-0'  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-7 col-md-3">
                                            <h6 className="fs-14 mb-0">Name</h6>
                                        </div>

                                        <div className="col-5 col-md-8">
                                            <h6 className="fs-14 mb-0">Status</h6>
                                        </div>

                                        <div className="mb-1 col-12 col-md-1  mx-auto d-flex gap-2 align-items-center justify-content-end">
                                            <h6 className="fs-14 mb-0">Action</h6>
                                        </div>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row className="row gy-1 mb-1"  >
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            tagList?.data?.length !== 0 ?
                                (tagList?.data)?.map((item, index) => (

                                    <Col lg={12} key={index} className='mt-0' >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-lg-flex align-items-center row">
                                                    <div className="col-8 col-md-3">
                                                        {item.name}
                                                    </div>

                                                    <div className={`col-10 col-md-8`}>
                                                        <p className="mb-0">   {item.status === 1 ?
                                                            <Badge color="success-subtle text-success" >Active</Badge>
                                                            :
                                                            <Badge color="danger-subtle text-danger" > Inactive  </Badge>
                                                        } </p>

                                                    </div>

                                                    <div className="col-2 col-md-1  mx-auto d-flex gap-0 align-items-center justify-content-end">
                                                        <button type="button" className="btn  btn-sm btn-icon btn25"
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-icon btn25"
                                                            onClick={(e) => {
                                                                onClickData(); setTagView(item);
                                                                setMessage(false);
                                                            }}>
                                                            < i className="ri-delete-bin-fill fs-18 secondary"></i>

                                                        </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))

                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>

                                    </div>
                                </div>

                        }

                    </Row>

                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getTagPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={tagList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={tagList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>

                    </div>



                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">
                                    {!isEdit ? "Add Tag" : "Update Tag"}
                                </span>
                                <span>
                                    {!view ? <span className="fs-18 px-1  text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>

                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            {!isEdit ?
                                <div className="px-4 py-3">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="tagName" className="form-label">Name <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="tagName" placeholder="Enter name"
                                                        name='name'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        // readOnly={isEdit ? false  : true}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            submit && validation.errors.name ? true : false
                                                        }
                                                    />
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary shadow-lg" onClick={() => { setSubmit(true); }}>{"Save"}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>

                                :

                                <SimpleBar className="h-100">
                                    <div className="px-4 py-3">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="tagName" className="form-label">Name <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="tagName" placeholder="Enter name"
                                                            name='name'
                                                            validate={{ required: { value: true }  }}

                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.name || ""}
                                                            invalid={  submit && validation.errors.name ? true : false   }
                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="description" className="form-label">Description</Label>
                                                        <Input type="textarea" className="form-control " id="description" placeholder="Enter description"
                                                            name='description'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.description || ""}
                                                            maxLength={250}
                                                            invalid={   submit && validation.errors.description ? true : false  }
                                                        />

                                                    </div>
                                                </Col>


                                                <Col lg={12}>
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="submit" className="btn btn-primary shadow-lg" id="addNewTag" onClick={() => { setSubmit(true); }}>{"Save"}</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </SimpleBar>
                            }

                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BlogTags;