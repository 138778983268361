import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { SumTimeDifference, DefaultDate } from "../../Components/Hooks/Function";

const AttendanceData = (props) => {
    var moment = require('moment-timezone');
    function timeStringToSeconds(timeString) {
        if (timeString) {
            const [hours, minutes, seconds] = timeString?.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        }
    }

    function secondsToTimeString(seconds) {
        if (seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        }
    } 

    return (
        <React.Fragment>
                <Row>
                    <Col xs={12} md={7}>
                        <Card>
                            <CardBody>
                                <div className=' d-flex align-items-end gap-2'>
                                    <span className='fw-bold'> Timesheet</span>
                                    {moment(props?.attendanceView?.[0]?.start_date).tz(moment.tz.guess()).format('DD MMM YYYY')}

                                </div>

                                <Card className='mt-2'>
                                    <CardBody className='px-2 py-2 bg-light'>

                                        <p className='mb-0'> Punch In at  </p>
                                        <p className='mb-0 text-muted'>
                                            {moment(props?.attendanceView?.[0]?.start_date).tz(moment.tz.guess()).format('ddd, Do MMM YYYY') + " " + props?.attendanceView?.[0]?.start_time}
                                        </p>

                                    </CardBody>
                                </Card>


                                <Card className='mt-2 text-center d-flex justify-content-center'  >
                                    <CardBody className='px-0 py-2 '>

                                        <p className='mb-0'>

                                            <span className='mb-0  fs-24'>
                                                {timeStringToSeconds(SumTimeDifference(props?.attendanceView.filter(item => item?.is_break === 0))) >
                                                    timeStringToSeconds(SumTimeDifference(props?.attendanceView.filter(item => item?.is_break === 1)) || "00:00:00") ?
                                                    (secondsToTimeString(timeStringToSeconds(SumTimeDifference(props?.attendanceView.filter(item => item?.is_break === 0))) -
                                                        timeStringToSeconds(SumTimeDifference(props?.attendanceView.filter(item => item?.is_break === 1)) || "00:00:00")))
                                                    : "00:00:00"

                                                }

                                                {/* {"diff"}
                                                                    {hoursList?.totalWorkHours} */}
                                            </span>

                                            <span> {" hrs"}</span>
                                        </p>

                                    </CardBody>
                                </Card>

                                <Card className='mt-2'>
                                    <CardBody className='px-2 py-2 bg-light'>

                                        <p className='mb-0'> Punch Out at  </p>
                                        <p className='mb-0 text-muted'>
                                            {props?.attendanceView?.[props?.attendanceView?.length - 1]?.end_time ? moment(props?.attendanceView?.[props?.attendanceView?.length - 1]?.end_date).tz(moment.tz.guess()).format('ddd, Do MMM YYYY') + " " + props?.attendanceView?.[props?.attendanceView?.length - 1]?.end_time : "-"}
                                        </p>

                                    </CardBody>
                                </Card>

                                <Row>
                                    <Col> <Card className='mt-2'>
                                        <CardBody className='px-2 py-2 bg-light'>

                                            <p className='mb-0'> Break</p>
                                            <p className='mb-0 text-muted'>
                                                {SumTimeDifference(props?.attendanceView.filter(item => item?.is_break === 1))}
                                                {" hrs"}</p>

                                        </CardBody>
                                    </Card> </Col>

                                    <Col> <Card className='mt-2'>
                                        <CardBody className='px-2 py-2 bg-light'>

                                            <p className='mb-0'> Overtime</p>
                                            <p className='mb-0 text-muted'>

                                                {timeStringToSeconds(SumTimeDifference(props?.attendanceView.filter(item => item?.is_break === 0))) > 28800 ?
                                                    secondsToTimeString(timeStringToSeconds(SumTimeDifference(props?.attendanceView.filter(item => item?.is_break === 0)))
                                                        - timeStringToSeconds(props?.hoursList?.totalShiftHours))
                                                    : 0} {" hrs"}

                                                {/* {"diff"} */}

                                                {/* {hoursList?.overTime + " hrs"} */}

                                            </p>

                                        </CardBody>
                                    </Card> </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>

                    <Col xs={12} md={5} >

                        <Card>
                            <CardBody>

                                <Row className="mb-4">
                                    <Col lg={12}>
                                        <div>

                                            <div className="timeline-2">
                                                <div className="timeline-year">
                                                    <h5 className='mb-0'> Activity </h5>
                                                </div>
                                                <div className="timeline-continue">
                                                    {props?.attendanceView?.map((item, index) => {
                                                        return (
                                                            <span key={index}>
                                                                <Row className="timeline-right">
                                                                    <Col xs={12}>
                                                                        <p className="timeline-date">

                                                                            <span className='d-flex justify-content-between'>
                                                                                <span> Punch In at 
                                                                                    {index === 0 ? moment(item?.start_time, 'HH:mm:ss').isAfter(moment(moment(props?.shift?.start_time).tz(moment.tz.guess()).format('HH:mm:ss'), 'HH:mm:ss')) ? " (Late)" : "" : ""}
                                                                                </span>
                                                                                {!item?.is_break && props?.handleEditClick && <button type="button" className="btn  btn-sm btn-icon btn25"
                                                                                    onClick={(e) => { props.handleEditClick(item); }}>
                                                                                    <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                                                </button>}

                                                                            </span>


                                                                            {DefaultDate(item?.start_date, props?.selectedCountry) + " " + item.start_time}  <span className='fs-10'> {item?.is_break ? " (Break Time)" : ""}</span>
                                                                        </p>

                                                                    </Col>

                                                                </Row>

                                                                <Row className="timeline-right">
                                                                    <Col xs={12}>
                                                                        <p className="timeline-punchOut">
                                                                            Punch Out at  <br />
                                                                            {item?.end_time ? DefaultDate(item?.end_date, props?.selectedCountry) + " " + item?.end_time : ""}  <span className='fs-10'>{item?.is_break ? " (Break Time)" : ""} </span>
                                                                            <span> {!item.end_time && <button onClick={(e) => {
                                                                                props.editWorking(item?.id, item?.user_id, item?.start_date);
                                                                            }}
                                                                                className="btn btn-danger mb-0 text-white w-bold px-1 py-1 my-1" >  Punch Out </button>} </span>
                                                                        </p>
                                                                    </Col>

                                                                </Row>
                                                            </span>


                                                        );
                                                    })}


                                                </div>


                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
        </React.Fragment>
    );
}

export default AttendanceData;