import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, Offcanvas, Card, CardBody, Spinner,
  OffcanvasHeader, ModalFooter,
  OffcanvasBody, Col, Container, Dropdown, ModalHeader, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getSettingsDetails } from "../../helpers/api_helper";
import { ToastMessage, FormatDate, SmallDateFormat, DefaultDate } from "../../Components/Hooks/Function";

const Holiday = () => {
  document.title = "Holiday";
  const history = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [searchList, setSearchList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [holidayView, setHolidayView] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [settingsList, setSettingsList] = useState(getSettingsDetails("settings"));
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [modalDownload, setModalDownload] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [sendMail, setSendMail] = useState(false);

  const handleEditClick = useCallback((arg) => {
    setSubmit(false);
    viewHoliday(arg?.id);
    setIsEdit(true);
  }, []);

  const handleAddClick = (arg) => {
    setSubmit(false);
    validation.resetForm();
    validation.setValues({
      date: new Date().toISOString()
    })
    setIsEdit(false);
    toggleDrawer();
    setSendMail(true);
  };

  const onClickData = (team) => {
    setDeleteModal(true);
  };

  const selectStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: '30px',
      minHeight: '30px',
      padding: '0px',
      minWidth: "max-content",

    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? ' ' : ' ',
    }),
    groupHeading: (provided) => ({
      fontWeight: 'bold',
      color: '#019aff',
      paddingLeft: '10px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: state.isFocused ? '4px' : '0px',

    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginTop: '-4px',
    }),
  }


  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    setSettingsList(getSettingsDetails("settings"));
  };

  useEffect(() => {
    getSettings();
  }, []);

 
  const keywordSearch = async (e) => {
    let inputVal = e;
    if (inputVal !== "") {
      const filteredResults = searchList?.filter(item =>
        item.title?.toLowerCase().includes(inputVal.toLowerCase()) ||
        DefaultDate(item.date, selectedCountry).toLowerCase().includes(inputVal.toLowerCase())
      );

      setHolidayList(filteredResults);

    } else {
      setHolidayList(searchList);
    }
  };

  const [loading, setLoading] = useState(false);
  const getHoliday = async (year) => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`holiday?year=${year}`, {});
      if (response.data.status === true) {
        setHolidayList(response.data?.data);
        setSearchList(response.data?.data);
      } else {
        setHolidayList([]);
        setSearchList([]);
      }
      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  //   const getHolidayPage = async (year) => {
  //     api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  //     api.defaults.headers.common['Content-Type']= 'application/json'
  //     try {  
  //      const response = await api.get(`holiday?year=${year}`, {    });  
  //       if (response.data.status === true) { 
  //        setHolidayList(response.data?.data);
  //       }  else {
  //         setHolidayList([]); 
  //         toast(response.data?.message, 
  //            { position: "top-right", hideProgressBar: true,
  //            className:   'secondary',  
  //             progress: undefined, toastId: "" })
  //     }
  //    } catch (err) {
  //       console.error(err.message);
  //    }
  // };

  const viewHoliday = async (id, name) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('holiday/' + id, {});

      if (response.data.status === true) {

        validation.setValues({
          ...response.data?.data,
          date: DefaultDate(response.data?.data?.date, selectedCountry),
        });

        setHolidayView(response.data?.data);

        setOpen(true);

      } else {

        setHolidayView([]);

      }

    } catch (err) {
      console.error(err.message);
    }

  };


  useEffect(() => {
    getHoliday(year);
  }, [year]);

  const getAllReport = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';

    try {
      const response = await api.get(`holidayAllReport`, {
        params: {
          year: year,
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          selectedCountry: selectedCountry,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
        }, responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Holiday Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (err) {
      console.error(err.message);
    }
  };

  const addHolidays = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post('holiday', value);

      if (response.data.status === true) {
        getHoliday(year);
        setOpen(false);
        validation.resetForm();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const editHolidays = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.put('holiday/' + value.id, value);

      if (response.data.status === true) {
        getHoliday(year);

        setIsEdit(false);
        validation.resetForm();
        setOpen(false);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteHolidays = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('holiday/' + value?.id, {});

      if (response.data.status === true) {
        getHoliday(year);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }
  };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      date: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title"),
      date: Yup.string().required("Please enter date"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (isEdit) {
        editHolidays({
          ...values,
          date: FormatDate(values.date, selectedCountry) || ""
        }, validation.values?.id);

      } else {

        setSubmitting(true);
        if (sendMail && getSettingsDetails("settings").filter(type => type?.name === "holiday_mail")[0]?.value === "ON") {
          setModalConfirm(true);
          setSubmitting(false);
        } else {
          addHolidays({
            ...values,
            date: FormatDate(values.date, selectedCountry) || ""
          });
        } 
      }
    },
  });

  const startYear = 2024;
  const endYear = new Date().getFullYear();
  const yearList = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
 

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => deleteHolidays(holidayView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Holiday</h4>
              </div>
            </Col>
          </Row>

          <Row className='d-flex align-items-center'  >
            <Col md={4} >
              <div className="list-grid-nav hstack gap-2">
                <div className="search-box">
                  <Input type="text" className="form-control px-2 py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <div>
                  <Select
                    name='year'
                    defaultValue={{ value: year, label: year }}
                    onChange={(e) => {
                      setYear(e?.value);
                      // getHoliday(year);
                    }}

                    options={yearList.map((year) => ({ value: year, label: year }))}
                    styles={selectStyle}
                  />
                </div>

                <div className="d-flex gap-1 align-items-center">
                  <i className="ri-calendar-2-line  d-flex d-md-none" style={{ fontSize: "20px", color: "#019aff" }} onClick={() => { history("/holidays"); }}></i>
                  <i className="ri-download-cloud-fill d-flex d-md-none" style={{ fontSize: "20px", color: "#019aff" }}  onClick={() => { setModalDownload(!modalDownload) }}></i>
                  <FaPlus className="text-primary d-flex d-md-none" size={20} onClick={() => { handleAddClick(); }} />
                </div>
              </div>
            </Col>

            <Col className="col-sm-auto ms-auto d-flex gap-2 py-1 py-0" >
              <div className="list-grid-nav hstack gap-1 d-none d-md-flex" >
                <button color="primary" className="btn btn-primary shadow-lg  d-flex gap-2 px-3 py-1" onClick={() => { history("/holidays"); }} >
                  <i className="ri-calendar-2-line search-icon"></i>    Calendar  </button>

                <button color="primary" className="btn btn-primary shadow-lg d-flex gap-2 px-3 py-1" onClick={() => { setModalDownload(!modalDownload) }} >
                  <i className="ri-download-cloud-fill search-icon"></i>  Download Report  </button>

                <button color="primary" className="btn btn-primary shadow-lg d-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                  <i className="ri-add-line search-icon"></i>   Holiday </button>
              </div>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1 mt-1 px-2">
            <Col lg={12} className='table px-1 mb-0'>
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0 rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center px-0 py-2 d-none d-md-none d-lg-block">
                    <div className="col-7 px-2">
                      <h5 className="fs-15 mb-0"> Occasion</h5>
                    </div>
                    <div className=" col-4 mx-auto d-flex gap-2 align-items-center justify-content-end justify-content-md-start">
                      <h5 className="fs-15 mb-0">Date</h5>
                    </div>
                    <div className="col-1  mx-auto d-flex gap-2 align-items-center justify-content-end">
                      <h5 className="fs-15 mb-0">Action</h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>



          <Row className="row gy-1 mb-1" id="holiday-list">
            {loading ?
              <div className='text-center'>
                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}> Loading...  </Spinner>
              </div>
              :
              holidayList?.length !== 0 ?

                (holidayList)?.map((item, index) => (
                  <Col lg={12} key={index} className='mt-0'>
                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                      <CardBody className="px-2 py-0">
                        <div className="d-lg-flex align-items-center row">
                          <div className="col-6 col-md-7"> {item.title} </div>

                          <div className="col-6  col-md-4 d-flex justify-content-end justify-content-md-start">
                            {DefaultDate(item.date, selectedCountry)}
                          </div>

                          <div className="col-12 col-md-1  mx-auto d-flex gap-0 align-items-center justify-content-end">
                            <button type="button" className="btn  btn-sm btn-icon btn25"
                              onClick={(e) => { handleEditClick(item); }}>
                              <i className="ri-pencil-fill fs-18 primary"></i>
                            </button>

                            <button type="button" className="btn btn-sm btn-icon btn25"
                              onClick={(e) => { onClickData(item); setHolidayView(item); }}>
                              <i className="ri-delete-bin-fill fs-18 secondary"></i>
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
                :
                <div className="noresult" >
                  <div className="text-center">
                    <h6 className="mt-2">Sorry! No Result Found</h6>
                  </div>
                </div>
            }
          </Row>

          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="md" backdrop={"static"} isOpen={modalDownload} toggle={() => { setModalDownload(!modalDownload) }} centered>
                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => { setModalDownload(!modalDownload) }}> {"Confirm"} </ModalHeader>
                <ModalBody >  <p> Are you sure you want to download Holiday Report  </p>   </ModalBody >
                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => { setModalDownload(false); }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => { getAllReport(); setModalDownload(!modalDownload); }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>


          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => { setModalConfirm(!modalConfirm) }} centered>
                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => { setModalConfirm(!modalConfirm) }}> {"Confirm"} </ModalHeader>
                <ModalBody >  <p> Are you sure you want to send to all?  </p>   </ModalBody >
                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => { setModalConfirm(false); }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => { setSendMail(false); validation.handleSubmit(); setModalConfirm(!modalConfirm); }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>


          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{!isEdit ? "Add Holiday" : "Update Holiday"}</span>
                <span>
                  <span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>
                  <span className="fs-18 text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>
              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">

                  <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false;   }}>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="title" className="form-label">Occasion <span className='secondary'>*</span></Label>
                          <Input type="text" className="form-control px-2 py-1" id="title" placeholder="Enter occasion"
                            name='title'
                            validate={{  required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={ submit && validation.errors.title ? true : false }
                          />

                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="date" className="form-label">Date <span className='secondary'>*</span></Label>
                          <Flatpickr
                            name='date'
                            className={`form-control py-1 px-2 ${submit && validation.errors.date ? 'is-invalid' : ''}`}
                            options={{
                              allowInput: true,
                              dateFormat: SmallDateFormat(selectedCountry),
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                              validation.setValues({
                                ...validation.values,
                                date: dateStr
                              });
                            }}

                            value={validation.values.date || ""}
                          />
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="submit" disabled={validation.isSubmitting ? true : false}
                            className="btn btn-primary shadow-lg" id="addNewHoliday" onClick={() => { setSubmit(true); }}>
                            {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Holiday;