

import React, { useState, useEffect, useCallback } from 'react';
import {
    Col, Form, Input, Label, FormGroup, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback,
    Nav, NavItem, NavLink,
} from 'reactstrap';
 
const EmployeeDesignationFunction = (props) => {
  
    return (   
        <Form onSubmit={(e) => {
            e.preventDefault();

            props.validation.handleSubmit();
            return false;
        }}>
            <Row> 

                {  props.renderNestedOptions(props.departmentAllList, null)}


                {<Col lg={6}>
                    <div className="mb-3">

                        <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                            name='name'


                            onChange={props.validation.handleChange}
                            onBlur={props.validation.handleBlur}
                            value={props.validation.values.name || ""}
                            invalid={
                                props.submit && props.validation.errors.name ? true : false
                            }
                        />

                    </div>
                </Col>}

                <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                        <button type="submit" className="btn btn-primary shadow-lg"
                            onClick={() => {
                                props.setSubmit(true);
                            }}>{"Save"}</button>



                    </div>
                </Col>
            </Row>
        </Form >

    );

};

export default EmployeeDesignationFunction;