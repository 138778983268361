import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import { changeSidebarVisibility } from '../slices/thunks';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { useEffect } from 'react';
import { api, url } from "../helpers/api";
import { getLoggedinUser, getSettingsDetails, getAddressFromCoordinates } from "../helpers/api_helper";
import { toast } from 'react-toastify';
import { ToastMessage } from "../Components/Hooks/Function";

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const dispatch = useDispatch();
  const selectDashboardData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype
  );
  const sidebarVisibilitytype = useSelector(selectDashboardData);
  const [settingsList, setSettingsList] = useState([]);
  const [todayWork, setTodayWork] = useState();
  const [totalWork, setTotalWork] = useState([]);
  const [loading, setLoading] = useState(false);
  var moment = require('moment-timezone');
  const userDetails = getLoggedinUser()?.user;
  const [location, setLocation] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const history = useNavigate();
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);

          var data = getAddressFromCoordinates(latitude, longitude);
          setLocation({
            address: data?.display_name,
            location: data?.address?.suburb
          });

        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
    }

  };

  const getDeviceType = () => {
    const isMobile = window.innerWidth <= 768;
    setDeviceType(isMobile ? "Mobile" : "PC");
  };

  useEffect(() => {
    getDeviceType();
  }, [])

  const checkEOD = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getLastEOD', {});

      if (response.data.status === true) {

        setLoading(false);

        if (response.data.data === false) {

          addWorking();
          setTimer(0);

        } else {
          ToastMessage("error", response.data?.message);
        }

      } else {
        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const addWorking = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post('workingHours', {
        user_id: userDetails.id,
        title: "Today Work",
        start_date: moment(new Date()).tz(moment.tz.guess()).format('YYYY-MM-DD'),
        start_time: moment(new Date()).tz(moment.tz.guess()).format('HH:mm:ss'),
        start_time_latitude: latitude,
        start_time_longitude: longitude,
        start_time_device_type: deviceType,
        location: location?.location,
        address: location?.address,
        status: "Approved",
      });

      if (response.data.status === true) {
        getTodayWorkingHours();
        window.dispatchEvent(new Event("workingHourChange"));
      }
      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const editWorking = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.put('workingHours/' + todayWork?.id, {
        end_date: moment(new Date()).tz(moment.tz.guess()).format('YYYY-MM-DD'),
        end_time: moment(new Date()).tz(moment.tz.guess()).format('HH:mm:ss'),
        end_time_latitude: latitude,
        end_time_longitude: longitude,
        end_time_device_type: deviceType,
        location: location?.location,
        address: location?.address,
      });

      if (response.data.status === true) {

        window.dispatchEvent(new Event("workingHourChange"));
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getTodayWorkingHours = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post(`getLastWorking`, {
        user_id: userDetails?.id
      });

      if (response.data.status === true) {
        setTodayWork(response.data?.data);
      } else {
        setTodayWork([]);
      }
      setTotalWork(response.data?.totalhour)
      setLoading(false);

    } catch (err) {
      console.error(err.message);

      if (err.response?.status === 401) {
        history('/logout');
      }
    }
  };

  useEffect(() => {
    if (userDetails?.roles?.[0]?.name !== "Admin") {
      getUserLocation();
    }

    getTodayWorkingHours();

    const workingHourChange = () => {
      getTodayWorkingHours();
    };
    window.addEventListener("workingHourChange", workingHourChange);


  }, []);

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    dispatch(changeSidebarVisibility("show"));
    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle('open');
    if (document.documentElement.getAttribute('data-layout') === "horizontal") {
      document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
    }
    if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }
    if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
      document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
    }
  };

  const getSettings = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('settingsDetails', {});
      if (response.data.status === true) {
        setSettingsList(response.data?.data);
        localStorage.setItem("settings", JSON.stringify(response.data?.data));
         let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = url + response.data?.data?.filter(type => type?.name === "icon")[0]?.value;
      } else {
        setSettingsList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };


  useEffect(() => {
    const changeLogo = () => {
      getSettings();
    };

   
    
    setSettingsList(getSettingsDetails("settings"));
    window.addEventListener("changeLogo", changeLogo);
    return () => {
      window.removeEventListener("changeLogo", changeLogo);
    };
  }, []);

  function convertSecondsToTimeString(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }

  function convertToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let intervalId;
    if (todayWork?.id) {
      setTimer(
        totalWork?.reduce((accumulator, work) => {
          const totalTimeInSeconds = (new Date(work?.end_time ? new Date().toDateString() + ' ' + work?.end_time : new Date()) - new Date(new Date().toDateString() + ' ' + work?.start_time)) / 1000;
          const hours = Math.floor(totalTimeInSeconds / 3600);
          const minutes = Math.floor((totalTimeInSeconds % 3600) / 60);
          const seconds = Math.floor(totalTimeInSeconds % 60);
          const timeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
          return accumulator + convertToSeconds(timeString);
        }, 0)
      );

      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [todayWork]);

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">

              <div className="navbar-brand-box horizontal-logo">
                {/* <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    {settingsList.filter(type => type?.name === "icon")[0]?.value !==undefined &&
                      <img src={url + settingsList.filter(type => type?.name === "icon")[0]?.value} alt="" height="22" />
                    }
                  </span>
                  <span className="logo-lg">
                    
                    {settingsList.filter(type => type?.name === "logo-dark")[0]?.value !==undefined &&
                      <img className="headLogo" src={url + settingsList.filter(type => type?.name === "logo-dark")[0]?.value} alt="" />
                    }
                  </span>
                </Link> */}

                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    {settingsList.filter(type => type?.name === "icon")[0]?.value !== undefined &&
                      <img src={url + settingsList.filter(type => type?.name === "icon")[0]?.value} alt="" height="22" />
                    }
                  </span>
                  <span className="logo-lg">
                    {settingsList.filter(type => type?.name === "logo-light")[0]?.value !== undefined &&
                      <img className="headLogo" src={url + settingsList.filter(type => type?.name === "logo-light")[0]?.value} alt="" />
                    }
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon">
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <Link to="/" className="logo d-block d-md-none d-lg-none">

                {/* {url + settingsList.filter(type => type?.name === "icon")[0]?.value &&
                  <img src={url + settingsList.filter(type => type?.name === "icon")[0]?.value} alt="" height="40" />
                } */}

              </Link>
              {/* <SearchOption /> */}
            </div>

            <div className="d-flex align-items-center">

              {/* <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..."
                                                    aria-label="Recipient's username" />
                                                <button className="btn btn-primary shadow-lg" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown> */}

              {/* LanguageDropdown */}
              {/* <LanguageDropdown /> */}
              {/* WebAppsDropdown */}
              {/* <WebAppsDropdown /> */}
              {/* MyCartDropdwon */}
              {/* <MyCartDropdown /> */}
              {/* FullScreenDropdown */}
              {/* Dark/Light Mode set */}


              {!loading ? userDetails?.roles?.[0]?.name !== "Admin" &&
                <div className="d-flex gap-2 align-items-center ">
                  {totalWork.length > 0 && (totalWork.length > 0 && (
                    convertSecondsToTimeString(
                      totalWork?.filter(item => item?.is_break === 0)?.reduce((accumulator, work) => {
                        const totalTimeInSeconds =
                          (new Date(work?.end_date ? work?.end_date + ' ' + work?.end_time : new Date()) -
                            new Date(work?.start_date + ' ' + work?.start_time)) / 1000;
                        return accumulator + totalTimeInSeconds;
                      }, 0)
                    )))}

                  {todayWork?.id ?
                    <button onClick={(e) => {
                      editWorking();
                      clearInterval(timer);
                    }} className="btn btn-danger mb-0 text-white w-bold"> Punch Out  </button>
                    :
                    <button onClick={(e) => {
                      checkEOD();
                    }} className="btn btn-primary shadow-lg mb-0 text-white w-bold">Punch In</button>
                  }
                </div> :
                <div className='text-center'>
                  <Spinner color="primary" className="m-5" style={{ width: 20, height: 20 }}>
                    Loading...
                  </Spinner>
                </div>
              }

              <FullScreenDropdown />
              {/* <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              /> */}
              {/* NotificationDropdown */}
              {/* <NotificationDropdown /> */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;