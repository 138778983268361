import React, { useState, useEffect, useCallback } from 'react';
import {  Button, Card, CardBody, Spinner, ModalFooter,
  Col, Container, ModalHeader, Form, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import { TimeDifference, SumTimeDifference, ToastMessage, DefaultDate } from "../../Components/Hooks/Function";
import { FaArrowAltCircleLeft } from "react-icons/fa";

const WorkingHours = (props) => {
  document.title = "Working Hours";
  var moment = require('moment-timezone');
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [submit, setSubmit] = useState(false);
  const [userList, setUserList] = useState([]);
  const [workingList, setWorkingList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [status, setStatus] = useState({
    id: "",
    status: "",
    reason: ""
  });
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [allConfirm, setAllConfirm] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const selectStyle = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: '20px',
      minHeight: '20px',
      padding: '0px',
      borderColor: state.isFocused ? 'grey' : '#ced4da'
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? ' ' : ' ',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '20px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '20px',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: state.isFocused ? '4px' : '0px',

    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginTop: '-4px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      marginTop: '-1px',
    }),
  }

   
  const [statusList, setStatusList] = useState([
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Reject", label: "Reject" },
  ]);

  const [employeeTypeList, setEmployeeTypeList] = useState([]);

  const filterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: '',
      endDate: '',
      user_id: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
        return (
          { value: item.id, label: item.name, }
        );
      }),
      employee_type: employeeTypeList,
    },
    validationSchema: Yup.object({
      startDate: Yup.date(),
      endDate: Yup.date(),
      user_id: Yup.array(),
      employee_type: Yup.array(),
    }),
    onSubmit: async (values) => {
      getWorkingHour();
    },
  });

  const statusValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object({
      reason: status.status !== "Reject" ? Yup.string().notRequired() :
        status.status === "Reject" && status.reason ? Yup.string().notRequired()
          : Yup.string().required("Enter"),
    }),
    onSubmit: async (values) => {
      { allConfirm ? changeAllstatus() : changestatus() }
    },
  });

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    setSettingsList(getSettingsDetails("settings"));
  };


  useEffect(() => {
    getSettings();
  }, []);

  const getAllUser = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getAllUser', {});

      if (response.data.status === true) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };


  const changestatus = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post(`workinghourStatusUpdate`, {
        id: status.id,
        user_id: [searchParams.get('user_id')],
        status: status.status,
        reason: status.reason,
      }
      );

      if (response.data.status === true) {
        getWorkingHour();
        ToastMessage("success", response.data?.message);

      } else {
        ToastMessage("error", response.data?.message);       
      }

      setModalConfirm(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const changeAllstatus = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.post(`workinghourStatusAllUpdate`, {
        user_id: [searchParams.get('user_id')],
        status: status.status,
        reason: status.reason,
      }
      );

      if (response.data.status === true) {
        getWorkingHour();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

      setModalConfirm(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const [loading, setLoading] = useState(false);
  const getWorkingHour = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`getWorkinghour`,
        {
          params: {
            user_id: [searchParams.get('user_id')],
            startDate: searchParams.get('startDate'),
            endDate: searchParams.get('endDate'),
          }
        }
      );

      if (response.data.status === true) {
        setWorkingList(response.data?.data);
      } else {
        setWorkingList([]);
      }
      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }
  };

  const [isExpanded, setIsExpanded] = useState([]);

  const handleToggle = (index) => {
    const newExpandedStates = [...isExpanded];
    newExpandedStates[index] = !newExpandedStates[index];
    setIsExpanded(newExpandedStates);
  };

  useEffect(() => {
    setIsExpanded(workingList?.map(() => false))
  }, [workingList]);

  const getAllWorking = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';

    try {
      const response = await api.get(`getWorkinghour`,
        {
          params: {
            user_id: [searchParams.get('user_id')],
            startDate: searchParams.get('startDate'),
            endDate: searchParams.get('endDate'),
            perPage: workingList?.total,
          }
        }
      );

      if (response.data.status === true) {
        getAllReport(response.data?.data);
      } else {
        getAllReport([]);
      }
      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }

  };


  const getAllReport = async (data) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';

    try {
      const response = await api.get(`workinghourReport`, {
        params: {
          user_id: [searchParams.get('user_id')],
          startDate: searchParams.get('startDate'),
          endDate: searchParams.get('endDate'),
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          selectedCountry: selectedCountry,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
          report: (data)?.map(item => ({
            date: DefaultDate(item.start_date, selectedCountry) + " " + item.start_time + " - " + DefaultDate(item.end_date, selectedCountry) + " " + item.end_time,
            total_hours: TimeDifference(item),
            reason: item.reason,
            status: item.status,
          })),

          total: SumTimeDifference(workingList),
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Working Hour Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (err) {
      console.error(err.message);
    }
  };

  const getEmployeeTypeList = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('employeeType', {});
      if (response.data.status === true) {
        setEmployeeTypeList(response.data?.data);
      } else {
        setEmployeeTypeList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getWorkingHour();
    getAllUser();
    getEmployeeTypeList();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0'>
              <div className="mb-0 page-title-box borderColor d-sm-flex align-items-center justify-content-between py-1">
                <h4 className="mb-0 primary fw-bold px-2 px-md-0" >Employee Work Hours</h4>
                <div className='d-flex gap-2 py-2 px-2 px-md-0 py-md-0 justify-content-between d-none d-md-flex'>
                  <Button color="primary" className="btn px-2 py-1" onClick={() => { history('/workinglist'); }}>     Back     </Button>

                  <button color="primary" className="btn btn-primary shadow-lg gap-2 px-3 py-1" onClick={() => { setModalDownload(!modalDownload) }} >
                    <i className="ri-download-cloud-fill search-icon"></i>  Download Report  </button>

                </div>
              </div>
            </Col>
          </Row>


          <Row className='d-flex align-items-center  border-bottom' >
            <Col xs={12} md={6} className='d-flex justify-content-between'>
              <p className="mb-0">   <span className='fw-bold'> {searchParams.get('username')} </span>
                ({DefaultDate(searchParams.get('startDate'), selectedCountry) + " - " + DefaultDate(searchParams.get('endDate'),  selectedCountry)})</p>


            </Col>

            <Col xs={4} md={6} className='d-flex justify-content-start justify-content-md-end py-2' >

              <Form onSubmit={(e) => {
                e.preventDefault();
                filterValidation.handleSubmit();
                return false;
              }}>
                <Select
                  onChange={(e) => {
                    setSubmit(false);
                    setModalConfirm(true);
                    setAllConfirm(true);
                    setStatus({
                      id: "",
                      status: e?.value
                    });
                  }}

                  options={statusList?.map((item) => {
                    return (
                      { value: item.value, label: item.label }
                    );
                  })}

                  styles={selectStyle}
                />

              </Form>

            </Col>

            <Col xs={8} className='d-flex d-md-none justify-content-end align-items-center gap-2 py-1 py-md-2' >

              <b>  Total:   {SumTimeDifference(workingList)} </b>

              <span className='d-flex gap-1 d-md-none'>
                <span className="fs-18 px-1" onClick={() => { history('/workinglist'); }}  >
                  <FaArrowAltCircleLeft className='cursor primary' />
                </span>

                <i className="ri-download-cloud-fill " style={{ fontSize: "20px", color: "#019aff" }} onClick={() => { setModalDownload(!modalDownload) }}></i>
              </span>
            </Col>
          </Row>



          <Row className="row  gy-1 mb-1  mt-1  px-2 ">
            <Col lg={12} className='table px-1  mb-0' >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                    <div className="col-4  px-2">
                      <h5 className="fs-15 mb-0"> Date</h5>
                    </div>

                    <div className=" col-2 d-flex gap-2 align-items-center px-2">
                      <h5 className="fs-15 mb-0">Total Hours </h5>
                    </div>

                    <div className=" col-4  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-2">
                      <h5 className="fs-15 mb-0">Reason </h5>
                    </div>

                    <div className=" col-2  mx-auto d-flex gap-2 align-items-center justify-content-end ">
                      <h5 className="fs-15 mb-0">Status </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row className="row gy-1 mb-1" id="working-list">
            {
              loading ?
                <div className='text-center'>
                  <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                    Loading...
                  </Spinner>
                </div>
                :
                workingList?.length !== 0 ?
                  <>
                    {(workingList || [])?.map((item, index) => (

                      <Col lg={12} key={index} className='mt-0'  >

                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                          <CardBody className="px-2 py-0">

                            <div className="d-lg-flex align-items-center row">

                              <div className="col-12 col-md-4 order-1 order-md-1">

                                {DefaultDate(item.start_date,  selectedCountry) + " " + item.start_time?.slice(0, 5)
                                  + " - " + DefaultDate((item.end_date || ""), selectedCountry) + " "
                                  + (item.end_time ? item.end_time?.slice(0, 5) : moment().format('HH:mm:ss')?.slice(0, 5))}

                              </div>

                              <div className="col-6 col-md-2 order-2 order-md-2 d-flex gap-2 align-items-center ">
                                {TimeDifference(item)}
                              </div>
 
                              <div className={`col-12 col-md-4 order-4 order-md-3 mx-auto ${isExpanded?.[index] ? "d-block" : "d-flex"}  gap-2 align-items-center justify-content-start px-3 px-md-2`}>
                                {item.status === "Reject" ?
                                  <>
                                    {isExpanded?.[index] ? item.reason : item.reason?.length > (isMobile ? 25 : 35) ? item.reason?.slice(0, (isMobile ? 25 : 35)) + "..." : item.reason}
                                    {item?.reason?.length > (isMobile ? 25 : 35) && (
                                      <a href="#!" key={item.id} onClick={() => handleToggle(index)}>
                                        {isExpanded?.[index] ? ' Read Less' : ' Read More'}
                                      </a>
                                    )}</>

                                  : ""}
                              </div>


                              <div className="col-6 col-md-2 order-3 order-md-4 mx-auto gap-2 align-items-center justify-content-end mb-1">
                                <Select
                                  value={item.status ? statusList?.filter(
                                    items => items?.value === item.status)?.map((items, index) => {
                                      return (
                                        { value: items.value, label: items.label }
                                      );
                                    }) : ""}


                                  onChange={(e) => {
                                    setSubmit(false);
                                    setModalConfirm(true);
                                    setAllConfirm(false);
                                    setStatus({
                                      id: item.id,
                                      status: e?.value,
                                      reason: item.reason
                                    });

                                  }}

                                  options={statusList?.map((item) => {
                                    return (
                                      { value: item.value, label: item.label }
                                    );
                                  })}

                                  styles={selectStyle}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}

                    <Col lg={12} className='d-none d-md-block mt-0'   >
                      <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 ">
                        <CardBody className="px-2 py-1">
                          <div className="d-lg-flex align-items-center row">
                            <div className="col-6  col-md-4"> </div>
                            <div className="col-6 col-md-2 d-flex gap-2 align-items-center ">
                              <b>  Total:  {SumTimeDifference(workingList)} </b>
                            </div>

                            <div className=" col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-start">
                            </div>

                            <div className=" col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end mb-1">
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </>

                  :
                  <div className="noresult" >
                    <div className="text-center">
                      <h6 className="mt-2">Sorry! No Result Found</h6>
                    </div>
                  </div>
            }
          </Row>

          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                setModalConfirm(!modalConfirm)
              }} centered>

                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => { setModalConfirm(!modalConfirm) }}  >  {"Confirm"}  </ModalHeader>

                <Form onSubmit={(e) => {
                  e.preventDefault();
                  statusValidation.handleSubmit();
                  return false;
                }}>

                  <ModalBody >
                    <p> Are you sure you want to change status?</p>

                    {status.status === "Reject" && <div className="mb-2">
                      <Label htmlFor="reason" className="form-label">Reason <span className='secondary'>*</span></Label>
                      <Input type="text" className="form-control px-2 py-1" id="reason" placeholder="Enter reason"
                        name='reason'
                        onChange={(e) => {
                          setStatus({
                            ...status,
                            reason: e.target.value
                          });
                        }}
                        invalid={
                          submit && statusValidation.errors.reason ? true : false
                        }
                        value={status.reason || ""}
                      />

                    </div>}
                  </ModalBody >
                  <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                      <Button color="light" onClick={() => { setModalConfirm(false); }} > No </Button>
                      <Button type="submit" color="success" onClick={(e) => { setSubmit(true); }} > Yes  </Button>
                    </div>
                  </ModalFooter>
                </Form>
              </Modal>
            </div>
          </div>

          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalDownload} toggle={() => {
                setModalDownload(!modalDownload)
              }} centered>

                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                  setModalDownload(!modalDownload)
                }}>  {"Confirm"} </ModalHeader>

                <ModalBody> <p> Are you sure you want to download Employee Work Hours Report  </p>  </ModalBody >

                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => { setModalDownload(false); }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => { getAllWorking(); setModalDownload(!modalDownload); }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default WorkingHours;