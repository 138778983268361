import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import Flatpickr from "react-flatpickr";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {
  Card, Offcanvas, OffcanvasHeader, OffcanvasBody, CardBody, Container, Form, Input,
  Label, Modal, ModalBody, Row, Col, Button, Spinner
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import listPlugin from '@fullcalendar/list';
import DeleteModal from "../../Components/Common/DeleteModal";
import SimpleBar from "simplebar-react";
import { api, url } from "../../helpers/api";
import { FaCheck, FaTimes, FaTrash, FaArrowAltCircleLeft, FaEdit } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import Select from "react-select";
import { getLoggedinUser, getSettingsDetails, getAddressFromCoordinates } from "../../helpers/api_helper";
import UpcomingWorkingHours from './UpcomingWorkingHours';
import { useNavigate, useSearchParams } from "react-router-dom";
import AttendanceData from "../../Components/Common/AttendanceData";
import { ToastMessage, DefaultDate, SumTimeDifference, FormatDate, FormatMinDate, SmallDateFormat } from "../../Components/Hooks/Function";

const Calender = () => {
  const history = useNavigate();
  var moment = require('moment-timezone');
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [submit, setSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [modalStartTime, setModalStartTime] = useState(false);
  const [modalEndTime, setModalEndTime] = useState(false);
  const [workingList, setWorkingList] = useState([]);
  const [hoursList, setHoursList] = useState([]);
  const [shift, setShift] = useState([]);
  const [workingAllList, setWorkingAllList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [userList, setUserList] = useState([]);
  const userDetails = getLoggedinUser()?.user;
  // const [userDetails, setUserDetails] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().toLocaleString('en-US', { month: '2-digit' }));
  const [attendanceView, setAttendanceView] = useState([]);

  const selectStyles = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      margin: "0px 0px 5px 0px",
      minHeight: "30px",
    }),
    dropdownButton: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
      height: "30px",
      padding: "5px 8px",
      boxWidth: "none",
      boxShadow: "none",
      color: "rgb(118, 118, 118)",
      border: "1px solid #ced4da",
      "& svg": {
        color: "hsl(0, 0%, 80%)",
        height: "35",
        width: "35"
      }
    }),

    groupHeading: (provided) => ({
      fontWeight: 'bold',
      // color: '#019aff',
      padding: "5px 12px",
      paddingLeft: '6px',
      cursor: "pointer",
      alignItems: 'center',
      display: 'flex',
    }),

    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: "pointer",
      paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
      fontWeight: state.data.label === 'Select All' && 'bold',
      // color: state.data.label === 'Select All' && '#019aff',
      // padding:"5px 12px",
      height: "30px",
      boxShadow: "none",
      display: 'flex',
      alignItems: 'center',
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "unset",
      },
    }),
  };

  const handleAddClick = () => {
    setSubmit(false);
    setStartTime(dayjs('2024-01-01T10:00'));
    setEndTime(dayjs('2024-01-01T18:00'));
    validation.resetForm();
    validation.setValues({
      start_date: new Date().toISOString()
    })
    setIsEdit(false);
    setView(false);
    toggleDrawer();
  };

  const handleEditClick = (item) => {
    validation.resetForm();
    setSubmit(false);
    setView(false);
    viewWorkingHours(item?.id);
    setIsEdit(true);
  };


  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? ' ' : ' ',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px', // Change padding on focus
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    };
  };

  const [view, setView] = useState(false);
  const [workingHoursView, setWorkingHoursView] = useState([]);
  const [workingHoursDetail, setWorkingHoursDetail] = useState([]);

  const viewWorkingHours = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('workingHours/' + id, {});
      if (response.data.status === true) {
        setWorkingHoursView(response.data.data);
        validation.setValues({
          ...response.data?.data,
          start_date: DefaultDate(response.data?.data?.start_date, selectedCountry),
          end_date: DefaultDate(response.data?.data?.end_date, selectedCountry),
        });
      } else {
        setWorkingHoursView([]);
      }
      setOpen(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  const viewAttendance = async (id, date) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('attendance/' + date + '/' + id, {});
      if (response.data.status === true) {
        setAttendanceView(response.data?.data);
        setShift(response.data?.shift);
        setHoursList(response.data?.hoursList);
        setOpen(true);
      } else {
        ToastMessage("error", response.data?.message);

        setAttendanceView([]);
        setHoursList([]);
        setShift([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  }

  const handleEventClick = (arg) => {
    setIsEdit(false);
    setView(true);
    setWorkingHoursDetail(arg?.event);
    viewAttendance(arg?.event?._def?.extendedProps?.user?.id, arg?.event?.startStr);
    setOpen(true);
  };

  const handleViewClick = (arg) => {
    setIsEdit(false);
    setView(true);
    viewWorkingHours(arg?.id);
  };

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  };

  const getAllUser = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getAllUser', {});

      if (response.data.status === true) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getSettings();
    getAllUser();
  }, []);

  const calendarRef = React.createRef();

  const handlePrevButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      const currentDate = calendarRef.current.getApi().getDate();
      const prevYear = currentDate.getFullYear().toString();
      const prevMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      getWorkingHours(prevYear, prevMonth);
    }
  };

  const handleNextButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      const currentDate = calendarRef.current.getApi().getDate();
      const prevYear = currentDate.getFullYear().toString();
      const prevMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      getWorkingHours(prevYear, prevMonth);
    }
  };


  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().today();
      const currentDate = calendarRef.current.getApi().getDate();
      const prevYear = currentDate.getFullYear().toString();
      const prevMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      getWorkingHours(prevYear, prevMonth);
    }
  };

  const filterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
        return (
          { value: item.id, label: item.name, }
        );
      }),

    },
    validationSchema: Yup.object({
      user_id: Yup.array(),
    }),
    onSubmit: async (values) => {
      api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
      api.defaults.headers.common['Content-Type'] = 'application/json'
      try {
        const response = await api.get(`workingHours`,
          {
            params: {
              year: year,
              month: month,
              user_id: filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '',
            }
          }
        );

        if (response.data.status === true) {

          setWorkingList(response.data?.data);
          setWorkingAllList(response.data?.allData);
        } else {
          setWorkingList([]);
          setWorkingAllList([]);
        }
      } catch (err) {
        console.error(err.message);
      }
    },
  });


  const user_idParam = userDetails?.roles?.[0]?.name === "Admin" ? filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '' : '';

  const getWorkingHours = async (year, month) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`workingHours`, {
        params: {
          user_id: user_idParam,
          year: year,
          month: month
        }
      });

      if (response.data.status === true) {
        setWorkingList(response.data?.data);
        setWorkingAllList(response.data?.allData);
      } else {

        setWorkingList([]);
        setWorkingAllList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getWorkingHours(year, month);
  }, []);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [location, setLocation] = useState({});

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          var data = getAddressFromCoordinates(latitude, longitude);
          setLocation({
            address: data?.display_name,
            location: data?.address?.suburb
          });
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
    }

  };


  // const getAddressFromCoordinates = (latitude, longitude) => {
  //   const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

  //   fetch(apiUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.display_name) {
  //         const address = data.display_name;
  //         setLocation({
  //           address: address,
  //           location: data.address?.suburb
  //         });

  //       } else {
  //         console.error("No address found for the given coordinates");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching address:", error);
  //     });
  // };

  const isMobile = window.innerWidth <= 768;
  const getDeviceType = () => {
    setDeviceType(isMobile ? "Mobile" : "PC");
  };

  useEffect(() => {
    getDeviceType();
    getUserLocation();
  }, [])

  const addWorking = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post('workingHours', {
        ...value,
        start_date: value.start_date ? FormatDate(value.start_date, selectedCountry) : "",
        start_time_latitude: latitude,
        start_time_longitude: longitude,
        start_time_device_type: deviceType,
        end_date: value.end_date ? FormatDate(value.end_date, selectedCountry) : "",
        end_time_latitude: value.end_time ? latitude : "",
        end_time_longitude: value.end_time ? longitude : "",
        end_time_device_type: value.end_time ? deviceType : "",
        location: location.location,
        address: location.address,
        status: userDetails?.roles?.[0]?.name === "Admin" ? "Approved" : "Pending",
      });

      if (response.data.status === true) {
        validation.resetForm();
        setOpen(false);
        getWorkingHours(new Date().getFullYear(), new Date().toLocaleString('en-US', { month: '2-digit' }));
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const editWorkings = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.put('workingHours/' + value.id, {
        ...value,
        start_date: value.start_date ? FormatDate(value.start_date, selectedCountry) : "",
        end_date: value.end_date ? FormatDate(value.end_date, selectedCountry) : "",
        location: location.location,
        address: location.address,
        end_time_latitude: value.end_time ? latitude : "",
        end_time_longitude: value.end_time ? longitude : "",
        end_time_device_type: value.end_time ? deviceType : "",
        is_admin: userDetails?.roles?.[0]?.name === "Admin" && value.end_time ? 1 : 0,
      });

      if (response.data.status === true) {
        getWorkingHours(new Date().getFullYear(), new Date().toLocaleString('en-US', { month: '2-digit' }));
        setIsEdit(false);
        setOpen(false);
        validation.resetForm();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }

  };



  const editWorking = async (id, user_id, date) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.put('workingHours/' + id, {
        end_date: moment(new Date()).tz(moment.tz.guess()).format('YYYY-MM-DD'),
        end_time: moment(new Date()).tz(moment.tz.guess()).format('HH:mm:ss'),
        end_time_latitude: latitude,
        end_time_longitude: longitude,
        end_time_device_type: deviceType,
        location: location?.location,
        address: location?.address,
        is_admin: 1,
      });

      if (response.data.status === true) {
        viewAttendance(user_id, date);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteWorkingHours = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('workingHours/' + value?.id, {});

      if (response.data.status === true) {
        getWorkingHours(new Date().getFullYear(), new Date().toLocaleString('en-US', { month: '2-digit' }));
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)
      setOpen(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      description: "",
      user_id: "",
    },

    validationSchema: Yup.object({
      user_id: userDetails?.roles?.[0]?.name === "Admin" ? Yup.string().required("Please Enter") : Yup.string().notRequired(),
      title: Yup.string().required("Please Enter"),
      start_date: Yup.string().required("Please Enter"),
      start_time: Yup.string().required("Please Enter"),
      end_date: Yup.string().notRequired(),


      end_time: Yup.string().when('end_date', {
        is: (end_date) => true,
        then: (schema) => schema.required("Enter"),
        otherwise: (schema) => schema,
      }),

      description: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {

      if (isEdit) {
        editWorkings(values, validation.values?.id);
      } else {
        addWorking(values);
      }
    },
  });


  const today = new Date();

  const filteredTodayWorkingList = Array.isArray(workingList)
    ? workingList.filter(event => {
      return event.start_date == moment(today).tz(moment.tz.guess()).format('YYYY-MM-DD');
    })
    : [];

  const [startTime, setStartTime] = React.useState(dayjs('2024-01-01T10:00'));
  const [endTime, setEndTime] = React.useState(dayjs('2024-01-01T18:00'));

  const [focus, setFocus] = React.useState("");


  const handleStartTimeChange = (newTime) => {
    setStartTime(newTime);

    validation.setValues({
      ...validation.values,
      start_time: newTime.format('HH:mm:ss')
    });

    if(focus === "seconds"){
      setModalStartTime(false);
    } 
  };

  const handleEndTimeChange = (newTime) => {
    setEndTime(newTime);

    validation.setValues({
      ...validation.values,
      end_time: newTime.format('HH:mm:ss')
    });

    if(focus === "seconds"){
      setModalEndTime(false);
    }
   };

  function renderEventContent(eventInfo) {
    return (
      <div className="fc-event-main-frame">
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {/* {eventInfo?.event?._def?.title} <br />   */}
            <span className="fs-12 fw-normal">  {eventInfo?.event?._def?.extendedProps?.hours} </span>  <br />
            <span className="fs-12 fw-normal">   {eventInfo?.event?._def?.extendedProps?.admin ? eventInfo?.event?._def?.extendedProps?.user?.name : ""}  </span>
          </div></div></div>
    )
  }


  document.title = "Employee Working Hours";
  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        name={workingHoursView?.name}
        show={deleteModal}
        onDeleteClick={() => deleteWorkingHours(workingHoursView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Employee Working Hours</h4>
              </div>
            </Col>
          </Row>


          <Row className="pb-2" >
            <Col xs={12} md={8} >

            </Col>
            <Col xs={6} md={2} className="d-flex py-1 align-items-center justify-content-start justify-content-md-end pe-0"  >
              <Button color="primary" className="btn btn-primary px-2 py-1 d-flex gap-2 align-items-center " onClick={() => { history('/workinglist'); }}>
                < i className="ri-file-list-fill d-none d-md-flex"></i> Work Hours Status</Button>

              {/* <Button color="primary" className="btn btn-primary px-2 py-0 d-flex gap-2 align-items-center " onClick={() => { 
                  
                  UpdateWorkingDate();
                  
                  }}>
                < i className="ri-file-list-fill d-none d-md-flex"></i> Update</Button> */}

            </Col>

            <Col xs={6} md={2} className='justify-content-end py-1'   >

              <Form onSubmit={(e) => {
                e.preventDefault();
                filterValidation.handleSubmit();
                return false;
              }}>
                <ReactMultiSelectCheckboxes

                  value={filterValidation.values.user_id?.filter(item => item?.value !== 0)?.map((item, index) => {

                    return (
                      { value: item.value, label: item.label }
                    );
                  })
                  }

                  options={[{
                    label: "Select All",
                    options: userList?.filter(item => item?.id !== 1)?.map((item, index) => {

                      return (
                        { value: item.id, label: item.name, }
                      );
                    }),
                  }]}


                  onChange={(e) => {

                    filterValidation.setValues({
                      ...filterValidation.values,
                      user_id: e.length > 0 ? e : [{
                        name: "Select...",
                        value: 0
                      }]
                    });

                    filterValidation.handleSubmit();

                  }}

                  styles={selectStyles}
                />

              </Form>
            </Col>

          </Row>

          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <button
                    className="btn btn-primary shadow-lg w-100"
                    id="btn-new-event"
                    onClick={handleAddClick}
                  >
                    <i className="mdi mdi-plus"></i> Work Hours
                  </button>

                  <Card className="card " >
                    <CardBody className="p-2">
                      <SimpleBar
                        className="pe-2 me-n1 mb-3"
                        style={{ height: "100vh" }}
                      >
                        <div id="upcoming-event-list">
                          {(filteredTodayWorkingList || []) &&
                            (filteredTodayWorkingList || [])?.map((event, index) => {
                              return (
                                <UpcomingWorkingHours key={index}
                                  index={index + 1}
                                  event={event}
                                  DefaultDate={DefaultDate}
                                  selectedCountry={selectedCountry}
                                  admin={userDetails?.roles?.[0]?.name === "Admin" ? true : false}
                                  workingAllList={workingAllList}
                                />
                              );

                            })}
                        </div>
                      </SimpleBar>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={9}>
                  <Card className="card-h-100">
                    <CardBody className="calender" >
                      <FullCalendar
                        initialView={isMobile ? "listWeek" : "dayGridMonth"}
                        ref={calendarRef}
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin
                        ]}

                        // slotDuration={"00:15:00"}
                        //   handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "prev,next today",
                          // right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}

                        customButtons={{
                          prev: {
                            text: 'prev',
                            click: handlePrevButtonClick,
                          },
                          next: {
                            text: 'next',
                            click: handleNextButtonClick,
                          },

                          today: {
                            text: 'today',
                            click: handleTodayButtonClick,
                          },

                        }}


                        eventContent={renderEventContent}

                        events={Array.isArray(workingList) ? workingList.map((item, index) => {
                          return ({
                            // "id": item.id,
                            // "title": item.title,
                            "start": item.start_date,
                            "end": item.end_date,
                            "className": "bg-danger-subtle ",
                            "allDay": true,
                            // "description": item.description,
                            // "location": "",
                            "user": item.user,
                            "date": item.start_date,
                            // "start_time": item.start_time,
                            // "end_time": item.end_time,
                            "hours": SumTimeDifference((workingAllList || []).filter(row => (row?.user_id === item?.user_id && row?.start_date === item?.start_date))) + " hrs",
                            "admin": userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                          }
                          );
                        }) : []}
                        editable={false}
                        droppable={false}
                        selectable={false}
                        // dateClick={handleDateClick}
                        eventClick={handleEventClick}
                      // drop={onDrop}
                      />

                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div style={{ clear: "both" }}></div>
              <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}    >
                <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                  <span className="d-flex justify-content-between" >
                    <span className="m-0 me-2 text-white">{view ?
                      `${workingHoursDetail?._def?.extendedProps?.user?.name} Work Hours` : !isEdit ? "Add Work Hours" : "Update Work Hours"}</span>
                    <span>

                      {isEdit &&
                        <span className="fs-18  text-color-white px-1"
                          onClick={() => { setIsEdit(false); setView(true) }}
                        >
                          <FaArrowAltCircleLeft className='cursor' style={{ color: "white" }} />
                        </span>
                      }

                      {!view && <>
                        <span className="fs-18  text-color-white px-1" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>
                          <FaCheck className='cursor' style={{ color: "white" }} /></span>
                      </>
                      }


                      {!view && isEdit &&
                        <span className="fs-18  text-color-white px-1" onClick={() => { setDeleteModal(true); }}   >
                          <FaTrash className='cursor' style={{ color: "white" }} />
                        </span>
                      }

                      <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                    </span>
                  </span>
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                  <SimpleBar className="h-100">
                    <div className="px-4 py-3">

                      {view ?
                        <AttendanceData editWorking={editWorking} handleEditClick={handleEditClick}
                          attendanceView={attendanceView} hoursList={hoursList} shift={shift} selectedCountry={selectedCountry} />
                        :
                        <>
                          <Form
                            className={
                              !!isEdit
                                ? "needs-validation view-event"
                                : "needs-validation"
                            }
                            name="event-form"
                            id="form-event"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >

                            <Row className="event-form">
                              {userDetails?.roles?.[0]?.name === "Admin" &&
                                <Col xs={12} md={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="user_id" className="form-label">User <span className='secondary'>*</span></Label>

                                    <Select
                                      placeholder="Select Employee..."
                                      value={validation.values.user_id ? userList?.filter(
                                        item => item?.id === validation.values.user_id)?.map((item, index) => {

                                          return (
                                            { value: item.id, label: item.name }
                                          );
                                        }) : ""}
                                      isDisabled={isEdit}

                                      onChange={(e) => {
                                        validation.setValues({
                                          ...validation.values,
                                          user_id: e?.value
                                        });

                                      }}

                                      options={userList?.filter(
                                        item => item?.id !== 1)?.map((item) => {
                                          return (
                                            { value: item.id, label: item.name }
                                          );
                                        })}


                                      styles={selectStyle('user_id')}
                                    />
                                  </div>

                                </Col>

                              }

                              <Col xs={12} md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Title <span className='secondary'>*</span></Label>
                                  <Input
                                    className="form-control px-2 py-1"
                                    placeholder="Enter title"
                                    type="text"
                                    name="title"
                                    id="title"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.title || ""}
                                    invalid={
                                      submit && validation.errors.title ? true : false
                                    }

                                  />

                                </div>
                              </Col>


                              <Col xs={6}>
                                <div className="mb-3">
                                  <Label>Working Start Date <span className='secondary'>*</span>

                                  </Label>
                                  <div className={"input-group"}  >

                                    <Flatpickr
                                      name='start_date'
                                      className={`form-control py-1 px-2 ${submit && validation.errors.start_date ? 'is-invalid' : ''}`}
                                      options={{
                                        allowInput: true,
                                        dateFormat: SmallDateFormat(selectedCountry),
                                      }}
                                      onChange={(selectedDates, dateStr, instance) => {
                                        validation.setValues({
                                          ...validation.values,
                                          start_date: dateStr
                                        });
                                      }}

                                      value={validation.values?.start_date || ""}
                                    />

                                  </div>
                                </div>

                              </Col>


                              <Col xs={6}>
                                <div className="mb-3">
                                  <Label>Start Time   <span className='secondary'>*</span> </Label>
                                  <div className={"input-group"}  >
                                    <Input id="start_time" name="start_time" placeholder="Enter start time"
                                      className="form-control px-2 py-1"
                                      defaultValue={validation.values.start_time || ""}
                                      onClick={() => {
                                        setModalStartTime(!modalStartTime);
                                        setFocus("");
                                        if (isEdit) {
                                          const inputTimeString = validation.values.start_time;
                                          if (inputTimeString) {
                                            const currentDate = new Date();
                                            const [hours, minutes] = inputTimeString.split(':').map(part => parseInt(part, 10));
                                            currentDate.setHours(hours, minutes, 0, 0);
                                            setStartTime(dayjs(currentDate));
                                          }
                                        }
                                      }}
                                      invalid={
                                        submit && validation.errors.start_time ? true : false
                                      }
                                      readOnly
                                    />

                                    <div className="modal" tabIndex="-1" aria-hidden="true" >
                                      <div className="modal-dialog modal-dialog-centered"    >
                                        <Modal size="md" backdrop={true} isOpen={modalStartTime} toggle={() => {
                                          setModalStartTime(!modalStartTime)
                                        }} centered>


                                          <ModalBody >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                              <DemoContainer components={['TimeClock', 'TimeClock', 'TimeClock']}>
                                                <DemoItem  >
                                                  <span className="d-flex justify-content-between align-items-center" >

                                                    <h6 className="text-muted" >SELECTED TIME</h6>
                                                    <span className="fs-18 text-muted cursor" onClick={() => { setModalStartTime(!modalStartTime) }}>   <FaTimes /> </span>

                                                  </span>

                                                  <h1> {startTime?.format('HH:mm:ss')}</h1>

                                                  <TimeClock
                                                    className="w-100"
                                                    value={startTime}
                                                    onChange={handleStartTimeChange}
                                                    ampm={false}
                                                    views={['hours', 'minutes', 'seconds']}
                                                    onFocusedViewChange={(e) => {  if (e === 'seconds') {
                                                      setFocus(e);
                                                    } }}
                                                  />



                                                </DemoItem>
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          </ModalBody >
                                        </Modal>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>


                              <Col xs={6}>
                                <div className="mb-3">
                                  <Label>Working End Date     </Label>
                                  <div className={"input-group"}  >

                                    <Flatpickr
                                      name='end_date'
                                      className={`form-control py-1 px-2 ${submit && validation.errors.end_date ? 'is-invalid' : ''}`}
                                      options={{
                                        allowInput: true,
                                        minDate: FormatMinDate(validation.values.start_date, selectedCountry),
                                        //  maxDate: FormatMaxDate(validation.values.start_date, selectedCountry),
                                        dateFormat: SmallDateFormat(selectedCountry),
                                        onReady: function (selectedDates, dateStr, instance) {
                                          instance.set('minDate', FormatMinDate(validation.values.start_date, selectedCountry));
                                        }
                                      }}
                                      onChange={(selectedDates, dateStr, instance) => {
                                        validation.setValues({
                                          ...validation.values,
                                          end_date: dateStr
                                        });
                                      }}

                                      value={validation.values?.end_date || ""}
                                    />

                                  </div>
                                </div>

                              </Col>



                              <Col xs={6}>
                                <div className="mb-3">
                                  <Label>End Time  </Label>
                                  <div className={"input-group"}  >
                                    <Input id="end_time" name="end_time" placeholder="Enter end time"
                                      className="form-control px-2 py-1"
                                      defaultValue={validation.values.end_time || ""}
                                      onClick={() => {
                                        setModalEndTime(!modalEndTime);
                                        setFocus("");
                                        if (isEdit) {
                                          if (validation.values.end_time) {
                                            const inputTimeString = validation.values.end_time;
                                            const currentDate = new Date();
                                            const [hours, minutes] = inputTimeString.split(':').map(part => parseInt(part, 10));
                                            currentDate.setHours(hours, minutes, 0, 0);
                                            setEndTime(dayjs(currentDate));
                                          }

                                        }
                                      }}
                                      invalid={
                                        submit && validation.errors.end_time ? true : false
                                      }

                                      readOnly
                                    />

                                    <div className="modal" tabIndex="-1" aria-hidden="true" >
                                      <div className="modal-dialog modal-dialog-centered"    >
                                        <Modal size="md" backdrop={true} isOpen={modalEndTime} toggle={() => {
                                          setModalEndTime(!modalEndTime)
                                        }} centered>


                                          <ModalBody    >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                              <DemoContainer components={['TimeClock', 'TimeClock', 'TimeClock']}>
                                                <DemoItem  >
                                                  <span className="d-flex justify-content-between align-items-center" >

                                                    <h6 className="text-muted mb-0" >SELECTED TIME</h6>
                                                    <span className="fs-18 text-muted cursor" onClick={() => { setModalEndTime(!modalEndTime) }}>   <FaTimes /> </span>

                                                  </span>

                                                  <h1> {endTime?.format('HH:mm:ss')}</h1>

                                                  <TimeClock
                                                    className="w-100"
                                                    value={endTime}
                                                    onChange={handleEndTimeChange}
                                                    ampm={false}
                                                    minTime={startTime}
                                                    views={['hours', 'minutes', 'seconds']}
                                                    onFocusedViewChange={(e) => {  if (e === 'seconds') {
                                                      setFocus(e);
                                                    } }}
                                                  />



                                                </DemoItem>
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          </ModalBody >
                                        </Modal>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>


                              <Col xs={12}>
                                <div className="mb-3">
                                  <Label className="form-label">Description</Label>
                                  <textarea
                                    className={"form-control d-block"}
                                    id="event-description"
                                    name="description"
                                    placeholder="Enter a description"
                                    rows="3"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ''}
                                  ></textarea>

                                </div>
                              </Col>

                            </Row>
                            <div className="hstack gap-2 justify-content-end">
                              {isEditButton && <button
                                type="submit"
                                className="btn btn-primary shadow-lg"
                                id="btn-save-event"
                                disabled={validation.isSubmitting ? true : false}
                                onClick={(e) => {
                                  setSubmit(true);
                                }}
                              >{validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                {!!isEdit ? "Update" : "Add Working Hours"}
                              </button>}
                            </div>
                          </Form>

                        </>
                      }



                    </div>
                  </SimpleBar>
                </OffcanvasBody>
              </Offcanvas>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default Calender;
