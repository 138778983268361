import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, CardBody, Col, Container, ModalHeader,
    Offcanvas, OffcanvasHeader, Spinner, OffcanvasBody, UncontrolledTooltip, Badge, Form, Input, Label, Modal, ModalBody, Row
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import Flatpickr from "react-flatpickr";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Pagination from "react-js-pagination";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import { ToastMessage, DefaultDate, SmallDateFormat, FormatDate, FormatMinDate } from "../../Components/Hooks/Function";

const Projects = () => {
    document.title = "Projects";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    var moment = require('moment-timezone');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [fromValue, setFromValue] = useState(moment().clone().tz(moment.tz.guess()).startOf('month'));
    const [toValue, setToValue] = useState(moment().clone().tz(moment.tz.guess()).endOf('month'));
    const [projectList, setProjectList] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [projectView, setProjectView] = useState(null)
    const [userView, setUserView] = useState(null)
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [modalTeam, setModalTeam] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [searchList, setSearchList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [view, setView] = useState(false);
    const handleAddClick = (arg) => {
         setView(false);
        setSubmit(false);
        validation.resetForm();
        validation.setValues({
            start_date: DefaultDate("", selectedCountry),
            status: true
        });
        setIsEdit(false);
        toggleDrawer();
    };

    const handleEditClick = useCallback((arg) => {
        setView(false);
        setSubmit(false);
        viewProject(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

    const handleViewClick = (arg) => {
        setView(true);
        viewProject(arg?.id);
        validation.resetForm();
        setIsEdit(false);
    };

    const onClickData = () => {
        setDeleteModal(true);
    };

    const handleDelete = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('projectUser/' + value.id, {});
            if (response.data.status === true) {
                viewProject(projectView?.id);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteUserModal(false)
        } catch (err) {
            console.error(err.message);
        }
    };



    const handleUserSelectionChange = (selected) => {
        validation.setValues({
            ...validation.values,
            user_id: selected
        });
    };

    const handleClientSelectionChange = (selected) => {
        filterValidation.setValues({
            ...filterValidation.values,
            client_id: selected.length > 0 ? selected : [{
                name: "Select...",
                value: 0
            }]
        });
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getProjectPage(e, rowPerPage);
    };


    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.client?.name.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.description?.toLowerCase().includes(inputVal.toLowerCase()) ||
                (item.status === 1 ? "Active" : "Inactive").toLowerCase().includes(inputVal.toLowerCase())
            );
            setProjectList({
                ...projectList,
                data: filteredResults
            });
        } else {
            setProjectList({
                ...projectList,
                data: searchList?.data
            });

        }
    };

    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                //  borderColor: state.isFocused ? 'grey'   :'#ced4da'  ,
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'

            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus
            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }

    const selectStyles = (fieldName) => {
        return {
            container: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
            }),
            control: (baseStyles, state) => ({
                ...baseStyles,
                margin: "0px 0px 5px 0px",
                minHeight: "30px",
            }),
            dropdownButton: (baseStyles, state) => ({
                ...baseStyles,
                width: "100%",
                height: "30px",
                padding: "5px 8px",
                boxWidth: "none",
                boxShadow: "none",
                color: "rgb(118, 118, 118)",
                border: "1px solid #ced4da",
                "& svg": {
                    color: "hsl(0, 0%, 80%)",
                    height: "35",
                    width: "35"
                }
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                // color: '#019aff',
                padding: "5px 12px",
                paddingLeft: '6px',
                cursor: "pointer",
                alignItems: 'center',
                display: 'flex',
            }),
            option: (baseStyles, state) => ({
                ...baseStyles,
                cursor: "pointer",
                paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
                fontWeight: state.data.label === 'Select All' && 'bold',
                // color: state.data.label === 'Select All' && '#019aff',
                // padding:"5px 12px",
                height: "30px",
                boxShadow: "none",
                display: 'flex',
                alignItems: 'center',
                backgroundColor: "transparent",
                "&:hover": {
                    backgroundColor: "transparent",
                },
                "&:active": {
                    backgroundColor: "unset",
                },
            }),
        };
    };

    const [loading, setLoading] = useState(false);

    const viewProject = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('project/' + id, {});
            if (response.data.status === true) {
                validation.setValues({
                    ...response.data?.data,
                    start_date: DefaultDate(response.data?.data?.start_date, selectedCountry),
                    end_date: DefaultDate(response.data?.data?.end_date, selectedCountry),
                    status: response.data?.data?.status === 1 ? true : false
                });

                setProjectView(response.data?.data);
            } else {
                setProjectView([]);
            }
            setOpen(true);
        } catch (err) {
            console.error(err.message);
        }
    };

    const getSettings = async () => {
        setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    };

    const getAllUser = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('getAllUser', {});
            if (response.data.status === true) {
                setUserList(response.data?.data);
            } else {
                setUserList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getAllClient = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('getAllClient', {});
            if (response.data.status === true) {
                setClientList(response.data?.data);
            } else {
                setClientList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getSettings();
        getAllUser();
        getAllClient();
    }, []);

    const addProject = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('project', {
                ...values,
                status: values?.status ? 1 : 0,
                start_date: values.start_date ? FormatDate(values.start_date, selectedCountry) : "",
                end_date: values.end_date ? FormatDate(values.end_date, selectedCountry) : "",
                user_id: values?.user_id ? values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '',
            });

            if (response.data.status === true) {
                getProject();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    const editProject = async (values, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.put('project/' + id, {
                ...values,
                status: values?.status ? 1 : 0,
                start_date: values.start_date ? FormatDate(values.start_date, selectedCountry) : "",
                end_date: values.end_date ? FormatDate(values.end_date, selectedCountry) : "",
                user_id: values?.user_id ? values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '',
            });

            if (response.data.status === true) {
                getProject();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
            //  setModal(!modal);
        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteProject = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('project/' + value.id, {});
            if (response.data.status === true) {
                getProject();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }
    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            client_id: "",
            project_manager_id: "",

            leader_id: "",
            start_date: "",
            end_date: "",
            description: '',
            status: true
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Enter"),
            client_id: Yup.string().required("Enter"),
            start_date: Yup.string().required("Enter"),
            end_date: Yup.string().notRequired(),
            leader_id: Yup.string().required("Enter"),
            project_manager_id: Yup.string().required("Enter"),
            description: Yup.string().notRequired(),
            status: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editProject(values, validation.values?.id);
            } else {
                addProject(values);
            }
        },
    });

    const filterValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            startDate: '',
            endDate: '',
            client_id: clientList?.map((item, index) => {
                return (
                    { value: item.id, label: item.name, }
                );
            }),
        },

        validationSchema: Yup.object({
            user_id: Yup.array(),
            startDate: Yup.date(),
            endDate: Yup.date(),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            api.defaults.headers.common['Content-Type'] = 'application/json';
            try {
                const response = await api.get(`project`, {
                    params: {
                        client_id: client_idParam,
                        startDate: startDateParam,
                        endDate: endDateParam,
                        page: currentPage,
                        perPage: rowPerPage
                    }
                });

                if (response.data.status === true) {
                    setSearchList(response.data?.data);
                    setProjectList(response.data?.data);

                } else {
                    setSearchList([]);
                    setProjectList([]);
                }
                setLoading(false);
            } catch (err) {
                console.error(err.message);
            }
        },
    });
    const startDateParam = fromValue ? `${moment(fromValue).format('YYYY-MM-DD')}` : '';
    const endDateParam = toValue ? `${moment(toValue).format('YYYY-MM-DD')}` : '';
    const client_idParam = filterValidation?.values?.client_id ? filterValidation.values.client_id?.map((value) => (value.value)).filter(value => value !== "") : "";

    useEffect(() => {
        getProject();
    }, []);

    const getProject = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`project`, {
                params: {
                    client_id: client_idParam,
                    startDate: startDateParam,
                    endDate: endDateParam,
                    page: currentPage,
                    perPage: rowPerPage
                }
            });
            if (response.data.status === true) {
                setProjectList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setProjectList([]);
                setSearchList([]);
            }
            setLoading(false);
            setCurrentPage(1);
        } catch (err) {
            console.error(err.message);
        }
    };

    const getProjectPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`project?page=${currentPage}&perPage=${rowPerPage}`,
                {
                    params: {
                        client_id: client_idParam,
                        startDate: startDateParam,
                        endDate: endDateParam,
                        page: currentPage,
                        perPage: rowPerPage
                    }
                });
            if (response.data.status === true) {
                setProjectList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setSearchList([]);
                setProjectList([]);
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getDefaultDate = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return [firstDayOfMonth, lastDayOfMonth];
    };

    const handleDateChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setFromValue(selectedDates[0]);
            setToValue(selectedDates[1]);
            filterValidation.handleSubmit();
        }
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={projectView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteProject(projectView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
                name={userView?.name}
                show={deleteUserModal}
                onDeleteClick={() => handleDelete(userView)}
                onCloseClick={() => setDeleteUserModal(false)}
            />

            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Projects</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center'  >
                        <Col sm={4}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                filterValidation.handleSubmit();
                                return false;
                            }}>
                                <Row  >
                                    <Col xs={7} md={8} className='pe-0 pe-md-3' >
                                        <Flatpickr
                                            className="form-control py-1 px-2"
                                            placeholder="Select Date Range"
                                            options={{
                                                allowInput: true,
                                                mode: 'range',
                                                dateFormat: SmallDateFormat(selectedCountry),
                                                defaultDate: getDefaultDate(),
                                            }}
                                            //     value={[fromValue, toValue]} // Pass the selected range as value
                                            onChange={handleDateChange}
                                        />
                                    </Col>

                                    <Col xs={5} md={4} className='mb-1 px-md-0' >
                                        <ReactMultiSelectCheckboxes
                                            name="client_id"
                                            value={filterValidation.values.client_id?.filter(item => (item?.value !== 0)).map((item, index) => {
                                                return ({ value: item.value, label: item.label });
                                            })}

                                            options={[{
                                                label: "Select All",
                                                options: clientList?.map((item, index) => {
                                                    return (
                                                        {
                                                            value: item.id, label: item.name,
                                                            // isIndeterminate: isSelectAllIndeterminate,  isChecked: isSelectAllChecked, 
                                                        }
                                                    );
                                                }),
                                            }]}

                                            onChange={handleClientSelectionChange}
                                            styles={selectStyles('client_id')}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => handleAddClick()} >
                                    <i className="ri-add-line"  ></i>  <span className='d-none d-md-block'> Project</span></button>
                                <FaPlus className="text-primary d-flex d-md-none" size={18} onClick={() => { handleAddClick(); }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-3  px-2"  >
                                            <h5 className="fs-15 mb-0">Project Name</h5>
                                        </div>

                                        <div className="col-3  px-1"  >
                                            <h5 className="fs-15 mb-0">Client / Vendor</h5>
                                        </div>

                                        <div className="col-3  px-1"  >
                                            <h5 className="fs-15 mb-0">Deadline</h5>
                                        </div>

                                        <div className="col-2 px-1">
                                            <h5 className="fs-15 mb-0">Status</h5>
                                        </div>

                                        <div className="col-1 d-lg-flex justify-content-end">
                                            <h5 className="fs-15 mb-0">Action</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1" id="project-list">
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            projectList?.data?.length !== 0 ?
                                (projectList?.data)?.map((item, index) => (
                                    <Col lg={12} key={index} className='mt-0 '  >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-lg-flex align-items-center row px-2">
                                                    <div className="col-6 col-md-3 px-1">
                                                        <a href="#!" > <span onClick={() => { handleViewClick(item); }}>  {item.name}</span>  </a>
                                                    </div>
                                                    <div className="col-6 col-md-3 px-1 d-flex justify-content-end  justify-content-md-start">
                                                        <p className="mb-0">  {item.client?.name}  </p>
                                                    </div>
                                                    <div className="col-6 col-md-3 px-1 px-md-2">
                                                        <p className="mb-0">  {DefaultDate(item.end_date, selectedCountry)}  </p>
                                                    </div>
                                                    <div className="col-6 col-md-2  px-1 px-md-2 d-flex justify-content-end  justify-content-md-start">
                                                        <p className="mb-0">   {item.status === 1 ?
                                                            <Badge color="success-subtle text-success" >Active</Badge>
                                                            :
                                                            <Badge color="danger-subtle text-danger" > Inactive  </Badge>
                                                        } </p>

                                                    </div>

                                                    <div className="col-12 col-md-1 d-flex gap-0 align-items-center justify-content-end px-1">
                                                        <button type="button" className="btn  btn-sm btn-icon btn25"
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <i className="ri-pencil-fill fs-18 primary"></i>
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-icon btn25"
                                                            onClick={(e) => { onClickData(item); setProjectView(item); }}>
                                                            <i className="ri-delete-bin-fill fs-18 secondary"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                    </div>
                                </div>
                        }
                    </Row>

                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getProjectPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >

                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={projectList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={projectList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >

                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{view ? "View Project" : !isEdit ? "Add Project" : "Update Project"}</span>
                                <span>
                                    {!view ? <span className="fs-18 px-1 text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">
                                    {view ? <>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Project Name  </Label> <br />
                                                    {projectView?.name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Client / Vendor </Label> <br />
                                                    {projectView?.client?.name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Start Date </Label> <br />
                                                    {DefaultDate(projectView?.start_date, selectedCountry)}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold">End Date </Label>   <br />
                                                    {DefaultDate(projectView?.end_date, selectedCountry)}
                                                </div>

                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-2">
                                                    <Label className="form-label fw-bold">Project Manager </Label>
                                                    <div className="avatar-group">
                                                        <Link to="#" className="avatar-group-item" id={`project_manager`} >
                                                            {projectView?.project_manager ? projectView?.project_manager?.employee?.profile ?
                                                                <img src={url + projectView?.project_manager?.employee?.profile} alt="project_manager" className="rounded-circle avatar-sm" />
                                                                :
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                                                        {projectView?.project_manager?.name?.slice(0, 1)}
                                                                    </div>
                                                                </div>
                                                                : ""}
                                                        </Link>

                                                        <UncontrolledTooltip placement="top" target={`project_manager`}> {projectView?.project_manager?.name} </UncontrolledTooltip>

                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-2">
                                                    <Label className="form-label fw-bold">Team Leader </Label>
                                                    <div className="avatar-group">
                                                        <Link to="#" className="avatar-group-item" id={`leader`} >
                                                            {projectView?.leader ? projectView?.leader?.employee?.profile ?
                                                                <img src={url + projectView?.leader?.employee?.profile} alt="leader" className="rounded-circle avatar-sm" />
                                                                : <div className="avatar-sm">
                                                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                                                        {projectView?.leader?.name?.slice(0, 1)}
                                                                    </div>
                                                                </div>
                                                                : ""}
                                                        </Link>

                                                        <UncontrolledTooltip placement="top" target={`leader`}> {projectView?.leader?.name} </UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="mb-2">
                                                    <Label className="form-label fw-bold">Team Members</Label>
                                                    <div className="avatar-group">
                                                        {projectView?.project_users ? projectView?.project_users?.filter(item => (item?.user_id !== projectView?.leader_id) && (item?.user_id !== projectView?.project_manager_id))?.map((item, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <Link to="#" className="avatar-group-item" id={`project_users${index}`} >
                                                                        {item.user?.employee?.profile ? <img src={url + item.user?.employee?.profile} alt={`project_users${index}`} className="rounded-circle avatar-sm" />
                                                                            :
                                                                            <div className="avatar-sm">
                                                                                <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                    {item.user?.name?.slice(0, 1)}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </Link>
                                                                    <UncontrolledTooltip placement="top" target={`project_users${index}`}> {item.user?.name} </UncontrolledTooltip>
                                                                </React.Fragment>
                                                            );
                                                        }) : ""}
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Description </Label>   <br />
                                                    <div dangerouslySetInnerHTML={{ __html: projectView?.description }} />
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Status </Label>    <br />
                                                    {projectView?.status === 1 ?
                                                        <Badge color="success-subtle text-success" >Active </Badge> :
                                                        <Badge color="danger-subtle text-danger"> Inactive </Badge>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                        :
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="name" className="form-label">Project Name <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                                                            name='name'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.name || ""}
                                                            invalid={submit && validation.errors.name ? true : false}
                                                        />

                                                    </div>

                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="client_id" className="form-label">Client / Vendor    <span className='secondary'>*</span></Label>
                                                        <Select
                                                            name='client_id'
                                                            defaultValue={validation.values.client ? clientList?.filter(
                                                                item => item?.id === validation.values.client_id)?.map((item, index) => {
                                                                    return (
                                                                        { value: item.id, label: item.name }
                                                                    );
                                                                }) : ""}

                                                            onChange={(e) => {

                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    client_id: e?.value
                                                                });
                                                            }}

                                                            options={clientList?.map((item, index) => {
                                                                return (
                                                                    { value: item.id, label: item.name }
                                                                );
                                                            })}

                                                            styles={selectStyle('client_id')}
                                                        />

                                                    </div>

                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="start_date" className="form-label">Start Date <span className='secondary'>*</span></Label>
                                                        <Flatpickr
                                                            name='start_date'
                                                            className={`form-control py-1 px-2 ${submit && validation.errors.start_date ? 'is-invalid' : ''}`}

                                                            options={{
                                                                allowInput: true,
                                                                dateFormat: SmallDateFormat(selectedCountry),
                                                            }}
                                                            onChange={(selectedDates, dateStr, instance) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    start_date: dateStr
                                                                });
                                                            }}

                                                            value={validation.values.start_date || ""}

                                                        // invalid={submit && validation.errors.start_date ? true : false}
                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="end_date" className="form-label">End Date </Label>
                                                        <Flatpickr
                                                            name='end_date'
                                                            className={`form-control py-1 px-2 ${submit && validation.errors.end_date ? 'is-invalid' : ''}`}
                                                            options={{
                                                                allowInput: true,
                                                                minDate: FormatMinDate(validation.values.start_date, selectedCountry),
                                                                dateFormat: SmallDateFormat(selectedCountry),
                                                                onReady: function (selectedDates, dateStr, instance) {
                                                                    instance.set('minDate', FormatMinDate(validation.values.start_date, selectedCountry));
                                                                }
                                                            }}
                                                            onChange={(selectedDates, dateStr, instance) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    end_date: dateStr
                                                                });
                                                            }}

                                                            value={validation.values.end_date || ""}

                                                        // invalid={submit && validation.errors.end_date ? true : false}

                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="project_manager_id" className="form-label"> Select Project Manager <span className='secondary'>*</span> </Label>
                                                        <Select
                                                            name="project_manager_id"
                                                            defaultValue={validation.values.project_manager?.name ? {
                                                                "value": validation.values.project_manager?.id,
                                                                "label": validation.values.project_manager?.name
                                                            } : ""}
                                                            options={[{ value: "", label: "Select" },
                                                            ...userList?.filter(item => ((item?.id !== 1) && (item?.id !== validation.values.leader_id)))?.map((item, index) => {
                                                                return ({ value: item.id, label: item.name });
                                                            })]}

                                                            placeholder="Select..."
                                                            onChange={(e) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    project_manager_id: e?.value
                                                                });
                                                            }}

                                                            styles={selectStyle('project_manager_id')}
                                                        />
                                                    </div>
                                                </Col>

                                                {isEdit && <Col lg={6}>
                                                    <div className="mb-2">

                                                        <Label htmlFor="end_date" className="form-label">Project Manager </Label>
                                                        <div className="avatar-group">
                                                            <Link to="#" className="avatar-group-item" id={`project_manager`} >
                                                                {validation.values.project_manager ?
                                                                    validation.values.project_manager?.employee?.profile ? <img src={url + validation.values.project_manager?.employee?.profile} alt="project_manager" className="rounded-circle avatar-sm" />
                                                                        :
                                                                        <div className="avatar-sm">
                                                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                {validation.values.project_manager?.name?.slice(0, 1)}
                                                                            </div>
                                                                        </div>
                                                                    : ""}
                                                            </Link>

                                                            <UncontrolledTooltip placement="top" target={`project_manager`}> {projectView?.project_manager?.name} </UncontrolledTooltip>

                                                        </div>
                                                    </div>
                                                </Col>}

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="leader_id" className="form-label"> Select Team Leader <span className='secondary'>*</span> </Label>
                                                        <Select
                                                            name="leader_id"
                                                            defaultValue={
                                                                validation.values.leader?.name ? {
                                                                    "value": validation.values.leader?.id,
                                                                    "label": validation.values.leader?.name
                                                                } : ""}
                                                            options={[{ value: "", label: "Select" },
                                                            ...userList?.filter(item => ((item?.id !== 1) && (item?.id !== validation.values.project_manager_id)))?.map((item, index) => {
                                                                return ({ value: item.id, label: item.name });
                                                            })]}

                                                            placeholder="Select..."
                                                            onChange={(e) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    leader_id: e?.value
                                                                });
                                                            }}

                                                            styles={selectStyle('leader_id')}
                                                        />
                                                    </div>
                                                </Col>

                                                {isEdit && <Col lg={6}>
                                                    <div className="mb-2">

                                                        <Label htmlFor="end_date" className="form-label">Team Leader </Label>
                                                        <div className="avatar-group">
                                                            <Link to="#" className="avatar-group-item" id={`leader`} >
                                                                {validation.values.leader ?
                                                                    validation.values.leader?.employee?.profile ? <img src={url + validation.values.leader?.employee?.profile} alt="leader" className="rounded-circle avatar-sm" />
                                                                        :
                                                                        <div className="avatar-sm">
                                                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                {validation.values.leader?.name?.slice(0, 1)}
                                                                            </div>
                                                                        </div>
                                                                    : ""}
                                                            </Link>

                                                            <UncontrolledTooltip placement="top" target={`leader`}> {projectView?.leader?.name} </UncontrolledTooltip>

                                                        </div>
                                                    </div>
                                                </Col>}

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="user_id" className="form-label">Add Team Members  </Label>
                                                        <ReactMultiSelectCheckboxes
                                                            className="form-control py-1 px-2"
                                                            options={[{
                                                                label: "Select All",
                                                                options: userList?.filter(item => ((item?.id !== 1) && (item?.id !== validation.values.leader_id) && (item?.id !== validation.values.project_manager_id)))?.map((item, index) => {

                                                                    return (
                                                                        {
                                                                            value: item.id, label: item.name,
                                                                            // isIndeterminate: isSelectAllIndeterminate,  isChecked: isSelectAllChecked, 
                                                                        }
                                                                    );
                                                                }),
                                                            }]}

                                                            onChange={handleUserSelectionChange}
                                                            styles={selectStyles('user_id')}
                                                        />
                                                    </div>
                                                </Col>

                                                {isEdit && <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="end_date" className="form-label">Team Members</Label>

                                                        <div className="avatar-group">
                                                            {validation.values.project_users ? validation.values.project_users?.filter(item => ((item?.user_id !== 1) && (item?.user_id !== validation.values.leader_id) && (item?.user_id !== validation.values.project_manager_id) && (item?.user !== null)))?.slice(0, 2).map((item, index) => {

                                                                return (
                                                                    item.user &&
                                                                    <React.Fragment key={index}>
                                                                        <Link to="#" className="avatar-group-item" id={`team_users${index}`} onClick={() => { setModalTeam(!modalTeam) }}>
                                                                            {item.user?.employee?.profile ? <img src={url + item.user?.employee?.profile} alt={`team_users${index}`} className="rounded-circle avatar-sm" />
                                                                                : <div className="avatar-sm">
                                                                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                        {item.user?.name?.slice(0, 1)}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </Link>
                                                                        <UncontrolledTooltip placement="top" target={`team_users${index}`} > {item.user?.name} </UncontrolledTooltip>
                                                                    </React.Fragment>
                                                                );
                                                            }) : ""}

                                                            {validation.values.project_users?.filter(item => ((item?.user_id !== 1) && (item?.user_id !== validation.values.leader_id) && (item?.user_id !== validation.values.project_manager_id) && (item?.user !== null)))?.length > 2 && <>
                                                                <Link to="#" className="avatar-group-item" id="more" onClick={() => {
                                                                    setModalTeam(!modalTeam);
                                                                }}>
                                                                    <div className="avatar-sm">
                                                                        <div className="avatar-title rounded-circle">
                                                                            2+
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <UncontrolledTooltip placement="top" target="more" > More </UncontrolledTooltip>
                                                            </>}
                                                        </div>
                                                    </div>
                                                </Col>}

                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="description" className="form-label">Description</Label>
                                                        <CKEditor

                                                            name="description"
                                                            editor={ClassicEditor}

                                                            data={validation.values.description || ""}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();

                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    description: data ? data : ""
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                </Col>



                                                <Col xs={6}>

                                                    <div className="mb-2">
                                                        <Label htmlFor="roleName" className="form-label">Status</Label>

                                                        <div className="form-check form-switch form-switch-left form-switch-md">
                                                            <Input
                                                                type="switch"
                                                                id="status_id"
                                                                name="status"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                defaultValue={validation.values.status}
                                                                checked={validation.values.status}
                                                            />
                                                            {validation.values.status ? "Active" : "Inactive"}
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col lg={12}  >
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="submit" className="btn btn-primary shadow-lg" id="addNew" onClick={() => {
                                                            setSubmit(true);

                                                        }}>
                                                            {!isEdit ? "Add Project" : "Update Project"}</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                    }


                                    <div className="modal fade" id="showTeamMem" tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <Modal size="md" isOpen={modalTeam} toggle={() => { setModalTeam(!modalTeam); }} centered>
                                                <ModalHeader className='bpBottom pt-2 pb-2' toggle={() => { setModalTeam(!modalTeam); }}  >  {"View Team Member"}   </ModalHeader>
                                                <ModalBody className='px-3 py-2' >
                                                    <Row className="row  gy-1 mb-1 px-2" >
                                                        <Col lg={12} className='table px-1  mb-0' >
                                                            <Card className="mb-0 d-none d-md-flex px-1 table-info border-0  rounded-0">
                                                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                                                        <div className="col-10 px-1">

                                                                            <h5 className="fs-15 mb-0"> User Name</h5>
                                                                        </div>

                                                                        <div className="col-2 d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                                                                            <h5 className="fs-15 mb-0">Action</h5>
                                                                        </div>

                                                                    </div>

                                                                </CardBody>
                                                            </Card>
                                                        </Col>

                                                    </Row>
                                                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                                                        {validation.values.project_users ? validation.values.project_users?.filter(item => ((item?.user_id !== 1) && (item?.user_id !== validation.values.leader_id) && (item?.user_id !== validation.values.project_manager_id) && (item?.user !== null)))?.map((item, index) => {
                                                            return (
                                                                <Col lg={12} key={index} className='mt-0 '  >
                                                                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                                                        <CardBody className="px-1 py-0">
                                                                            <div className="d-lg-flex align-items-center row">
                                                                                <div className="col-6 col-md-11 px-1"  >
                                                                                    <p className="mb-0">  {item.user?.name}   </p>
                                                                                </div>

                                                                                <div className="col-6 col-md-1 d-flex align-items-center justify-content-en">
                                                                                    <button type="button" className="btn btn-sm btn-icon btn25"
                                                                                        onClick={(e) => {
                                                                                            setUserView(item);
                                                                                            setDeleteUserModal(true);
                                                                                        }}>
                                                                                        < i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            );
                                                        })
                                                            : "No Team Member"}
                                                    </Row>
                                                </ModalBody>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Projects;