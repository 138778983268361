import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Card, CardBody, Col, Container, Dropdown, ModalHeader,
  Offcanvas,
  OffcanvasHeader, Spinner,
  OffcanvasBody,
  Collapse, Form, Input, Label, Modal, ModalBody, FormGroup, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { FaCheck, FaTimesCircle, FaTimes, FaPlus, FaFilePdf, FaFileAlt, FaFileExcel } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const Documents = () => {
  document.title = "Documents";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [deleteModal, setDeleteModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [documentView, setDocumentView] = useState(null)
  const [open, setOpen] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [isEdit, setIsEdit] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [modalAttachment, setModalAttachment] = useState({
    show: false,
    file: [],
  });
  const [file, setFile] = useState([]);
  const [isExpanded, setIsExpanded] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
  const pdfExtensions = new Set(["pdf"]);
  const excelExtensions = new Set(["xls", "xlsx"]);
  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? ' ' : ' ',
      }),
      groupHeading: (provided) => ({
        fontWeight: 'bold',
        color: '#019aff',
        paddingLeft: '10px',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px', // Change padding on focus
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    }
  }

  const [view, setView] = useState(false);
  const handleAddClick = (arg) => {
    setView(false);
    setSubmit(false);
    setAttachmentList([]);
    setFile([]);
    setFiles([]);
    validation.resetForm();
    validation.setValues({});
    setIsEdit(false);
    toggleDrawer();
  };

  const handleEditClick = useCallback((arg) => {
    setView(false);
    setSubmit(false);
    setFile([]);
    setFiles([]);
    setAttachmentList([]);
    viewDocument(arg?.id);
    validation.resetForm();
    setIsEdit(true);
  }, []);

  const handleViewClick = (arg) => {
    setView(true);
    viewDocument(arg?.id);
    validation.resetForm();
    setIsEdit(false);
  };

  const onClickData = () => {
    setDeleteModal(true);
  };

  const handleClick = (e) => {
    setCurrentPage(e);
    getDocumentPage(e, rowPerPage);
  };

  const getCategory = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('documentCategoryList', {});
      if (response.data.status === true) {
        setCategoryList(response.data?.data);
      } else {
        setCategoryList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleDelete = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('documentAttachment/' + id, {});
      if (response.data.status === true) {
        setAttachmentList(attachmentList?.filter(item => item.id !== id));
        setFile(file?.filter(item => item.id !== id));
        setFiles(files?.filter(item => item.id !== id));
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const keywordSearch = async (e) => {
    let inputVal = e;

    if (inputVal !== "") {
      const filteredResults = searchList?.data?.filter((item) => {
        const itemNameMatch = item.name?.toLowerCase().includes(inputVal.toLowerCase());
        const itemDescriptionMatch = item.description?.toLowerCase().includes(inputVal.toLowerCase());
        const attachmentNameMatch = (item.document_attachments || []).some((document) =>
          document.name?.toLowerCase().includes(inputVal.toLowerCase())
        );

        return itemNameMatch || itemDescriptionMatch || attachmentNameMatch;
      });

      setDocumentList({
        ...documentList,
        data: filteredResults
      });
    } else {
      setDocumentList({
        ...documentList,
        data: searchList?.data
      });
    }
  };

  const handleFileChange = (e) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize <= maxSizeInBytes) {
      setFile(files);
      setFiles(files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));

    } else {
      setFile([]);
      ToastMessage("error", "Uplaoad File is Greater than 10 MB");

    }
  }

  const viewAttachmet = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('documentAttachment/' + id, {});
      if (response.data.status === true) {
        setModalAttachment({ ...modalAttachment, show: true, file: response.data.data });
      } else {
        setModalAttachment({ ...modalAttachment, show: true, file: [], });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  const [loading, setLoading] = useState(false);

  const getDocument = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`document`, {});
      if (response.data.status === true) {
        setDocumentList(response.data?.data);
        setSearchList(response.data?.data);
        setIsExpanded(response.data?.data?.data?.map(() => false))
      } else {
        setDocumentList([]);
        setSearchList([]);
        ToastMessage("error", response.data?.message);
      }

      setLoading(false);
      setCurrentPage(1);

    } catch (err) {
      console.error(err.message);
    }
  };


  const getDocumentPage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`document?page=${currentPage}&perPage=${rowPerPage}`);
      if (response.data.status === true) {
        setDocumentList(response.data?.data);
        setSearchList(response.data?.data);
        setIsExpanded(response.data?.data?.data?.map(() => false))
      } else {
        setDocumentList([]);
        setSearchList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const handleToggle = (index) => {
    const newExpandedStates = [...isExpanded];
    newExpandedStates[index] = !newExpandedStates[index];
    setIsExpanded(newExpandedStates);
  };

  useEffect(() => {
    setIsExpanded(documentList?.data?.map(() => false))
  }, [documentList]);


  const viewDocument = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('document/' + id, {});

      if (response.data.status === true) {

        validation.setValues(response.data?.data);
        setDocumentView(response.data?.data);
        setAttachmentList(response.data?.attachment);

      } else {
        setDocumentView([]);
        setAttachmentList([]);
      }

      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getDocument();
    getCategory();
  }, []);

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
    );
  };

  const addDocuments = async (values) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key] || '');
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        formData.append('attachment[]', files);
      });

      const response = await api.post('document', formData);
      if (response.data.status === true) {
        getDocument();
        validation.resetForm();
        setIsEdit(false);
        setOpen(false);
        setFile([]);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const editDocuments = async (values, id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('_method', "PUT");
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key] || '');
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        formData.append('attachment[]', files);
      });

      const response = await api.post('document/' + id, formData);

      if (response.data.status === true) {
        getDocument();
        setIsEdit(false);
        validation.resetForm();
        setFile([]);
        setOpen(false);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteDocuments = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('document/' + value?.id, {});
      if (response.data.status === true) {
        getDocument();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }
  };


  function getExtension(filename) {
    return filename?.split('.').pop()
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      document_category_id: '',
      description: '',
      attachment: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Enter name"),
      document_category_id: Yup.string().required("Select Category"),
      description: Yup.string().notRequired(),
      attachment: !isEdit ? Yup.array().required("Enter name") : Yup.array().notRequired(),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isEdit) {
          await editDocuments(values, validation.values?.id);
        } else {
          await addDocuments(values);
        }
        setSubmitting(false);
      } catch (error) {
        console.error("An error occurred:", error);
        setSubmitting(false);
      }
    },

  });


  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        name={documentView?.name}
        show={deleteModal}
        onDeleteClick={() => deleteDocuments(documentView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Documents</h4>

              </div>
            </Col>
          </Row>
          <Row className='d-flex align-items-center'  >
            <Col sm={4}>
            </Col>
            <Col className="col-sm-auto ms-auto d-flex gap-2">
              <div className="search-box">
                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                <i className="ri-search-line search-icon"></i>
              </div>

              <div className="list-grid-nav hstack gap-1">
                <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => handleAddClick()} >
                  <i className="ri-add-line"  ></i>  <span className='d-none d-md-block'> Documents</span></button>
                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />
              </div>
            </Col>
          </Row>


          <Row className="row  gy-1 mb-1  mt-1  px-2" >
            <Col lg={12} className='table px-1  mb-0' >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0 rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center px-0 py-2 d-none d-md-none d-lg-block">

                    <div className="col-6 col-md-3 px-2"  >

                      <h5 className="fs-15 mb-0">Name</h5>

                    </div>

                    <div className={`col-md-3  px-1`}>

                      <h5 className="fs-15 mb-0">Description</h5>

                    </div>

                    <div className={`col-md-4  px-1`}>

                      <h5 className="fs-15 mb-0">Attachment</h5>

                    </div>

                    <div className={`col-12 col-md-2  d-flex  align-items-center justify-content-end  px-3 `}>

                      <h5 className="fs-15 mb-0">  Action</h5>

                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1" id="documents-list">
            {
              loading ?
                <div className='text-center'>
                  <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                    Loading...
                  </Spinner>
                </div>
                :
                documentList?.data?.length !== 0 ?

                  (documentList?.data)?.map((item, index) => (

                    <Col lg={12} key={index} className='mt-0 '  >
                      <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                        <CardBody className="px-2 py-0">
                          <div className="d-lg-flex align-items-center row px-2">
                            <div className="col-8 col-md-3 order-1 order-md-1 px-1"  >
                              <p className="mb-0 "> {item.name}  </p>
                            </div>                            

                            <div className={`col-12 col-md-3 order-3 order-md-2 d-flex justify-content-start  px-1`}>
                              <p className="mb-0 " key={index}>
                                {isExpanded?.[index] ? item?.description : item?.description?.length > 35 ? item?.description?.slice(0, 35) + "..." : item?.description}
                                {item?.description?.length > 35 && (
                                  <a href="#" onClick={() => handleToggle(index)}>
                                    {isExpanded?.[index] ? ' Read Less' : ' Read More'}
                                  </a>
                                )}
                              </p>

                            </div>
                            <div className={`col-12 col-md-4 px-1 order-4 order-md-3`}>

                              {item.document_attachments?.length > 0 ?
                                (item.document_attachments)?.map((document, index) => (
                                  <p className='mb-0' key={index}> {index + 1}: {document.name} </p>
                                )) : ""}

                            </div>

                            <div className={`col-4 col-md-2 order-2 order-md-4 d-flex gap-0 align-items-center justify-content-end px-1 `}>
                              {item.document_attachments?.length > 0 ?
                                <button type="button" className="btn  btn-sm btn-icon btn25"
                                  onClick={(e) => { viewAttachmet(item.id) }}>
                                  <i className="ri-attachment-2 fs-18 primary"  ></i>
                                </button> : " "}

                              <button type="button" className="btn  btn-sm btn-icon btn25"
                                onClick={(e) => { handleEditClick(item); }}>
                                <i className="ri-pencil-fill fs-18 primary"  ></i>
                              </button>

                              <button type="button" className="btn btn-sm btn-icon btn25"
                                onClick={(e) => { onClickData(item); setDocumentView(item); }}>
                                < i className="ri-delete-bin-fill fs-18 secondary" ></i>
                              </button>

                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                  ))

                  :
                  <div className="noresult" >
                    <div className="text-center">
                      <h6 className="mt-2">Sorry! No Result Found</h6>
                    </div>
                  </div>
            }
          </Row>



          <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getDocumentPage(1, e.target.value);
                }}
                value={rowPerPage}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={documentList?.total}>All</option>
              </select>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={documentList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>
          </div>

          <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                setModalAttachment({
                  ...modalAttachment,
                  show: false,
                  file: [],
                });
              }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => {
                  setModalAttachment({
                    ...modalAttachment,
                    show: false,
                    file: [],
                  });
                }}  >
                  {"View Attachment"}

                </ModalHeader>

                <ModalBody>

                  {modalAttachment?.show ? <>

                    <div className='text-center pb-2' >

                      {imageExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                        <img

                          src={url + modalAttachment?.file?.[currentSlide]?.path}
                          alt={modalAttachment?.file?.[currentSlide]?.name}
                          width={isDesktop ? 500 : "100%"}
                          height={"100%"}

                        />
                        : pdfExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                          <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />
                          : excelExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                            <>  {modalAttachment?.file?.[currentSlide]?.name}</>
                            : <>  {modalAttachment?.file?.[currentSlide]?.name}</>

                      }

                    </div>
                  </>
                    : ""}


                  {modalAttachment?.file?.[0]?.path !== undefined ?

                    <div className='pb-2 text-center' >
                      <button className="btn btn-primary shadow-lg">
                        <a style={{ color: "white" }} href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                      </button>
                    </div>

                    : ""}


                  {modalAttachment?.file?.length > 1 ?

                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                          <FeatherIcon icon="arrow-left" size="20" />
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" onClick={goToNextSlide}>
                          <FeatherIcon icon="arrow-right" size="20" />
                        </Link>
                      </div>
                    </div>


                    : ""}

                </ModalBody>
              </Modal>
            </div>
          </div>

          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >

              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{view ? "View Documents" : !isEdit ? "Add Documents" : "Update Documents"}</span>
                <span>
                  {!view ? <span className="fs-18 px-1 text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>
              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">

              <SimpleBar className="h-100">
                <div className="px-4 py-3">
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-2">
                          <Label htmlFor="name" className="form-label">Name <span className='secondary'>*</span></Label>
                          <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                            name='name'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={submit && validation.errors.name ? true : false
                            }
                          />

                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-2">
                          <Label htmlFor="category" className="form-label">Category <span className='secondary'>*</span></Label>
                          <Select
                            defaultValue={
                              validation.values.document_category?.name ? {
                                "value": validation.values.document_category_id,
                                "label": validation.values.document_category?.name
                              } : ""}

                            options={
                              [
                                { value: "", label: "Select" },
                                ...categoryList?.map((item, index) => {
                                  return (
                                    { value: item.id, label: item.name }
                                  );
                                })

                              ]
                            }

                            placeholder="Select..."
                            name='document_category_id'
                            onChange={(e) => {
                              validation.setValues({
                                ...validation.values,
                                document_category_id: e?.value
                              });
                            }}

                            styles={selectStyle('document_category_id')}
                          />

                        </div>

                      </Col>

                      <Col lg={12}>
                        <div className="mb-2">
                          <Label htmlFor="description" className="form-label">Description </Label>
                          <Input type="textarea" className="form-control px-2 py-1" id="description" placeholder="Enter description"
                            name='description'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={submit && validation.errors.description ? true : false}
                            maxLength={250}
                            //   style={{ height: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", minHeight: '50px', maxHeight: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", }} 
                            multiline="true"
                          />
                        </div>
                      </Col>

                      <Col lg={12} className='py-2'>
                        <div className="mb-2">
                          <Label htmlFor="attachment" className="form-label">Upload Attachment  <span className='secondary'>*</span></Label>
                          <Input type="file" className="form-control px-2 py-1" id="attachment"
                            name='attachment'
                            onChange={(e) => {
                              handleFileChange(e);
                              let inputValue = e.target.value;
                              if (inputValue.length > 10) {
                                inputValue = inputValue.slice(0, 10);
                              }

                              validation.handleChange({
                                target: {
                                  name: 'attachment',
                                  value: files,
                                },
                              });
                            }}

                            invalid={submit && validation.errors.attachment ? true : false}
                            multiple />
                        </div>

                        <Row className='py-2'>
                          {(files || [])?.map((item, index) => {
                            return [
                              <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                <FaTimesCircle className="close-button" onClick={(e) => {
                                  setFiles(
                                    files.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                  );
                                  setFile(
                                    file.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                  );
                                }} />
                                <div className="selected-image cursor" key={index} onClick={(e) => {
                                  setModalFile(true); setFileUrl({ name: item.name, path: item.preview });
                                }}>

                                  {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                    <img
                                      src={item.preview}
                                      alt={item.name}
                                      width={100}
                                      height={80}
                                    />
                                    : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                      <FaFilePdf size={30} />
                                      : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                        <FaFileExcel size={30} />
                                        : <FaFileAlt size={30} />

                                  }

                                </div>
                              </Col>

                            ]
                          })}

                          {attachmentList?.map((item, index) => {
                            return [

                              <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                  handleDelete(item.id);
                                }} />

                                <div className="selected-image cursor" key={index} onClick={(e) => {
                                  setModalFile(true);

                                  setFileUrl({ name: item.path, path: url + item.path });
                                }}   >

                                  {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                    <img
                                      src={url + item.path}
                                      alt={item.name}
                                      width={100}
                                      height={80}
                                    />
                                    : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                      <FaFilePdf size={30} />
                                      : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                        <FaFileExcel size={30} />
                                        : <FaFileAlt size={30} />
                                  }
                                </div>
                              </Col>
                            ]
                          })}</Row>
                      </Col>

                      <div className="modal fade" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                          <Modal size="lg" isOpen={modalFile} toggle={() => {
                            setModalFile(false);
                          }} centered>
                            <ModalHeader className='bpBottom pb-2' toggle={() => {
                              setModalFile(false);
                            }}  >
                              {"View Attachment"}

                            </ModalHeader>
                            <ModalBody >

                              {modalFile && <>
                                <div className='text-center py-4' >
                                  {imageExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                    <img
                                      src={fileUrl.path}
                                      alt={fileUrl.name}
                                      width={isDesktop ? 500 : "100%"}
                                      height={"100%"}

                                    />
                                    : pdfExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                      <iframe src={fileUrl.path} className='iframe' />
                                      : excelExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                        <>  {fileUrl.name}</>
                                        : <>  {fileUrl.name}</>

                                  }
                                </div>
                              </>
                              }


                              <div className='pt-2 text-center' >
                                <button className="btn btn-primary shadow-lg">
                                  <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                </button>
                              </div>

                            </ModalBody>
                          </Modal>
                        </div>
                      </div>


                      <Col lg={12}  >
                        <div className="hstack gap-2 justify-content-end">
                          <button type="submit" disabled={validation.isSubmitting ? true : false}
                            className="btn btn-primary shadow-lg" onClick={() => {
                              setSubmit(true);
                            }}> {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                            {!isEdit ? "Add Documents" : "Update Documents"}</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>

                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Documents;