import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Card, CardBody, Col, Container, Offcanvas,
    OffcanvasHeader, Spinner, OffcanvasBody, Badge,
    Form, Input, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import { FaCheck, FaTimes, FaTimesCircle, FaPlus, } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const Clients = () => {
    document.title = "Client / Vendor";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [deleteModal, setDeleteModal] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [clientView, setClientView] = useState(null);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [file, setFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileUrl, setFileUrl] = useState([]);
    const [modalLogo, setModalLogo] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [view, setView] = useState(false);
    const handleAddClick = (arg) => {
        setView(false);
        setSubmit(false);
        setFile([]);
        setFiles([]);
        validation.resetForm();
        validation.setValues({
            status: true
        });
        setIsEdit(false);
        toggleDrawer();
    };

    const handleEditClick = useCallback((arg) => {
        setView(false);
        setSubmit(false);
        setFile([]);
        setFiles([]);
        viewClient(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

    const handleViewClick = (arg) => {
        setView(true);
        viewClient(arg?.id);
        validation.resetForm();
        setIsEdit(false);
    };

    const handleRemoveLogo = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('deleteLogo/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const onClickData = () => {
        setDeleteModal(true);
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getClientPage(e, rowPerPage);
    };

    const keywordSearch = async (e) => {
        let inputVal = e;

        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.description?.toLowerCase().includes(inputVal.toLowerCase()) ||
                (item.status === 1 ? "Active" : "Inactive").toLowerCase().includes(inputVal.toLowerCase())
            );
            setClientList({
                ...clientList,
                data: filteredResults
            });

        } else {
            setClientList({
                ...clientList,
                data: searchList?.data
            });

        }
    };

    const handleLogoChange = (e) => {
        const maxSizeInBytes = 10 * 1024 * 1024;
        const fileData = Array.from(e.target.files);
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        if (totalSize <= maxSizeInBytes) {
            setFile(fileData);
            setFiles(fileData.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
        } else {
            setFile([]);
            setFiles([]);

            ToastMessage("error", "Uplaoad File is Greater than 10 MB");
        }
    }

    const [loading, setLoading] = useState(false);

    const getClient = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {

            const response = await api.get(`client`, {});
            if (response.data.status === true) {
                setClientList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setClientList([]);
                setSearchList([]);
            }

            setLoading(false);
            setCurrentPage(1);

        } catch (err) {
            console.error(err.message);
        }

    };


    const getClientPage = async (currentPage, rowPerPage) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`client?page=${currentPage}&perPage=${rowPerPage}`);

            if (response.data.status === true) {
                setClientList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setClientList([]);
                setSearchList([]);
            }

        } catch (err) {
            console.error(err.message);
        }
    };


    const viewClient = async (id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('client/' + id, {});
            if (response.data.status === true) {
                validation.setValues({
                    ...response.data?.data,
                    status: response.data?.data?.status === 1 ? true : false
                });
                setClientView(response.data?.data);

            } else {
                setClientView([]);
            }
            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };

    useEffect(() => {
        getClient();
    }, []);


    const addClient = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    if (key === "status") {
                        formData.append('status', values.status ? 1 : 0,);
                    } else {
                        formData.append(key, values[key] || '');
                    }
                }
            }
            if (file) {
                formData.append(`logo`, file[0]);
            }
            const response = await api.post('client', formData);

            if (response.data.status === true) {
                getClient();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    const editClient = async (values, id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';

        try {
            const formData = new FormData();
            formData.append('_method', "PUT");

            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    if (key === "status") {
                        formData.append('status', values.status ? 1 : 0,);
                    } else {
                        formData.append(key, values[key] || '');
                    }

                }
            }

            if (file) {
                formData.append(`logo`, file[0]);
            }
            const response = await api.post('client/' + id, formData);
            if (response.data.status === true) {
                getClient();
                setIsEdit(false);
                setOpen(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
            //  setModal(!modal);

        } catch (err) {
            console.error(err.message);
        }

    };

    const deleteClient = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('client/' + value?.id, {});
            if (response.data.status === true) {
                getClient();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }

    };



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            contact_name: '',
            contact_mobile: '',
            contact_email: '',
            contact_address: '',
            contact_person_designation: '',
            description: '',
            status: "",

        },
        validationSchema: Yup.object({
            name: Yup.string().required("Enter"),
            contact_name: Yup.string().required("Enter"),
            contact_mobile: Yup.string()
                .min(10, "Mobile must be more than 10 characters long")
                .max(10, "Mobile must be less than 10 characters long").required("Please enter"),
            contact_email: Yup.string().required("Enter"),
            contact_address: Yup.string().notRequired(),
            contact_person_designation: Yup.string().notRequired(),
            description: Yup.string().notRequired(),
            status: Yup.string().required("Enter"),

        }),
        onSubmit: (values) => {
            if (isEdit) {
                editClient(values, validation.values?.id);
            } else {
                addClient(values);
            }
        },
    });


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={clientView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteClient(clientView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2 page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Client / Vendor</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center' >

                        <Col sm={4}>

                        </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => handleAddClick()} >
                                    <i className="ri-add-line"></i> <span className='d-none d-md-block'> Client</span></button>
                                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />
                            </div>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1 mt-1 px-2" >
                        <Col lg={12} className='table px-1 mb-0' >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0 rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                        <div className="col-2 px-2">
                                            <h5 className="fs-15 mb-0">Name</h5>
                                        </div>

                                        <div className="col-2 px-2">
                                            <h5 className="fs-15 mb-0">Contact Name</h5>

                                        </div>

                                        <div className="col-3 px-2">
                                            <h5 className="fs-15 mb-0">Contact Email </h5>
                                        </div>

                                        <div className="col-2 px-2">
                                            <h5 className="fs-15 mb-0">Contact Mobile</h5>
                                        </div>

                                        <div className="col-2 px-3">
                                            <h5 className="fs-15 mb-0"> Status </h5>
                                        </div>

                                        <div className="col-1 d-lg-flex justify-content-end px-2">
                                            <h5 className="fs-15 mb-0">Action</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                    <Row className="row gy-1 mb-1" id="Client-list">
                        {
                            loading ?
                                <div className='text-center'>
                                    <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                        Loading...
                                    </Spinner>
                                </div>

                                :
                                clientList?.data?.length !== 0 ?

                                    (clientList?.data)?.map((item, index) => (

                                        <Col lg={12} key={index} className='mt-0'>
                                            <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                                <CardBody className="px-2 py-0">
                                                    <div className="d-lg-flex align-items-center row px-2">
                                                        <div className="col-6 col-md-2 px-1" >
                                                            <a href="#!" > <span onClick={() => { handleViewClick(item); }}> {item.name}</span> </a>
                                                        </div>


                                                        <div className="col-6 col-md-2 px-1 px-md-2 d-flex justify-content-end justify-content-md-start">
                                                            <p className="mb-0"> {item.contact_name}  </p>
                                                        </div>

                                                        <div className="col-12 col-md-3 px-1 px-md-2">
                                                            <p className="mb-0">  {item.contact_email}  </p>
                                                        </div>

                                                        <div className="col-5 col-md-2 px-1 px-md-2 d-flex ">
                                                            <p className="mb-0"> {item.contact_mobile}  </p>
                                                        </div>
                                                        <div className="col-3 col-md-2 px-1 px-md-3">
                                                            {item.status === 1 ?
                                                                <Badge color="success-subtle text-success" >Active</Badge>
                                                                :
                                                                <Badge color="danger-subtle text-danger"> Inactive</Badge>
                                                            }
                                                        </div>

                                                        <div className="col-4 col-md-1 d-flex gap-0 align-items-center justify-content-end px-0 px-md-2 ">
                                                            <button type="button" className="btn btn-sm btn-icon btn25"
                                                                onClick={(e) => { handleEditClick(item); }}>
                                                                <i className="ri-pencil-fill fs-18 primary" ></i>
                                                            </button>
                                                            <button type="button" className="btn btn-sm btn-icon btn25"
                                                                onClick={(e) => { onClickData(item); setClientView(item); }}>
                                                                <i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    ))

                                    :
                                    <div className="noresult" >
                                        <div className="text-center">
                                            <h6 className="mt-2">Sorry! No Result Found</h6>
                                        </div>
                                    </div>
                        }
                    </Row>

                    <div className=" d-flex justify-content-end pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getClientPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={clientList?.total}>All</option>
                            </select>


                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={clientList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />

                        </div>

                    </div>


                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}  >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }} >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{view ? "View Client" : !isEdit ? "Add Client" : "Update Client"}</span>
                                <span>
                                    {!view ? <span className="fs-18 px-1 text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>  <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                                    <span className="fs-18 text-color-white cursor" onClick={() => { toggleDrawer() }}>  <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">

                                    {view ? <>
                                        <Row>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Company Name </Label> <br />
                                                    {clientView?.name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Logo </Label> <br />
                                                    {clientView?.logo ?

                                                        <img src={url + clientView?.logo} alt="" className="rounded-circle avatar-sm" />
                                                        : ""
                                                        // <div className="avatar-sm">
                                                        //     <div className="avatar-title rounded-circle bg-light text-primary">
                                                        //         {clientView?.name?.slice(0, 1)}
                                                        //     </div>
                                                        // </div>
                                                    }
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Contact Name </Label> <br />
                                                    {clientView?.contact_name}
                                                </div>
                                            </Col>



                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Contact Email </Label> <br />
                                                    {clientView?.contact_email}
                                                </div>



                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Contact Mobile </Label>
                                                    <br />
                                                    {clientView?.contact_mobile}
                                                </div>

                                            </Col>



                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Contact Person Designation </Label>
                                                    <br />


                                                    {clientView?.contact_person_designation}
                                                </div>

                                            </Col>



                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Contact Address </Label>
                                                    <br />
                                                    {clientView?.contact_address}
                                                </div>

                                            </Col>




                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Description </Label>
                                                    <br />


                                                    {clientView?.description}
                                                </div>

                                            </Col>



                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Status </Label>
                                                    <br />



                                                    {clientView?.status === 1 ?

                                                        <Badge color="success-subtle text-success" >Active</Badge>
                                                        :
                                                        <Badge color="danger-subtle text-danger"> Inactive  </Badge>

                                                    }

                                                </div>

                                            </Col>

                                        </Row>
                                    </> :
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="name" className="form-label">Company Name <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                                                            name='name'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.name || ""}
                                                            invalid={submit && validation.errors.name ? true : false}

                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="contact_name" className="form-label">Contact Name <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="contact_name" placeholder="Enter contact name"
                                                            name='contact_name'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.contact_name || ""}
                                                            invalid={submit && validation.errors.contact_name ? true : false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="contact_email" className="form-label">Contact Email <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="contact_email" placeholder="Enter contact email"
                                                            name='contact_email'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.contact_email || ""}
                                                            invalid={submit && validation.errors.contact_email ? true : false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="contact_mobile" className="form-label">Contact Mobile <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="contact_mobile" placeholder="Enter contact mobile"
                                                            name='contact_mobile'
                                                            onChange={(e) => {
                                                                let inputValue = e.target.value;
                                                                if (inputValue.length > 10) {
                                                                    inputValue = inputValue.slice(0, 10);
                                                                }
                                                                validation.handleChange({
                                                                    target: {
                                                                        name: 'contact_mobile',
                                                                        value: inputValue,
                                                                    },
                                                                });
                                                            }
                                                            }
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.contact_mobile || ""}
                                                            invalid={submit && validation.errors.contact_mobile ? true : false}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="contact_person_designation" className="form-label">Contact Person Designation  </Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="contact_person_designation" placeholder="Enter contact person designation"
                                                            name='contact_person_designation'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.contact_person_designation || ""}
                                                            invalid={submit && validation.errors.contact_person_designation ? true : false}
                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="contact_address" className="form-label">Contact Address </Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="contact_address" placeholder="Enter contact address"
                                                            name='contact_address'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.contact_address || ""}
                                                            invalid={submit && validation.errors.contact_address ? true : false}
                                                        />

                                                    </div>

                                                </Col>

                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="description" className="form-label">Description</Label>
                                                        <Input type="textarea" className="form-control px-2 py-1" id="description" placeholder="Enter description"
                                                            name='description'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.description || ""}
                                                            invalid={submit && validation.errors.description ? true : false}
                                                            maxLength={250}
                                                            multiline="true"

                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="logo" className="form-label">Logo </Label>
                                                        <Input type="file" accept="image/*" className="form-control px-2 py-1" id="logo"
                                                            name='logo'
                                                            onChange={handleLogoChange}
                                                        />

                                                    </div>

                                                    <Row className='py-2'>


                                                        {(files || [])?.map((item, index) => {

                                                            return [
                                                                <Col xs={6} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                                                    <FaTimesCircle className="close-button" onClick={(e) => {
                                                                        setFiles([]);
                                                                        setFile([]);
                                                                    }} />
                                                                    <div className="selected-image cursor" key={index} onClick={(e) => {
                                                                        setModalLogo(true); setFileUrl({ logo: true, name: item.name, path: item.preview });
                                                                    }}>
                                                                        <img
                                                                            src={item.preview}
                                                                            alt={item.name}
                                                                            width={100}
                                                                            height={80}
                                                                        />
                                                                    </div>
                                                                </Col>

                                                            ]
                                                        })}

                                                        {validation.values.logo &&
                                                            <Col xs={6} className='text-center p-2' >

                                                                <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                                                    handleRemoveLogo(validation.values.id);
                                                                }} />

                                                                <div className="selected-image cursor d-flex text-center" onClick={(e) => {
                                                                    setModalLogo(true); setFileUrl({ logo: true, name: "", path: url + validation.values.logo, ext: url + validation.values.logo });
                                                                }}>
                                                                    <img

                                                                        src={url + validation.values.logo}
                                                                        alt={validation.values.logo}
                                                                        width={100}
                                                                        height={80}


                                                                    />

                                                                </div>
                                                            </Col>
                                                        }


                                                    </Row>
                                                </Col>

                                                <Col xs={6}>

                                                    <div className="mb-2">
                                                        <Label htmlFor="roleName" className="form-label">Status</Label>

                                                        <div className="form-check form-switch form-switch-left form-switch-md">
                                                            <Input
                                                                type="switch"
                                                                id="status_id"
                                                                name="status"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                defaultValue={validation.values.status}
                                                                checked={validation.values.status}
                                                            />

                                                            {validation.values.status ? "Active" : "Inactive"}

                                                        </div>

                                                    </div>

                                                </Col>





                                                <Col lg={12}  >
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="submit" className="btn btn-primary shadow-lg"
                                                            disabled={validation.isSubmitting ? true : false}
                                                            onClick={() => {
                                                                setSubmit(true);
                                                            }}>
                                                            {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                            {!isEdit ? "Add Client" : "Update Client"}</button>
                                                    </div>
                                                </Col>



                                                <div className="modal fade" tabIndex="-1" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <Modal size="lg" isOpen={modalLogo} toggle={() => {
                                                            setModalLogo(false);
                                                        }} centered>
                                                            <ModalHeader className='bpBottom pb-2' toggle={() => {
                                                                setModalLogo(false);
                                                            }}  >
                                                                {"View Logo"}
                                                            </ModalHeader>
                                                            <ModalBody >
                                                                {modalLogo && <>

                                                                    <div className='text-center py-4' >
                                                                        <h5  >{fileUrl.name}</h5>
                                                                        <img
                                                                            src={fileUrl.path}
                                                                            alt={fileUrl.name}
                                                                            width={isDesktop ? 500 : "100%"}
                                                                            height={"100%"}
                                                                        />
                                                                    </div>
                                                                </>
                                                                }

                                                                <div className='pt-2 text-center' >
                                                                    <button className="btn btn-primary shadow-lg">
                                                                        <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download> Download</a>
                                                                    </button>
                                                                </div>
                                                            </ModalBody>
                                                        </Modal>
                                                    </div>
                                                </div>

                                            </Row>
                                        </Form>

                                    }

                                </div>
                            </SimpleBar>

                        </OffcanvasBody>

                    </Offcanvas>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default Clients;