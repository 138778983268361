import React, { useState, useEffect, useCallback, } from 'react';
import { Col, Card, CardBody, Container, Row, Spinner, } from "reactstrap";
import CountUp from "react-countup";
//import { useSelector, useDispatch } from "react-redux"; 
//import { createSelector } from "reselect";
//import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from 'react-router-dom';
import SimpleBar from "simplebar-react";
import { api, url } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Clock from 'react-clock';
import ReactApexChart from "react-apexcharts";
import Calender from './index';
import UpcommingEvents from './Events';
import { TimeDifference, SumTimeDifference, DefaultDate, DefaultDateWithTime, DefaultDateWithDay, FormatCount } from "../../Components/Hooks/Function";
import { useMediaQuery } from 'react-responsive';
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import 'react-clock/dist/Clock.css';
let intervalId = null;

const Dashboard = (props) => {
    document.title = "Dashboard";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const userDetails = getLoggedinUser()?.user;
    var moment = require('moment-timezone');
    const [employeeCount, setEmployeeCount] = useState(0);
    const [expenseCount, setExpenseCount] = useState([]);
    const [assetCount, setAssetCount] = useState(0);
    const [workHourCount, setWorkHourCount] = useState([]);
    const [EODList, setEODList] = useState([]);
    const [BlogList, setBlogList] = useState([]);
    //  const [userDetails, setUserDetails] = useState([]);
    const [userStatusList, setUserStatusList] = useState([]);
    const [punchTime, setPunchTime] = useState([]);
    const [noteList, setNoteList] = useState([]);
    const [holidayList, setHolidayList] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());

    function timeStringToSeconds(timeString) {
        if (timeString) {
            const [hours, minutes, seconds] = timeString?.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        }
    }

    function secondsToTimeString(seconds) {
        if (seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        }
    }

    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    const [loading, setLoading] = useState(false);
    const [leaveCount, setLeaveCount] = useState([]);
    const [punchInCount, setPunchInCount] = useState(0);
    const [punchOutCount, setPunchOutCount] = useState(0);
    // const [leaveList, setLeaveList] = useState([]);

    const calculateTotalSumHours = (row) => {
        const data = row?.reduce((accumulator, currentArray) => [...accumulator, ...currentArray], []);
        const totalMilliseconds = data?.length > 0
            ? (data || []).reduce((sum, item) =>
                sum + moment.duration(TimeDifference(item)).asMilliseconds(), 0)
            : 0;
        const totalDuration = moment.duration(totalMilliseconds);
        const totalHours = Math.floor(totalDuration.asHours());
        const totalMinutes = totalDuration.minutes();
        const totalSeconds = totalDuration.seconds();
        return `${totalHours < 10 ? '0' : ' '}${totalHours}:${totalMinutes < 10 ? '0' : ''}${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;
    };

    const history = useNavigate();

    const getSettings = async () => {
        setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    };

    const getExpenseCount = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`expenseCount`, {});
            if (response.data.status === true) {
                setExpenseCount(response.data?.data);
            } else {
                setExpenseCount([]);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const getAssetCount = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`assetCount`, {});
            if (response.data.status === true) {
                setAssetCount(response.data?.data);
            } else {
                setAssetCount(0);
            }

        } catch (err) {
            console.error(err.message);
        }
    };


    const getEmployeeCount = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`employeeCount`, {});
            if (response.data.status === true) {
                setEmployeeCount(response.data?.data);
            } else {
                setEmployeeCount(0);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getWorkHourCount = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`workHourCount`, {});
            if (response.data.status === true) {
                setWorkHourCount(response.data?.data);
            } else {
                setWorkHourCount([]);
            }

            setLoading(false);

        } catch (err) {
            console.error(err.message);
        }
    };

    const getPunchInCount = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`punchInCount`, {});
            if (response.data.status === true) {
                setPunchInCount(response.data?.punchInCount);
                setPunchOutCount(response.data?.punchOutCount);
            } else {
                setPunchInCount(0);
                setPunchOutCount(0);
            }
        } catch (err) {
            console.error(err.message);
        }

    };

    const getHoliday = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`holiday?year=${new Date().getFullYear()}`, {});
            if (response.data.status === true) {
                setHolidayList(response.data?.data);
            } else {
                setHolidayList([]);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const getUserPunchTime = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`userPunchTime`, {});
            if (response.data.status === true) {
                setPunchTime(response.data?.data);
            } else {
                setPunchTime([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getUserLeaveCount = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`userLeaveCount`, {});
            if (response.data.status === true) {
                setLeaveCount(response.data?.data);
            } else {
                setLeaveCount(0);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getEODReport = async () => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`endOfDayReport`, {
                params: {
                    page: 1,
                    perPage: 5
                }
            });

            if (response.data.status === true) {
                setEODList(response.data?.data);

            } else {
                setEODList([]);

            }

        } catch (err) {
            console.error(err.message);
        }
    };

    // const getLeave = async () => {

    //     api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    //     api.defaults.headers.common['Content-Type'] = 'application/json'
    //     try {
    //         const response = await api.get(`leave`,
    //             {
    //                 params: {
    //                     startDate: `${moment().format('YYYY-MM-DD')}`,
    //                     endDate: `${moment().format('YYYY-MM-DD')}`,
    //                     page: 1,
    //                     perPage: 50
    //                 }
    //             }
    //         );

    //         if (response.data.status === true) {

    //             setLeaveList(response.data?.data);
    //         } else {

    //             setLeaveList([]);
    //             ToastMessage("error", response.data?.message);
    //         }


    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // };

    const getBlog = async () => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';

        try {
            const response = await api.get(`blogList`, {});

            if (response.data.status === true) {

                setBlogList(response.data.data);

            } else {

                setBlogList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const getAllNote = async () => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';

        try {
            const response = await api.get(`note`, { params: { page: 1, rowPerPage: 5 } });

            if (response.data.status === true) {

                setNoteList(response.data.data);

            } else {

                setNoteList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    useEffect(() => {
        intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleHoverEnter = (event, chartContext, config) => {
        clearInterval(intervalId);
    };

    const handleHoverLeave = (event, chartContext, config) => {
        intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
    };

    const workHourChart = {
        options: {
            chart: {
                type: "bar",
                width: "100%",
                events: {
                    dataPointMouseEnter: handleHoverEnter,
                    dataPointMouseLeave: handleHoverLeave
                },
                toolbar: {
                    show: !1,
                }
            },
            plotOptions: {
                bar: {
                    vertical: !0,
                    fill: {
                        type: 'solid',
                        colors: function ({ value, seriesIndex, dataPointIndex }) {
                            const date = workHourCount[dataPointIndex]?.date;
                            return new Date(date).getDay() === 0 ? '#FF0000' : undefined;
                        }
                    }
                }
            },
            dataLabels: {
                enabled: !1,
            },
            grid: {
                borderColor: "#f1f1f1",
            },
            xaxis: {
                type: "datetime",
                categories: Array.isArray(workHourCount) ? workHourCount?.map((value) => value?.date) : [],
                labels: {
                    datetimeUTC: false,
                    format: selectedCountry === 'US' ? "dd" : "dd",
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        const hours = Math.floor(value / 3600);
                        const minutes = Math.floor((value % 3600) / 60);
                        const seconds = Math.round(value % 60);
                        const formattedHours = hours.toString().padStart(2, '0');
                        const formattedMinutes = minutes.toString().padStart(2, '0');
                        const formattedSeconds = seconds.toString().padStart(2, '0');
                        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                    },
                },
            },
            tooltip: {
                enabled: true,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const data = series[seriesIndex][dataPointIndex];
                    const date = workHourCount[dataPointIndex].date;
                    const totalHours = workHourCount[dataPointIndex]?.totalHours;
                    const isSunday = new Date(date).getDay() === 0;
                    const holiday = holidayList.some(holiday => holiday.date === date);
                    const holidayName = holidayList.filter(holiday => holiday.date === date);


                    let tooltipDate = `<b>Date:</b> ${DefaultDate(date, selectedCountry)}`;
                    let tooltipText = "";

                    if (isSunday || holiday) {
                        tooltipText = `<b>Holiday:</b> ${holiday ? holidayName?.[0]?.title : "Sunday"} `;
                    } else if (totalHours?.length === 0) {
                        tooltipText = `<b>Leave:</b>  Leave `;
                    }
                    else {
                        tooltipText = `<b>Working Hours:</b> ${secondsToTimeString(data)}`;
                    }

                    return (
                        "<div class='w-100 p-2'>" +
                        "<span class='py-2 pb-0  px-0'>" + tooltipDate + "</span> <br/>" +
                        "<span class='px-0 py-0'>" + tooltipText + "</span>" +
                        "</div>"
                    );
                }
            },
            colors: [
                function ({ series, seriesIndex, dataPointIndex, w }) {
                    const date = workHourCount[dataPointIndex]?.date;
                    const totalHours = workHourCount[dataPointIndex]?.totalHours;
                    const isSunday = new Date(date).getDay() === 0;
                    const holiday = holidayList.some(holiday => holiday?.date === date);
                    let tooltipText = "";
                    if (isSunday || holiday) {
                        tooltipText = '#f06548';
                    } else if (totalHours?.length === 0) {

                        tooltipText = '#f06548';
                    } else {
                        tooltipText = `#019aff`;
                    }
                    return tooltipText;
                }
            ]
        },
        series: [{
            data: Array.isArray(workHourCount) ? workHourCount?.filter(item => item?.date <= moment().tz(moment.tz.guess()).format('YYYY-MM-DD'))?.map((value) => {
                const isSunday = new Date(value.date).getDay() === 0;
                const holiday = holidayList.some(date => date?.date === value.date);
                if (isSunday || holiday) {
                    return "28800";
                } else if (!isSunday && !holiday) {
                    return "28800";
                } else {
                    return (timeStringToSeconds(SumTimeDifference(value?.totalHours?.filter(type => type?.is_break === 0))) >
                        timeStringToSeconds(SumTimeDifference(value?.totalHours?.filter(type => type?.is_break === 1))) ?
                        timeStringToSeconds(SumTimeDifference(value?.totalHours?.filter(type => type?.is_break === 0))) -
                        timeStringToSeconds(SumTimeDifference(value?.totalHours?.filter(type => type?.is_break === 1)))
                        : 0);
                }
            }) : [],
        }],
    };

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const filteredUpcomingEvents = holidayList.filter(event => {
        const eventDate = new Date(event.date);
        return eventDate > yesterday;
    });

    const getUserStatusCount = async (year, month) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`userStatusCount`,
                {
                    params: {
                        year: year,
                        month: month
                    }
                }
            );

            if (response.data.status === true) {
                setUserStatusList(response.data?.data);
            } else {
                setUserStatusList([]);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getHoliday();
        getSettings();
        if (userDetails?.roles?.[0]?.name === "Admin") {
            getExpenseCount();
            getAssetCount();
            getEmployeeCount();
            getPunchInCount();
            getUserStatusCount(new Date().getFullYear(), new Date().toLocaleString('en-US', { month: '2-digit' }));
        } else {
            getUserPunchTime();
            getUserLeaveCount();
            getWorkHourCount();
            getEODReport();
            //  getLeave();
            getBlog();
            const workingHourChange = () => {
                getWorkHourCount();
            };
            getAllNote();
            window.addEventListener("workingHourChange", workingHourChange);
        }
    }, []);

    const combinedEvents = [...filteredUpcomingEvents].sort((a, b) => new Date(a.date) - new Date(b.date));

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold  px-3 px-md-0" >Dashboard  </h4>
                            </div>
                        </Col>
                    </Row>

                    {userDetails?.roles?.[0]?.name === "Admin" &&
                        <>
                            {/* {!loading ? <> */}
                            <Row>
                                <Col xs={12} md={10}>
                                    <Row>
                                        <Col xs={12}>
                                            <Row>
                                                <Col className='w-20'    >
                                                    <Card className="card-animate shadow cursor" onClick={(e) => {
                                                        history('/attendance');
                                                    }}>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">Punch In</p>
                                                                    <h4 className="mt-4  fs-20 ff-secondary fw-semibold">
                                                                        <span className="counter-value">
                                                                            <CountUp
                                                                                start={0}
                                                                                separator={","}
                                                                                end={punchInCount}
                                                                                decimals={FormatCount(punchInCount, selectedCountry)}
                                                                                duration={1}
                                                                            />
                                                                        </span></h4>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-xs flex-shrink-0">
                                                                        <span className="avatar-title bg-success rounded-circle fs-18">
                                                                            <i className="ri-calendar-check-fill"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col className='w-20'       >
                                                    <Card className="card-animate shadow cursor" onClick={(e) => {
                                                        history('/attendance');
                                                    }}>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">Punch Out</p>
                                                                    <h4 className="mt-4 fs-20 ff-secondary fw-semibold">
                                                                        <span className="counter-value">
                                                                            <CountUp
                                                                                start={0}
                                                                                separator={","}
                                                                                end={punchOutCount}
                                                                                decimals={FormatCount(punchOutCount, selectedCountry)}
                                                                                duration={1}
                                                                            />
                                                                        </span></h4>

                                                                </div>
                                                                <div>
                                                                    <div className="avatar-xs flex-shrink-0">
                                                                        <span className="avatar-title bg-danger rounded-circle fs-18">
                                                                            {/* <FeatherIcon  icon="users"    className="text-white"  /> */}
                                                                            <i className="ri-calendar-check-fill"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col className='w-20'     >
                                                    <Card className="card-animate shadow cursor" onClick={(e) => {
                                                        history('/employees');
                                                    }}>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">Employees</p>
                                                                    <h4 className="mt-4 fs-20 ff-secondary fw-semibold">
                                                                        <span className="counter-value">
                                                                            <CountUp
                                                                                start={0}
                                                                                separator={","}
                                                                                end={employeeCount}
                                                                                decimals={FormatCount(employeeCount, selectedCountry)}
                                                                                duration={1}
                                                                            />
                                                                        </span></h4>

                                                                </div>
                                                                <div>
                                                                    <div className="avatar-xs flex-shrink-0">
                                                                        <span className="avatar-title bg-primary rounded-circle fs-18">
                                                                            {/* <FeatherIcon   icon="users" className="text-white"  /> */}
                                                                            <i className="ri-user-2-fill"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col className='w-20'   >
                                                    <Card className="card-animate shadow cursor" onClick={(e) => {
                                                        history('/assets');
                                                    }}>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">Assets</p>
                                                                    <h4 className="mt-4 fs-20 ff-secondary fw-semibold">
                                                                        <span className="counter-value">
                                                                            <CountUp
                                                                                start={0}
                                                                                separator={","}
                                                                                end={assetCount}
                                                                                decimals={FormatCount(assetCount, selectedCountry)}
                                                                                duration={1}
                                                                            />
                                                                        </span></h4>

                                                                </div>
                                                                <div>
                                                                    <div className="avatar-xs flex-shrink-0">
                                                                        <span className="avatar-title bg-warning rounded-circle fs-18">
                                                                            {/* <FeatherIcon  icon="users"   className="text-white"  /> */}
                                                                            <i className="ri-tv-2-fill"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col className='w-20'       >
                                                    <Card className="card-animate shadow cursor" onClick={(e) => {
                                                        history('/expenses');
                                                    }}>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">Expenses</p>
                                                                    <h4 className="fs-20 mt-4 ff-secondary fw-semibold">
                                                                        <span className="counter-value text-break">
                                                                            <CountUp
                                                                                start={0}
                                                                                separator={","}
                                                                                end={expenseCount}
                                                                                decimals={FormatCount(expenseCount, selectedCountry)}
                                                                                duration={1}
                                                                            />
                                                                        </span></h4>

                                                                </div>
                                                                <div>
                                                                    <div className="avatar-xs flex-shrink-0">
                                                                        <span className="avatar-title  rounded-circle fs-18" style={{ backgroundColor: "#4b38b3" }}>
                                                                            {/* <FeatherIcon    icon="users"     className="text-white"   /> */}

                                                                            <i className="ri-hand-coin-fill"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Calender getUserStatusCount={getUserStatusCount} userStatusList={userStatusList} selectedCountry={selectedCountry} />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12} md={2}>
                                    <Card className="card">
                                        <CardBody className='p-2 mt-2'>
                                            <div id="external-events">
                                                <div>
                                                    <h5 className="mb-2 fs-14 fw-bold">Upcoming Holidays</h5>

                                                    <SimpleBar className="pe-2 me-n1 mb-3" style={{ height: "119vh" }} >
                                                        <div id="upcoming-event-list">
                                                            {combinedEvents.map((event, key) => (
                                                                <UpcommingEvents
                                                                    event={event}
                                                                    key={key}
                                                                    DefaultDate={DefaultDate}
                                                                    selectedCountry={selectedCountry}
                                                                />
                                                            ))}
                                                        </div>
                                                    </SimpleBar>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* </>
                                : <div className='text-center'>
                                    <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                        Loading...
                                    </Spinner>
                                </div>

                            }
*/} </>
                    }
                    {userDetails?.roles?.[0]?.name !== "Admin" &&
                        <Row>
                            <Col>
                                <div className="h-100">
                                    <Row>
                                        {!loading ? <>
                                            <Col xs={12}  >
                                                <Row>
                                                    <Col xs={12} md={3}    >
                                                        <Card className="card-animate shadow  cursor" onClick={(e) => {
                                                            history('/attendance');
                                                        }}>
                                                            <CardBody className='border  py-5 ' >
                                                                <div className="d-flex align-items-center ">
                                                                    <Row>
                                                                        <Col xs={12} className='text-center'>
                                                                            <div className='d-flex justify-content-center'>
                                                                                <Clock value={currentTime} />
                                                                            </div> <br />
                                                                            <p className='text-primary'>     {DefaultDateWithDay(punchTime.date, selectedCountry)} </p> </Col>

                                                                        <Col xs={6}  >
                                                                            <p className='text-center'>
                                                                                Punch In At <br />
                                                                                <span className='text-success'>  {punchTime?.punchInTime?.start_time || "-"} </span></p>
                                                                        </Col>
                                                                        <Col xs={6}  >
                                                                            <p className='text-center'>
                                                                                Punch In Out <br />
                                                                                <span className='text-danger'> {punchTime?.punchOutTime?.end_time || "-"}</span> </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                    <Col xs={12} md={4} >
                                                        <Card className="card-animate shadow cursor" onClick={(e) => { history('/leave'); }}>
                                                            <CardBody className='border py-3' >
                                                                <h6 className='mb-2 font-weight-bold'> {"Leaves"}</h6>
                                                                <Card className='card-animate cursor mb-2'>
                                                                    <CardBody className='px-2 py-2'>
                                                                        <p className="mb-0">Total Leave <span className="float-end"> {leaveCount.total_count}</span></p>
                                                                    </CardBody>
                                                                </Card>

                                                                <Card className='card-animate cursor mb-2'  >
                                                                    <CardBody className='px-2 py-2'>
                                                                        <p className="mt-0 mb-0">Casual Leave <span className="float-end">  {leaveCount.cl_count + " / " + (leaveCount?.leave_type?.filter(item => item?.code === "CL")?.[0]?.monthly_allowed || 0)}</span></p>
                                                                        <div className="progress mt-2" style={{ height: "6px" }}>
                                                                            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                                                                style={{ width: `${(leaveCount.cl_count / leaveCount?.leave_type?.filter(item => item.code === "CL")?.[0]?.monthly_allowed) * 100}%` }}
                                                                                aria-valuenow={leaveCount.cl_count / leaveCount?.leave_type?.filter(item => item.code === "CL")?.[0]?.monthly_allowed * 100}
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100">
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>

                                                                <Card className='card-animate  cursor mb-2'  >
                                                                    <CardBody className='px-2 py-2 '>
                                                                        <p className="mt-0 mb-0">Earned Leave <span className="float-end">{leaveCount.el_count + " / " + (leaveCount?.leave_type?.filter(item => item?.code === "EL")?.[0]?.monthly_allowed || 0)}</span></p>
                                                                        <div className="progress mt-2" style={{ height: "6px" }}>
                                                                            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                                                                style={{ width: `${(leaveCount.el_count / leaveCount?.leave_type?.filter(item => item.code === "EL")?.[0]?.monthly_allowed) * 100}%` }}
                                                                                aria-valuenow={leaveCount.el_count / leaveCount?.leave_type?.filter(item => item.code === "EL")?.[0]?.monthly_allowed * 100}
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100">
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>


                                                                <Card className='card-animate  cursor mb-2'  >
                                                                    <CardBody className='px-2 py-2 '>
                                                                        <p className="mt-0 mb-0">Loss of Pay <span className="float-end">{leaveCount.lop_count + " / " + leaveCount.total_count} </span></p>
                                                                        <div className="progress mt-2" style={{ height: "6px" }}>
                                                                            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                                                                style={{ width: `${(leaveCount.lop_count / leaveCount.total_count) * 100}%` }}
                                                                                aria-valuenow={leaveCount.lop_count / leaveCount.total_count * 100}
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100">
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                                <Card className='card-animate  cursor mb-2' >
                                                                    <CardBody className='px-2 py-2 '>
                                                                        <p className="mt-0 mb-0">Pending Leave <span className="float-end">{leaveCount.pending_count + " / " + leaveCount.total_count}</span></p>
                                                                        <div className="progress mt-2" style={{ height: "6px" }}>
                                                                            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                                                                style={{ width: `${(leaveCount.pending_count / leaveCount.total_count) * 100}%` }}
                                                                                aria-valuenow={leaveCount.pending_count / leaveCount.total_count * 100}
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100">

                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} md={5}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Card className="card-animate mb-2 shadow cursor" onClick={(e) => { history('/attendance'); }}>
                                                                    <CardBody className='border px-0 py-0'>
                                                                        <h6 className='mb-0 mt-2 mb-2 mb-md-0 px-2 font-weight-bold'>Total Working Hours</h6>
                                                                        <Card className='mt-2 text-center mx-3'>
                                                                            <CardBody>
                                                                                <p className='mb-0'>
                                                                                    <span className='mb-0 fs-24'>
                                                                                        {timeStringToSeconds(calculateTotalSumHours(
                                                                                            workHourCount?.map((item) => {
                                                                                                const isSunday = new Date(item.start_date).getDay() === 0;
                                                                                                const holiday = holidayList.some(date => date?.date === item.start_date);
                                                                                                return (!isSunday && !holiday ? item.totalHours?.filter(type => type?.is_break === 0) : [])
                                                                                            })
                                                                                        )) >
                                                                                            timeStringToSeconds(calculateTotalSumHours(
                                                                                                workHourCount?.map((item) => {
                                                                                                    const isSunday = new Date(item.start_date).getDay() === 0;
                                                                                                    const holiday = holidayList.some(date => date?.date === item.start_date);
                                                                                                    return (!isSunday && !holiday ? item.totalHours?.filter(type => type?.is_break === 1) : [])
                                                                                                })
                                                                                            )) ?

                                                                                            secondsToTimeString((timeStringToSeconds(calculateTotalSumHours(
                                                                                                workHourCount?.map((item) => {
                                                                                                    const isSunday = new Date(item.start_date).getDay() === 0;
                                                                                                    const holiday = holidayList.some(date => date?.date === item.start_date);
                                                                                                    return (!isSunday && !holiday ? item.totalHours?.filter(type => type?.is_break === 0) : [])
                                                                                                })
                                                                                            ))
                                                                                                -
                                                                                                timeStringToSeconds(calculateTotalSumHours(
                                                                                                    workHourCount?.map((item) => {
                                                                                                        const isSunday = new Date(item.start_date).getDay() === 0;
                                                                                                        const holiday = holidayList.some(date => date?.date === item.start_date);
                                                                                                        return (!isSunday && !holiday ? item.totalHours?.filter(type => type?.is_break === 1) : [])
                                                                                                    })
                                                                                                ))))

                                                                                            : "00:00:00"
                                                                                        }
                                                                                    </span>
                                                                                    <span> {" hrs"}</span>
                                                                                </p>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col >

                                                            <Col xs={12} >
                                                                <Card className="card-animate mb-2 shadow cursor" onClick={(e) => { history('/attendance'); }}>
                                                                    <CardBody className='border px-0 py-0'  >
                                                                        <h6 className='mb-0 mt-2 px-2 font-weight-bold'>{"Working Hours"}</h6>
                                                                        <ReactApexChart
                                                                            options={workHourChart?.options}
                                                                            series={workHourChart?.series}
                                                                            type="bar"
                                                                            height="180"
                                                                            width={isDesktop ? "100%" : "300"}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col >
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12}  >
                                                <Row>
                                                    <Col xs={12} md={3} >
                                                        <Card className="shadow h-100" >
                                                            <CardBody className='border py-3' >
                                                                <h6 className='mb-0 font-weight-bold'> {"Holiday"}</h6>
                                                                <Row>
                                                                    <Col lg={12}   >
                                                                        {holidayList?.length !== 0 ?
                                                                            (holidayList)?.slice(0, 9)?.map((item, index) => (
                                                                                <Card key={index} className="card-animate cursor my-2" onClick={(e) => { history("/holidays"); }}>
                                                                                    <CardBody className="p-2">
                                                                                        <div className="d-lg-flex align-items-center row">
                                                                                            <div className="col-6 col-md-7">
                                                                                                {item.title}
                                                                                            </div>
                                                                                        </div>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            ))
                                                                            : <Card className="card-animate cursor my-2" onClick={(e) => { history("/holidays"); }}>
                                                                                <CardBody className="p-2"> </CardBody>
                                                                            </Card>}
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>


                                                    </Col>
                                                    <Col xs={12} md={4} >
                                                        <Card className="shadow h-100" >
                                                            <CardBody className='border py-3'>
                                                                <div className='row'>
                                                                    <div className='col-12 border-1 pb-2 border-bottom' >
                                                                        <h6 className='mb-0 font-weight-bold'> {"Recent work"}</h6>
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                {(EODList?.data)?.length !== 0 ?
                                                                                    (EODList?.data)?.slice(0, 1)?.map((item, index) => (
                                                                                        <Card key={index} className="card-animate my-2 cursor" onClick={(e) => { history("/eodReport"); }}>
                                                                                            <CardBody className="p-2">
                                                                                                <div dangerouslySetInnerHTML={{ __html: item.today_activity.substring(0, 35) + "...." }} className='text_html d-flex mb-2' />
                                                                                                <ul className="list-inline mb-0">
                                                                                                    <li className="list-inline-item text-secondary"><i className="ri-calendar-2-fill text-secondary align-middle me-1"></i> {DefaultDate(item.date)}</li>
                                                                                                </ul>
                                                                                            </CardBody>
                                                                                        </Card>
                                                                                    )) :
                                                                                    <Card className="card-animate my-2 cursor" onClick={(e) => { history("/eodReport"); }}>
                                                                                        <CardBody className="p-2" >  </CardBody>
                                                                                    </Card>
                                                                                }
                                                                            </Col>

                                                                        </Row>

                                                                    </div>
                                                                    <div className='col-12 mt-2 ' >
                                                                        <h6 className='mb-0 mt-2 font-weight-bold'> {"Notes"}</h6>
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                {(noteList?.data)?.length !== 0 ?
                                                                                    (noteList?.data)?.slice(0, 1)?.map((item, index) => (
                                                                                        <Card key={index} className="card-animate my-2 cursor" onClick={(e) => { history("/notes"); }}>
                                                                                            <CardBody className="p-2">

                                                                                                <div dangerouslySetInnerHTML={{ __html: item.note.substring(0, 35) + "...." }} className='text_html d-flex mb-2' />

                                                                                                <ul className="list-inline mb-0">
                                                                                                    <li className="list-inline-item text-secondary"><i className="ri-calendar-2-fill text-secondary align-middle me-1"></i> {DefaultDate(item.date)}</li>
                                                                                                </ul>

                                                                                            </CardBody>
                                                                                        </Card>
                                                                                    ))
                                                                                    :
                                                                                    <Card className="card-animate my-2 cursor" onClick={(e) => { history("/notes"); }}>
                                                                                        <CardBody className="p-2"> </CardBody>
                                                                                    </Card>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} md={5} >
                                                        <Card className="shadow cursor h-100" >
                                                            <CardBody className='border py-3'>
                                                                <h6 className='mb-0 font-weight-bold'> {"Blog"}</h6>
                                                                <Row>
                                                                    <Col lg={12} >
                                                                        {(BlogList?.data)?.length !== 0 ?
                                                                            (BlogList?.data)?.map((item, index) => {
                                                                                return (
                                                                                    <Card key={index} className="card-animate  border cursor my-2" onClick={(e) => { history("/blogs"); }} >
                                                                                        <CardBody className='border py-1 px-2'>
                                                                                            <div className="d-sm-flex align-items-center">
                                                                                                <div className="flex-shrink-0">
                                                                                                    <img src={url + item.thumbnail} alt="" width={isMobile ? "100%" : "100"} className="rounded-2" />
                                                                                                </div>
                                                                                                <div className="flex-grow-1 ms-sm-4 mt-3 mt-sm-0">
                                                                                                    <ul className="list-inline mb-2">
                                                                                                        <li className="list-inline-item"><span className={`badge bg-dark-subtle fs-11 text-dark`}>{item.blog_category?.name}</span></li>
                                                                                                    </ul>
                                                                                                    <p className='mb-2'><Link to="#" className='text-dark'>{item.title}</Link></p>
                                                                                                    <ul className="list-inline mb-0">
                                                                                                        <li className="list-inline-item text-secondary"><i className="ri-user-3-fill text-secondary align-middle me-1"></i> {item.user?.name}</li>
                                                                                                        <li className="list-inline-item text-secondary"><i className="ri-calendar-2-fill text-secondary align-middle me-1"></i> {DefaultDateWithTime(item.publish)}</li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                );
                                                                            })
                                                                            : <Card className="border cursor my-2" onClick={(e) => { history("/blogs"); }}> <CardBody> </CardBody> </Card>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </>
                                            : <div className='text-center'>
                                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                                    Loading...
                                                </Spinner>
                                            </div>
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row >
                    }
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Dashboard;
