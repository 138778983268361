import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Card, CardBody, Col, Container, Offcanvas,
    OffcanvasHeader, Spinner, OffcanvasBody, Badge,
    Form, Input, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import { FaCheck, FaTimes, FaTimesCircle, FaPlus, } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { DefaultDate, FormatDate, ToastMessage, SmallDateFormat } from "../../Components/Hooks/Function";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import Flatpickr from "react-flatpickr";

const Notes = () => {
    document.title = "Notes";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    const [isExpanded, setIsExpanded] = useState(false);

    const [noteList, setNoteList] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [noteView, setNoteView] = useState(null);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);

    const [searchList, setSearchList] = useState([]);
    const [view, setView] = useState(false);
    const handleAddClick = (arg) => {
        setView(false);
        setSubmit(false);

        validation.resetForm();
        validation.setValues({
            date: DefaultDate("", selectedCountry),
            status: true
        });
        setIsEdit(false);
        toggleDrawer();
    };

    const handleEditClick = useCallback((arg) => {
        setView(false);
        setSubmit(false);
        viewNote(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

    const handleViewClick = (arg) => {
        setView(true);
        viewNote(arg?.id);
        validation.resetForm();
        setIsEdit(false);
    };


    const onClickData = () => {
        setDeleteModal(true);
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getNotePage(e, rowPerPage);
    };

    const keywordSearch = async (e) => {
        let inputVal = e;

        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.description?.toLowerCase().includes(inputVal.toLowerCase()) ||
                (item.status === 1 ? "Active" : "Inactive").toLowerCase().includes(inputVal.toLowerCase())
            );
            setNoteList({
                ...noteList,
                data: filteredResults
            });

        } else {
            setNoteList({
                ...noteList,
                data: searchList?.data
            });

        }
    };


    const [loading, setLoading] = useState(false);

    const getNote = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {

            const response = await api.get(`note`, {});
            if (response.data.status === true) {
                setNoteList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setNoteList([]);
                setSearchList([]);
            }

            setLoading(false);
            setCurrentPage(1);

        } catch (err) {
            console.error(err.message);
        }

    };


    const getNotePage = async (currentPage, rowPerPage) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`note?page=${currentPage}&perPage=${rowPerPage}`);

            if (response.data.status === true) {
                setNoteList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setNoteList([]);
                setSearchList([]);
            }

        } catch (err) {
            console.error(err.message);
        }
    };


    const viewNote = async (id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('note/' + id, {});
            if (response.data.status === true) {
                validation.setValues({
                    ...response.data?.data,
                    date: DefaultDate(response.data?.data?.date, selectedCountry),
                    status: response.data?.data?.status === 1 ? true : false
                });
                setNoteView(response.data?.data);

            } else {
                setNoteView([]);
            }
            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };

    useEffect(() => {
        getNote();
    }, []);


    const addNote = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {

            const response = await api.post('note', {
                ...values,
                status: values?.status ? 1 : 0,
                date: values.date ? FormatDate(values.date, selectedCountry) : "",
            });

            if (response.data.status === true) {
                getNote();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    const editNote = async (values, id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';

        try {
            const response = await api.put('note/' + id, {
                ...values,
                status: values?.status ? 1 : 0,
                date: values.date ? FormatDate(values.date, selectedCountry) : "",
            });

            if (response.data.status === true) {
                getNote();
                setIsEdit(false);
                setOpen(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
            //  setModal(!modal);

        } catch (err) {
            console.error(err.message);
        }

    };

    const deleteNote = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('note/' + value?.id, {});
            if (response.data.status === true) {
                getNote();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }

    };



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            date: '',
            note: '',
            status: '',
        },
        validationSchema: Yup.object({
            date: Yup.string().required("Enter"),
            note: Yup.string().required("Enter"),
            status: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editNote(values, validation.values?.id);
            } else {
                addNote(values);
            }
        },
    });

    const handleToggle = (index) => {
        const newExpandedStates = [...isExpanded];
        newExpandedStates[index] = !newExpandedStates[index];
        setIsExpanded(newExpandedStates);
    };

    useEffect(() => {
        setIsExpanded(noteList?.data?.map(() => false))
    }, [noteList]);


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={noteView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteNote(noteView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Note</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center'>
                        <Col sm={4}> </Col>

                        <Col className="col-sm-auto ms-auto d-flex gap-2">
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => handleAddClick()} >
                                    <i className="ri-add-line"  ></i>  <span className='d-none d-md-block'> Note</span></button>
                                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                        <div className="col-2 px-2">
                                            <h5 className="fs-15 mb-0">Date</h5>
                                        </div>

                                        <div className="col-2 px-2" >
                                            <h5 className="fs-15 mb-0">User</h5>

                                        </div>

                                        <div className="col-5 px-2">
                                            <h5 className="fs-15 mb-0">Notes </h5>
                                        </div>


                                        <div className="col-2 px-3">
                                            <h5 className="fs-15 mb-0"> Status </h5>
                                        </div>

                                        <div className="col-1 d-lg-flex justify-content-end px-2">
                                            <h5 className="fs-15 mb-0">  Action</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                    <Row className="row gy-1 mb-1" id="Note-list">
                        {
                            loading ?
                                <div className='text-center'>
                                    <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                        Loading...
                                    </Spinner>
                                </div>

                                :
                                noteList?.data?.length !== 0 ?

                                    (noteList?.data)?.map((item, index) => (

                                        <Col lg={12} key={index} className='mt-0 '  >
                                            <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                                <CardBody className="px-2 py-0">
                                                    <div className="d-lg-flex align-items-center row px-2">
                                                        <div className="col-6 col-md-2 px-1"  >
                                                            <a href="#!"  > <span onClick={() => { handleViewClick(item); }}>  {DefaultDate(item.date, selectedCountry)}</span>  </a>
                                                        </div>

                                                        <div className="col-6 col-md-2 px-1 px-md-2  d-flex justify-content-end  justify-content-md-start ">
                                                            <p className="  mb-0 ">  {item.user?.name}  </p>
                                                        </div>

                                                        <div className={`${isExpanded?.[index] ? "d-block" : "d-flex"} col-10 col-md-5 gap-4 px-1 px-md-2 align-items-center`}>

                                                            <p className="mb-0">

                                                                {isExpanded?.[index] ? item?.note : item?.note?.length > (isMobile ? 35 : 80) ? item?.note?.slice(0, (isMobile ? 35 : 80)) + "..." : item?.note}
                                                                {item?.note?.length > (isMobile ? 35 : 80) && (
                                                                    <a href="#!" key={index} onClick={() => handleToggle(index)}>
                                                                        {isExpanded?.[index] ? ' Read Less' : ' Read More'}
                                                                    </a>
                                                                )}
                                                            </p>

                                                        </div> 
                                                        <div className="col-6 col-md-2  px-1 px-md-3">
                                                            {item.status === 1 ?
                                                                <Badge color="success-subtle text-success" >Active</Badge>  : <Badge color="danger-subtle text-danger"  > Inactive  </Badge>
                                                            }
                                                        </div>

                                                        <div className="col-6 col-md-1  d-flex gap-0 align-items-center justify-content-end  px-0 px-md-2 ">
                                                            <button type="button" className="btn  btn-sm btn-icon btn25"
                                                                onClick={(e) => { handleEditClick(item); }}>
                                                                <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                            </button>
                                                            <button type="button" className="btn btn-sm btn-icon btn25"
                                                                onClick={(e) => { onClickData(item); setNoteView(item); }}>
                                                                <i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    ))

                                    :
                                    <div className="noresult" >
                                        <div className="text-center">
                                            <h6 className="mt-2">Sorry! No Result Found</h6>
                                        </div>
                                    </div>
                        }
                    </Row>

                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getNotePage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={noteList?.total}>All</option>
                            </select>


                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={noteList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />

                        </div>

                    </div>


                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{view ? "View Note" : !isEdit ? "Add Note" : "Update Note"}</span>
                                <span>
                                    {!view ? <span className="fs-18 px-1 text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">

                                    {view ? <>
                                        <Row>

                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Date  </Label> <br /> 
                                                    {DefaultDate(noteView.date, selectedCountry)}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Description </Label>
                                                    <br />
                                                    {noteView?.note}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Status </Label>
                                                    <br />

                                                    {noteView?.status === 1 ?
                                                        <Badge color="success-subtle text-success" >Active</Badge>
                                                        :
                                                        <Badge color="danger-subtle text-danger"  > Inactive  </Badge>
                                                    }

                                                </div>
                                            </Col>

                                        </Row>
                                    </> :

                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="date" className="form-label">Date <span className='secondary'>*</span></Label>

                                                        <Flatpickr
                                                            name='date_of_birth'
                                                            className={`form-control py-1 px-2 ${submit && validation.errors.date ? 'is-invalid' : ''}`}

                                                            options={{
                                                                allowInput: true,
                                                                dateFormat: SmallDateFormat(selectedCountry),
                                                            }}
                                                            onChange={(selectedDates, dateStr, instance) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    date: dateStr
                                                                });
                                                            }}
                                                            value={validation.values.date || ""}
                                                        />


                                                    </div>

                                                </Col>

                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="note" className="form-label">Note</Label>
                                                        <Input type="textarea" className="form-control px-2 py-1" id="note" placeholder="Enter note"
                                                            name='note'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.note || ""}
                                                            invalid={submit && validation.errors.note ? true : false}
                                                            style={{ height: "200px", minHeight: "200px", }}
                                                            multiline="true"

                                                        />

                                                    </div>
                                                </Col>


                                                <Col xs={6}>

                                                    <div className="mb-2">
                                                        <Label htmlFor="roleName" className="form-label">Status</Label>

                                                        <div className="form-check form-switch form-switch-left form-switch-md">
                                                            <Input
                                                                type="switch"
                                                                id="status_id"
                                                                name="status"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                defaultValue={validation.values.status}
                                                                checked={validation.values.status}
                                                            />
                                                            {validation.values.status ? "Active" : "Inactive"}
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col lg={12}  >
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="submit" className="btn btn-primary shadow-lg" onClick={() => {
                                                            setSubmit(true);
                                                        }}>
                                                            {!isEdit ? "Add Notes" : "Update Notes"}</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>


                                    }

                                </div>
                            </SimpleBar>

                        </OffcanvasBody>

                    </Offcanvas>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default Notes;